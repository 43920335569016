import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { differenceInDays, differenceInYears } from "date-fns";
import React from "react";
import { AddFamilyMemberDTO } from "../../../../../Services/DTO/Employee/EmployeeEnrolmentDTO";
import { EMPLOYEE_ENROLMENT_SERVICES } from "../../../../../Services/Employee/EmployeeEnrolmentService";
import * as EMPLOYEE_ENDORSEMENT_SERVICES from "../../../../../Services/Employee/EndorsementService";
import useIsMobile from "../../../../../Supporting files/MobileProvider";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import {
  containsLeapYearFebruary,
  extractNumbersFromString,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  isLeapYear,
} from "../../../../../Supporting files/HelpingFunction";
import "../ConfirmationPage/ConfirmationPage.scss";
import moment from "moment";
interface Props {
  policiesAmount: any[];
  setPoliciesAmount: Function;
  amountRiders: number;
  setamountRiders: Function;
  policies: any;
  userObj: any;
  totalPayable: number;
  value_update: Function;
  calculatePaymentInfo: any;
  userLocalInfo: any;
  newlyAddedMembers: any;
  user: any;
  selectedPolicies: any[];
  setShowPwButton: Function;
  setShowLoader: Function;
  empPolicies: any[];
}

const ConfirmationPage: React.FC<Props> = ({
  policiesAmount,
  setPoliciesAmount,
  amountRiders,
  setamountRiders,
  policies,
  userObj,
  totalPayable,
  value_update,
  calculatePaymentInfo,
  userLocalInfo,
  newlyAddedMembers,
  user,
  selectedPolicies,
  setShowPwButton,
  setShowLoader,
  empPolicies,
}) => {
  const isMobile = useIsMobile();
  let test = 0;

  function findCoverageType(userAge: number, livesInfoFlat: any[]) {
    if (!Array.isArray(livesInfoFlat) || livesInfoFlat.length === 0) {
      return null;
    }
    for (let i = 0; i < livesInfoFlat.length; i++) {
      const coverageType = livesInfoFlat[i];
      const minAge = parseInt(extractNumbersFromString(coverageType.min));
      const maxAge = parseInt(extractNumbersFromString(coverageType.max));
      if (coverageType.min === "Up to 1 Year") {
        if (userAge < 1) {
          return coverageType;
        } else {
          if (userAge >= minAge && userAge <= maxAge) {
            return coverageType;
          }
        }
      } else {
        if (userAge >= minAge && userAge <= maxAge) {
          return coverageType;
        }
      }
    }
    return null;
  }

  function findIndexInBenefitsDetailsGrade(
    benefitsDetailsGrade: any,
    searchString: any
  ) {
    if (!Array.isArray(benefitsDetailsGrade)) {
      return -1;
    }
    for (let i = 0; i < benefitsDetailsGrade.length; i++) {
      const grade = benefitsDetailsGrade[i];

      if (grade.gradeDivisions && Array.isArray(grade.gradeDivisions)) {
        for (let j = 0; j < grade.gradeDivisions.length; j++) {
          const division = grade.gradeDivisions[j];
          if (division.label?.includes(searchString)) {
            return i;
          }
        }
      }
    }

    return -1;
  }
  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const Policy_Assignment = () => {
    const onSuccess = (res: any) => {
      setShowPwButton(res.data.data?.passwordExists);
      setShowLoader(false);
    };

    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    let dto = {
      policyIds: selectedPolicies,
    };
    setShowLoader(true);
    EMPLOYEE_ENROLMENT_SERVICES.completePolicyAssignment(
      "employee",
      onSuccess,
      onError,
      dto
    );
  };
  const CreateEndorsementForNomineeAndMember = (
    depId?: string,
    policyId?: string,
    policyNumber?: string,
    memberIndentification?: string
  ) => {
    let data = {
      memberId: depId,
      member: memberIndentification,
      policyId,
      policy: policyNumber,
      clientId: user.clientId,
      client: user.Client,
      type: "COMMERCIAL",
      endorsementFor: "Member Addition",
      old: "",
      new: {
        dob: "",
      },
      attachment: {},
    };
    const onSuccess = (res: any) => {};

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_ENDORSEMENT_SERVICES.post_new_endorsement(
      data,
      onSuccess,
      onError
    );
  };

  const handleConfirmClicked = () => {
    // if (!(newlyAddedMembers.length > 0)) {
    //   value_update("setStartFifthScreen", true);
    //   // return;
    // }
    const onSuccess = (policyId: string, res: any) => {
      Policy_Assignment();
      value_update("setStartFifthScreen", true);
      // const policyDetails = empPolicies.find((data: any) => data.policyData._id === policyId)
      // const policyEndDate = new Date(policyDetails.policyData.policyDetails.endDate);
      // const policyStartDate = new Date(policyDetails.policyData.policyDetails.startDate);
      // const currentDate = new Date();

      // const isTodayBetweenPolicyDates =
      //   currentDate >= policyStartDate && currentDate <= policyEndDate;
      //   if(isTodayBetweenPolicyDates) {
      //     CreateEndorsementForNomineeAndMember(res.data.data._id, policyId, policyDetails.policyData.policyCase.policyNumber,`${res.data.data.preFix}${res.data.data.employeeId}`)
      //   }
    };
    const onError = (err: any) => {
      console.log(err);
    };

    const payload: any[] = [];
    for (let i = 0; i < newlyAddedMembers.length; i++) {
      if (newlyAddedMembers[i].checked) {
        const exists = payload.find(
          (data: any) => data.policyId === newlyAddedMembers[i].policyId
        );
        if (exists) {
          exists.members.push({
            dob: newlyAddedMembers[i].dob,
            fullName: newlyAddedMembers[i].fullName,
            relationWithEmployee: newlyAddedMembers[i].relationShip,
            memId:
              newlyAddedMembers[i].memId || newlyAddedMembers[i]._id || null,
          });
        } else {
          const dto: AddFamilyMemberDTO = {
            members: [
              {
                dob: newlyAddedMembers[i].dob,
                fullName: newlyAddedMembers[i].fullName,
                relationWithEmployee: newlyAddedMembers[i].relationShip,
                memId:
                  newlyAddedMembers[i].memId ||
                  newlyAddedMembers[i]._id ||
                  null,
              },
            ],
            policyId: newlyAddedMembers[i].policyId,
            attachedClient: user.clientId,
          };
          payload.push(dto);
        }

        // EMPLOYEE_ENROLMENT_SERVICES.add_family_member(
        //   user.id,
        //   onSuccess.bind(this, newlyAddedMembers[i].policyId),
        //   onError,
        //   dto
        // );
      }
    }

    EMPLOYEE_ENROLMENT_SERVICES.add_family_member(user.id, onSuccess, onError, {
      payload,
    });
    value_update("setStartFifthScreen", true);
  };

  const calculateAmount = (policy: any) => {
    let amountSinglePolicy: number = 0;
    let totalAmount: number = 0;
    let perdayValue = 0;
    let policyRemainingDays = 365;
    let proRataAmount;
    let proRataUnit: string;
    const empAge = differenceInYears(
      new Date(),
      new Date(userObj["Date Of Birth"].toString())
    );
    proRataUnit = policy.policyData.policy.proreta_by;
    if (policy.policyData.payType.type === "STANDARD") {
      return (amountSinglePolicy = 0);
    } else if (policy.policyData.payType.type === "SPLIT") {
      if (policy.policyData.planType.name === "FLAT") {
        let membrEnrollmentDatePolicy = policy.enrollment_date.toString();
        const coverageTypeEmployee = findCoverageType(
          empAge,
          policy.policyData.livesInfoFlat
        );

        let amountdata = coverageTypeEmployee.coverageTypeData.find(
          (data: any) => data.name === "amount"
        );

        let start = moment(
          new Date(policy.policyData.policyDetails.startDate),
          "DD-MM-YYYY"
        );
        let yearDays = 365;
        if (
          containsLeapYearFebruary(
            new Date(policy.policyData.policyDetails.startDate)
          )
        ) {
          yearDays = 366;
        }

        proRataAmount = +amountdata.value ?? 0;
        let unit =
          proRataUnit === "YEARLY"
            ? yearDays
            : proRataUnit === "MONTHLY"
            ? 30
            : proRataUnit === "WEEKLY"
            ? 7
            : 1;
        perdayValue = proRataAmount / unit;
        let getSplitPercentageObject: any = policy.policyData.payType.data.find(
          (data: any) => data.name === "EMPLOYEE"
        );
        let startt = new Date(
          new Date(policy.policyData.policyDetails.startDate).setHours(
            0,
            0,
            0,
            0
          )
        );
        let enroll = new Date(
          new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
        );
        let endd = new Date(
          new Date(policy.policyData.policyDetails.endDate).setHours(0, 0, 0, 0)
        );
        let policyStart = startt > enroll ? startt : enroll;
        policyRemainingDays = differenceInDays(endd, policyStart) + 1;

        let splitedpercentage = getSplitPercentageObject.value;
        policyRemainingDays =
          policyRemainingDays >= 366 ? 366 : policyRemainingDays;
        let memberAmount =
          perdayValue * policyRemainingDays * (splitedpercentage / 100);
        amountSinglePolicy += memberAmount;
        policy.memberData.length > 0 &&
          policy.memberData.map((member: any) => {
            if (member.checked || member.checked === undefined) {
              const coverageTypeMember = findCoverageType(
                differenceInYears(new Date(), new Date(member.dob)),
                policy.policyData.livesInfoFlat
              );
              let amountdata = coverageTypeMember.coverageTypeData.find(
                (data: any) => data.name === "amount"
              );
              proRataAmount = +amountdata.value ?? 0;
              perdayValue = proRataAmount / unit;
              let relationname =
                member.relationShip === "Husband" ||
                member.relationWithEmployee === "Husband" ||
                member.relationShip === "Wife" ||
                member.relationWithEmployee === "Wife"
                  ? "SPOUSE"
                  : member.relationShip === "Daughter" ||
                    member.relationWithEmployee === "Daughter" ||
                    member.relationShip === "Son" ||
                    member.relationWithEmployee === "Son"
                  ? "KIDS"
                  : member.relationShip === "Father" ||
                    member.relationWithEmployee === "Father" ||
                    member.relationShip === "Mother" ||
                    member.relationWithEmployee === "Mother"
                  ? "PARENTS"
                  : member.relationShip === "Father-In-Law" ||
                    member.relationWithEmployee === "Father-In-Law" ||
                    member.relationShip === "Mother-In-Law" ||
                    member.relationWithEmployee === "Mother-In-Law"
                  ? "PARENTSINLAW"
                  : "";

              let getSplitPercentageObject =
                policy.policyData.payType.data.find(
                  (data: any) => data.name === relationname
                );
              let startt = new Date(
                new Date(policy.policyData.policyDetails.startDate).setHours(
                  0,
                  0,
                  0,
                  0
                )
              );
              let enroll = new Date(
                new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
              );
              let endd = new Date(
                new Date(policy.policyData.policyDetails.endDate).setHours(
                  0,
                  0,
                  0,
                  0
                )
              );
              let policyStart = startt > enroll ? startt : enroll;
              policyRemainingDays = differenceInDays(endd, policyStart) + 1;

              policyRemainingDays =
                policyRemainingDays >= 366 ? 366 : policyRemainingDays;
              let splitedpercentage = getSplitPercentageObject?.value ?? 0;
              let memberAmount =
                perdayValue * policyRemainingDays * (splitedpercentage / 100);
              amountSinglePolicy += memberAmount;
            }
          });
        totalAmount += amountSinglePolicy;
        test += amountSinglePolicy;
        return amountSinglePolicy;
      } else if (policy.policyData.planType.name === "GRADED") {
        let index, gradeName: any;
        if (policy.policyData.planType.gradeCriteria !== "Custom") {
          let gradeDivision = userObj[policy.policyData.planType.gradeCriteria];
          index = findIndexInBenefitsDetailsGrade(
            policy.policyData.benefitsDetailsGrade,
            gradeDivision
          );
          gradeName = policy.policyData.benefitsDetailsGrade[index]?.name;
          index = policy?.policyData?.livesInfoGrade?.findIndex(
            (li: any) => li.gradeName === gradeName
          );
        }
        if (policy.policyData.planType.gradeCriteria === "Custom") {
          if (policy.policyData.customValue) {
            let customIndex = +extractNumbersFromString(
              policy.policyData.customValue
            );
            index = customIndex - 1;
          } else {
            index = 0;
          }
        }
        let gradeIndex = index ?? 0;
        let membrEnrollmentDatePolicy = policy.enrollment_date.toString();
        const coverageTypeEmployee = findCoverageType(
          empAge,
          policy?.policyData?.livesInfoGrade[gradeIndex ?? 0]?.data
        );
        let amountdata = coverageTypeEmployee?.coverageTypeData.find(
          (data: any) => data.name === "amount"
        );
        let start = moment(
          new Date(policy.policyData.policyDetails.startDate),
          "DD-MM-YYYY"
        );
        let yearDays = 365;
        if (
          containsLeapYearFebruary(
            new Date(policy.policyData.policyDetails.startDate)
          )
        ) {
          yearDays = 366;
        }
        proRataAmount = +amountdata.value ?? 0;
        let unit =
          proRataUnit === "YEARLY"
            ? yearDays
            : proRataUnit === "MONTHLY"
            ? 30
            : proRataUnit === "WEEKLY"
            ? 7
            : 1;
        perdayValue = proRataAmount / unit;
        let getSplitPercentageObject = policy.policyData.payType.data.find(
          (data: any) => data.name === "EMPLOYEE"
        );
        let startt = new Date(
          new Date(policy.policyData.policyDetails.startDate).setHours(
            0,
            0,
            0,
            0
          )
        );
        let enroll = new Date(
          new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
        );
        let endd = new Date(
          new Date(policy.policyData.policyDetails.endDate).setHours(0, 0, 0, 0)
        );
        let policyStart = startt > enroll ? startt : enroll;
        policyRemainingDays = differenceInDays(endd, policyStart) + 1;

        let splitedpercentage = getSplitPercentageObject.value;
        policyRemainingDays =
          policyRemainingDays >= 366 ? 366 : policyRemainingDays;
        let memberAmount =
          perdayValue * policyRemainingDays * (splitedpercentage / 100);
        amountSinglePolicy += memberAmount;
        policy.memberData.length > 0 &&
          policy.memberData.map((member: any) => {
            if (
              member.checked ||
              member.checked === undefined ||
              member.checked === null
            ) {
              const coverageTypeMember = findCoverageType(
                differenceInYears(new Date(), new Date(member.dob)),
                policy.policyData.livesInfoGrade[gradeIndex ?? 0].data
              );
              let amountdata = coverageTypeMember.coverageTypeData.find(
                (data: any) => data.name === "amount"
              );
              proRataAmount = +amountdata.value ?? 0;
              perdayValue = proRataAmount / unit;
              let relationname =
                member.relationShip === "Husband" ||
                member.relationWithEmployee === "Husband" ||
                member.relationShip === "Wife" ||
                member.relationWithEmployee === "Wife"
                  ? "SPOUSE"
                  : member.relationShip === "Daughter" ||
                    member.relationWithEmployee === "Daughter" ||
                    member.relationShip === "Son" ||
                    member.relationWithEmployee === "Son"
                  ? "KIDS"
                  : member.relationShip === "Father" ||
                    member.relationWithEmployee === "Father" ||
                    member.relationShip === "Mother" ||
                    member.relationWithEmployee === "Mother"
                  ? "PARENTS"
                  : member.relationShip === "Father-In-Law" ||
                    member.relationWithEmployee === "Father-In-Law" ||
                    member.relationShip === "Mother-In-Law" ||
                    member.relationWithEmployee === "Mother-In-Law"
                  ? "PARENTSINLAW"
                  : "";

              let getSplitPercentageObject =
                policy.policyData.payType.data.find(
                  (data: any) => data.name === relationname
                );

              let startt = new Date(
                new Date(policy.policyData.policyDetails.startDate).setHours(
                  0,
                  0,
                  0,
                  0
                )
              );
              let enroll = new Date(
                new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
              );
              let endd = new Date(
                new Date(policy.policyData.policyDetails.endDate).setHours(
                  0,
                  0,
                  0,
                  0
                )
              );
              let policyStart2 = startt > enroll ? startt : enroll;
              policyRemainingDays = differenceInDays(endd, policyStart2) + 1;

              policyRemainingDays =
                policyRemainingDays >= 366 ? 366 : policyRemainingDays;
              let splitedpercentage = getSplitPercentageObject?.value ?? 0;
              let memberAmount =
                perdayValue * policyRemainingDays * (splitedpercentage / 100);
              amountSinglePolicy += memberAmount;
            }
          });
        totalAmount += amountSinglePolicy;
        test += amountSinglePolicy;
        return amountSinglePolicy;
      }
    }
  };

  const calculateRiderAmount = (policy: any, riderIndex: any) => {
    let amountSinglePolicy: number = 0;
    let totalAmount: number = 100;
    let perdayValue = 0;
    let policyRemainingDays = 365;
    let proRataAmount;
    let proRataUnit: string;
    const empAge = differenceInYears(
      new Date(),
      new Date(userObj["Date Of Birth"].toString())
    );
    if (policy.policyData.planType.name === "FLAT") {
      proRataUnit =
        policy.policyData?.rider_premium_flat[riderIndex]?.proreta_by;
      let membrEnrollmentDatePolicy = policy.enrollment_date.toString();
      const coverageTypeEmployee = findCoverageType(
        empAge,
        policy.policyData.rider_premium_flat[riderIndex].livesInfoFlat
      );
      let amountdata = coverageTypeEmployee.coverageTypeData.find(
        (data: any) => data.name === "amount"
      );
      let start = moment(
        new Date(policy.policyData.policyDetails.startDate),
        "DD-MM-YYYY"
      );
      let yearDays = 365;
      if (
        containsLeapYearFebruary(
          new Date(policy.policyData.policyDetails.startDate)
        )
      ) {
        yearDays = 366;
      }
      proRataAmount = +amountdata?.value ?? 0;
      let unit =
        proRataUnit === "YEARLY"
          ? yearDays
          : proRataUnit === "MONTHLY"
          ? 30
          : proRataUnit === "WEEKLY"
          ? 7
          : 1;
      perdayValue = proRataAmount / unit;
      // let getSplitPercentageObject = policy.policyData.payType.data.find(
      //   (data: any) => data.name === "EMPLOYEE"
      // );
      let startt = new Date(
        new Date(policy.policyData.policyDetails.startDate).setHours(0, 0, 0, 0)
      );
      let enroll = new Date(
        new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
      );
      let endd = new Date(
        new Date(policy.policyData.policyDetails.endDate).setHours(0, 0, 0, 0)
      );
      let policyStart = startt > enroll ? startt : enroll;
      policyRemainingDays = differenceInDays(endd, policyStart) + 1;

      let splitedpercentage =
        +policy.policyData?.rider_premium_flat[riderIndex]?.premium_split;
      policyRemainingDays =
        policyRemainingDays >= 366 ? 366 : policyRemainingDays;
      let memberAmount =
        perdayValue * policyRemainingDays * (splitedpercentage / 100);
      amountSinglePolicy += memberAmount;
      totalAmount += amountSinglePolicy;
      test += amountSinglePolicy;
      return amountSinglePolicy;
    } else if (policy.policyData.planType.name === "GRADED") {
      let index = 0;
      let gradeName: any;
      proRataUnit =
        policy.policyData?.rider_premium_graded[riderIndex]?.proreta_by;
      if (policy.policyData.planType.gradeCriteria !== "Custom") {
        let gradeDivision = userObj[policy.policyData.planType.gradeCriteria];
        index = findIndexInBenefitsDetailsGrade(
          policy.policyData.benefitsDetailsGrade,
          gradeDivision
        );
        gradeName = policy.policyData.benefitsDetailsGrade[index]?.name;
        index = policy?.policyData?.livesInfoGrade?.findIndex(
          (li: any) => li.gradeName === gradeName
        );
      }

      if (policy.policyData.planType.gradeCriteria === "Custom") {
        if (policy.policyData.customValue) {
          let customIndex = +extractNumbersFromString(
            policy.policyData.customValue
          );
          index = customIndex - 1;
        } else {
          index = 0;
        }
      }
      let gradeIndex = index ?? 0;
      let membrEnrollmentDatePolicy = policy.enrollment_date.toString();
      const coverageTypeEmployee = findCoverageType(
        empAge,
        policy.policyData.rider_premium_graded[riderIndex].livesInfoGrade[
          gradeIndex
        ].data
      );
      let amountdata = coverageTypeEmployee.coverageTypeData.find(
        (data: any) => data.name === "amount"
      );
      let start = moment(
        new Date(policy.policyData.policyDetails.startDate),
        "DD-MM-YYYY"
      );
      let yearDays = 365;
      if (
        containsLeapYearFebruary(
          new Date(policy.policyData.policyDetails.startDate)
        )
      ) {
        yearDays = 366;
      }

      proRataAmount = +amountdata.value ?? 0;
      let unit =
        proRataUnit === "YEARLY"
          ? yearDays
          : proRataUnit === "MONTHLY"
          ? 30
          : proRataUnit === "WEEKLY"
          ? 7
          : 1;
      perdayValue = proRataAmount / unit;
      // let getSplitPercentageObject = policy.policyData.payType.data.find(
      //   (data: any) => data.name === "EMPLOYEE"
      // );
      let startt = new Date(
        new Date(policy.policyData.policyDetails.startDate).setHours(0, 0, 0, 0)
      );
      let enroll = new Date(
        new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
      );
      let endd = new Date(
        new Date(policy.policyData.policyDetails.endDate).setHours(0, 0, 0, 0)
      );
      let policyStart = startt > enroll ? startt : enroll;
      policyRemainingDays = differenceInDays(endd, policyStart) + 1;

      let splitedpercentage =
        +policy.policyData?.rider_premium_graded[riderIndex]?.premium_split;

      policyRemainingDays =
        policyRemainingDays >= 366 ? 366 : policyRemainingDays;
      let memberAmount =
        perdayValue * policyRemainingDays * (splitedpercentage / 100);
      amountSinglePolicy += memberAmount;
      totalAmount += amountSinglePolicy;
      test += amountSinglePolicy;
      return amountSinglePolicy;
    }
  };

  return (
    <>
      {isMobile ? (
        <Box className="confirmation_wrapper">
          <Grid container spacing={3} className="row">
            <Grid xs={12} className="mb-5 mt-1" padding={0}>
              <div className="MenrollmentBackBtn">
                <ArrowBackIosOutlinedIcon
                  fontSize="small"
                  onClick={() => {
                    value_update("setStartFourthScreenfalse", false);
                    setPoliciesAmount(policies);
                  }}
                />
                <h5>Back</h5>
              </div>
            </Grid>
            <Grid xs={12}>
              {/* <div className="confirmation_rightSection">
                <div className="confirmation_innerSection">
                  <img src="./images/confirmation_girl_img.svg" alt="" />
                  <h5>Almost Done!</h5>
                  <p>
                    Total payment amount of your enrolment shall be deducted
                    from your salary as per the decided number of installments .
                  </p>
                </div> */}
              <div className="image_container">
                <img src="mobile/almost-done.svg" alt="girl.svg" loading="eager"/>
                <h5>Almost Done!</h5>
                <p>
                  Total payment amount of your enrolment shall be deducted from
                  your salary as per the decided number of installments .
                </p>
              </div>
            </Grid>
            <Grid xs={12}>
              <div className="pay_section">
                <h5>What you'll pay</h5>
                <hr />
                <ul className="pay_list">
                  {policiesAmount.map((item: any) => {
                    let amountData = calculateAmount(item) ?? 0;
                    let dataNew =
                      item.policyData?.productType?.name === "Group Term" &&
                      item.policyData.planType.name === "GRADED"
                        ? item.policyData.benefits_detail_graded[0].riders
                        : item.policyData?.benefits_detail_flat;

                    return (
                      <React.Fragment key={item.policyData._id}>
                        <li>
                          <span className="name">
                            {item.policyData.policyCase.policyNumber}
                          </span>
                          <span
                            className="price"
                            style={{ textAlign: "right" }}
                          >
                            {formatCurrencyAccToUser(
                              amountData,
                              getCurrencyCodeRegex(userLocalInfo?.currency),
                              userLocalInfo?.countryCurrencyCode,
                              2
                            )}{" "}
                            /-
                          </span>
                        </li>
                        {dataNew?.map((benefit: any, riderIndex: any) => {
                          if (benefit.enable) {
                            let riderAmount: number =
                              calculateRiderAmount(item, riderIndex) ?? 0;
                            return (
                              <div
                                key={benefit._id}
                                className=" mb-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <h5
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {benefit.label}
                                </h5>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    fontFamily: "Montserrat",
                                  }}
                                >
                                  {formatCurrencyAccToUser(
                                    riderAmount ?? 0,
                                    getCurrencyCodeRegex(
                                      userLocalInfo?.currency
                                    ),
                                    userLocalInfo?.countryCurrencyCode,
                                    2
                                  )}{" "}
                                  /-
                                </p>
                              </div>
                            );
                          }
                          return null; // Added return null for disabled benefits
                        })}
                      </React.Fragment>
                    );
                  })}
                  <li className="total_payment">
                    <span className="name">Total Payment</span>
                    <span className="price" style={{ textAlign: "right" }}>
                      {formatCurrencyAccToUser(
                        test,
                        getCurrencyCodeRegex(userLocalInfo?.currency),
                        userLocalInfo?.countryCurrencyCode,
                        2
                      )}{" "}
                      /-
                    </span>
                  </li>
                </ul>

                {test === 0 && (
                  <Alert severity="info" className="mb-5">
                    Your policy(s) have been paid for by your employer.
                  </Alert>
                )}
                {/* {!totalPayable && (
                <Alert severity="info" className="mb-5">
                  Your policy(s) have been paid for by your employer.
                </Alert>
              )} */}
                {/* <div style={{ textAlign: "center" }}>
                  {" "}
                  <img src="./images/choose_policy_img.svg" alt="" className="mb-5"/>
                </div> */}
                <div className="MenrollmentStartBtn ">
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      handleConfirmClicked();
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="confirmation_wrapper">
          <Grid container spacing={3} className="row">
            <Grid xs={12} className="mb-5">
              <div className="backBtn">
                <ArrowBackIcon
                  onClick={() => {
                    value_update("setStartFourthScreenfalse", false);
                    setPoliciesAmount(policies);
                  }}
                />
                <span>Backs to Policy</span>
              </div>
            </Grid>
            <Grid xs={4}>
              <div className="pay_section">
                <h5>What you'll pay</h5>
                <hr />
                <ul className="pay_list">
                  {policiesAmount.map((item: any) => {
                    let amountData = calculateAmount(item) ?? 0;
                    let dataNew =
                      item.policyData?.productType?.name === "Group Term" &&
                      item.policyData.planType.name === "GRADED"
                        ? item.policyData.benefits_detail_graded[0].riders
                        : item.policyData?.benefits_detail_flat;

                    return (
                      <React.Fragment key={item.policyData._id}>
                        <li>
                          <span className="name">
                            {item.policyData.policyCase.policyNumber}
                          </span>
                          <span
                            className="price"
                            style={{ textAlign: "right" }}
                          >
                            {formatCurrencyAccToUser(
                              amountData,
                              getCurrencyCodeRegex(userLocalInfo?.currency),
                              userLocalInfo?.countryCurrencyCode,
                              2
                            )}{" "}
                            /-
                          </span>
                        </li>
                        {dataNew?.map((benefit: any, riderIndex: any) => {
                          if (benefit.enable) {
                            let riderAmount: number =
                              calculateRiderAmount(item, riderIndex) ?? 0;
                            return (
                              <div
                                key={benefit._id}
                                className="ml-5 mb-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <h5
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {benefit.label}
                                </h5>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {formatCurrencyAccToUser(
                                    riderAmount ?? 0,
                                    getCurrencyCodeRegex(
                                      userLocalInfo?.currency
                                    ),
                                    userLocalInfo?.countryCurrencyCode,
                                    2
                                  )}{" "}
                                  /-
                                </p>
                              </div>
                            );
                          }
                          return null; // Added return null for disabled benefits
                        })}
                      </React.Fragment>
                    );
                  })}
                  <li className="total_payment">
                    <span className="name">Total Payment</span>
                    <span className="price" style={{ textAlign: "right" }}>
                      {formatCurrencyAccToUser(
                        test,
                        getCurrencyCodeRegex(userLocalInfo?.currency),
                        userLocalInfo?.countryCurrencyCode,
                        2
                      )}{" "}
                      /-
                    </span>
                  </li>
                </ul>
                <div className="ctaBtn mb-5">
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      handleConfirmClicked();
                    }}
                  >
                    Confirm
                  </Button>
                </div>
                {test === 0 && (
                  <Alert severity="info" className="mb-5">
                    Your policy(s) have been paid for by your employer.
                  </Alert>
                )}
                {/* {!totalPayable && (
                <Alert severity="info" className="mb-5">
                  Your policy(s) have been paid for by your employer.
                </Alert>
              )} */}
                <img src="./images/choose_policy_img.svg" alt="" />
              </div>
            </Grid>
            <Grid xs={8}>
              <div className="confirmation_rightSection">
                <div className="confirmation_innerSection">
                  <img src="./images/confirmation_girl_img.svg" alt="" />
                  <h5>Almost Done!</h5>
                  <p>
                    Total payment amount of your enrolment shall be deducted
                    from your salary as per the decided number of installments .
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ConfirmationPage;
