/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-lone-blocks */
import { InsertDriveFile } from "@mui/icons-material";
import Optout from "@mui/icons-material/ExitToApp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Expired from "@mui/icons-material/GppBad";
import {
  default as Enrolled,
  default as HowToRegIcon,
  default as Requested,
} from "@mui/icons-material/HowToReg";
import EnrollmentRequested from "@mui/icons-material/MarkChatRead";
import EnrolmentInitated from "@mui/icons-material/MobileFriendly";
import NA from "@mui/icons-material/NotAccessible";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import {
  AddExistingMemberToPolicy,
  AddNewMemberToPolicyDTO,
  CreateECardDTO,
  CreateNomineeInPolicyDTO,
  PolicyDetailObj,
  assignPolicyDTO,
} from "../../../Services/DTO/Employee/EmployeePoliciesDTO";
import { EMPLOYEE_POLICY_SERVICES } from "../../../Services/Employee/EmployeePolicyService";
import { EMPLOYEE_SERVICES } from "../../../Services/Employee/EmployeeService";
import { EMPLOYER_EMPLOYEE_SERVICES } from "../../../Services/EmployerServices/Employee/EmployeeServices";
import { PolicyDeassignEntityType } from "../../../Services/Enum/EEmployeePolicyDetail";
import { useAppSelector } from "../../../Store/hooks";
import { RootState } from "../../../Store/Store";
import { useFormatCurrency } from "../../../Supporting files/CustomHooks";
import DatePicker from "../../../Supporting files/DatePicker/DatePicker";
import {
  calculateAgeFromDob,
  customFormatDate,
  customFormatTime,
  downloadAttachement,
  extractNumbersFromString,
  getFileSignedUrl,
  isIntegratedTpa,
  uploadImage,
} from "../../../Supporting files/HelpingFunction";
import RKTextField from "../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../Supporting files/SelectDropdown/SelectDropdown";
import "../EmployeeDetailPolicies/EmployeeDetailPolicies.scss";
import DocumentViewer from "../../Common/DocumentViewer/DocumentViewer";

function EmployeeDetailPolicies({
  selectedClient,
  employeeId,
  empSalary,
  empSalaryBracket,
  empDepartment,
  empDesignation,
  empFirstName,
  dob,
  empLastName,
  memberId,
  selectedMemberType,
  relatedEmpId,
  policyMemberRelationId,
  employementStatus,
  mainStatus,
  selectedMemberDOB,
  employeeBaseLocationId,
  relationWithEmployee,
}: {
  selectedClient: any;
  employeeId: string;
  empSalary: string;
  empSalaryBracket: string;
  empDesignation: string;
  empDepartment: string;
  empFirstName: string;
  empLastName: string;
  dob: string;
  memberId: string;
  selectedMemberType: string;
  relatedEmpId: string;
  policyMemberRelationId: string;
  employementStatus?: number;
  mainStatus?: number;
  selectedMemberDOB?: string | Date;
  employeeBaseLocationId?: string;
  relationWithEmployee?: string;
}) {
  const { formatCurrency } = useFormatCurrency();
  const [selectedDocUrl, setselectedDocUrl] = useState("");
  const [docviewOpenStatus, setDocviewOpenStatus] = useState(false);
  const [disableSaveAss, setDisableSaveAss] = useState<boolean>(false);
  const [disableSaveEmp, setDisableSaveEmp] = useState<boolean>(false);
  const [disableSaveNom, setDisableSaveNom] = useState<boolean>(false);
  const [disableSaveCard, setDisableSaveCard] = useState<boolean>(false);
  const usertypeinfo = useAppSelector(
    (state: RootState) => state.userDetailsSlice.userType
  );
  const [relationEmployee_data, setrelationEmployee_data] = useState<any[]>([
    { key: "Husband", value: "Husband", type: "S" },
    { key: "Wife", value: "Wife", type: "S" },
    { key: "Daughter", value: "Daughter", type: "C" },
    { key: "Son", value: "Son", type: "C" },
    { key: "Father", value: "Father", type: "P" },
    { key: "Mother", value: "Mother", type: "P" },
    { key: "Mother-In-Law", value: "Mother-In-Law", type: "PIL" },
    { key: "Father-In-Law", value: "Father-In-Law", type: "PIL" },
  ]);
  const [relationNomineeEmployee_data, setRelationNomineeEmployee_data] =
    useState<any[]>([
      { key: "Husband", value: "Husband", type: "S" },
      { key: "Wife", value: "Wife", type: "S" },
      { key: "Brother", value: "Brother", type: "S" },
      { key: "Sister", value: "Sister", type: "S" },
      { key: "Daughter", value: "Daughter", type: "C" },
      { key: "Son", value: "Son", type: "C" },
      { key: "Father", value: "Father", type: "P" },
      { key: "Mother", value: "Mother", type: "P" },
      { key: "Mother-In-Law", value: "Mother-In-Law", type: "PIL" },
      { key: "Father-In-Law", value: "Father-In-Law", type: "PIL" },
    ]);
  const MemberStatus = useAppSelector(
    (state) => state.permissionSlice.MemberStatus
  );
  const [dropdowndataDynamic, setdropdowndataDynamic] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [toggleExistingOrNew, setToggleExistingOrNew] = useState(true);
  const [addNominee, setAddNominee] = useState(false);
  const [addHealth, setAddHealth] = useState(false);
  const [addMember, setAddMember] = useState(false);
  const [viewAssignPolicy, setViewAssignPolicy] = useState(false);
  const [fullName, setFullName] = useState("");
  const [relationEmployee, setRelationEmployee] = useState("");
  const [selectMember, setSelectMember] = useState("");
  const [selectedFamily, setSelectedFamily] = useState("");
  const [selectedNominee, setSelectedNominee] = useState("");
  const [nomineeDob, setNomineeDob] = useState<Date | string>("");
  const [healthEcardNumber, setHealthEcardNumber] = useState("");
  const [eCardError, seteCardError] = useState({
    warnMember: false,
    warnNumber: false,
    warnDoc: false,
  });
  const [assignable_policies, setAssignable_policies] = useState<
    {
      _id: "";
      policyNumber: "";
      productType: "";
      insurer: "";
      basicConfig: "";
      individualConfig: "";
      employeeStatus: null;
    }[]
  >([]);
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  const [selectedPoliciesNew, setSelectedPoliciesNew] = useState<string[]>([]);
  const [existingAssignedPolicies, setExistingAssignedPolicies] = useState<
    string[]
  >([]);
  const [employeeAssignedPolicies, setEmployeeAssignedPolicies] = useState<
    Array<PolicyDetailObj>
  >([]);
  const [prioritystatusData, setprioritystatusData] = useState<any>([]);
  const [prioritystatusDataNominee, setprioritystatusDataNominee] =
    useState<any>([]);
  const [employeeMembers, setEmployeeMembers] = useState<
    {
      fullName: string;
      relation: string;
      _id: string;
    }[]
  >([]);
  const [employeeNominees, setEmployeeNominees] = useState([]);
  const [memberToAdd, setMemberToAdd] = useState<{
    fullName: string;
    relation: string;
    dob?: string;
    _id: string;
  }>({ fullName: "", relation: "", _id: "" });
  const [nomineeToAdd, setNomineeToAdd] = useState<{
    label: string;
    relation: string;
    _id: string;
    dob: string;
  }>({ label: "", relation: "", _id: "", dob: "" });
  const [newMemberDetails, setNewMemberDetails] = useState<any>({
    dob: "",
    warnDob: false,
    fullName: "",
    warnFullName: false,
    relationWithEmployee: "",
    warnRelationWithEmployee: false,
  });
  const [policyBeingWorkedOn, setPolicyBeingWorkedOn] =
    useState<PolicyDetailObj>({
      deleteable: false,
      status: 0,
      tpa: "",
      nominee: [
        {
          _id: "",
          age: "",
          createdAt: "",
          dob: "",
          fullName: "",
          relation: "",
          enrolment_status: 45,
        },
      ],
      physicalForm: [
        {
          fileName: "",
          description: "",
          subType: "",
          title: "",
          downloadLink: "",
          attachment: "",
          product: "",
          type: "",
        },
      ],
      maxChildAge: "",
      ecard: [
        {
          _id: "",
          doc: "",
          downloadLink: "",
          ecardNumber: "",
          memberId: "",
          createdAt: "",
        },
      ],
      endDate: "",
      insurerName: "",
      planType: {
        basicConfig: "",
        coverageType: "",
        familyDefinition: "",
        gradeCriteria: "",
        name: "",
        parentConfig: "",
        setOfParents: "",
      },
      policySettings: {
        cardType: "",
        corporateBuffer: 0,
        criticalCd: 0,
        criticalPercentage: 0,
        enrolmentEndDate: "",
        enrolmentStartDate: "",
        gracePeriod: "",
        healthCard: false,
        healthCardIssued: "",
        isCorporateBuffer: false,
        maxMemberPerFamily: 0,
        maxNomination: 0,
        newMemberPeriod: "",
        nomination: false,
        period: "",
        totalMemberPerFamily: 0,
      },
      members: [
        {
          preFix: "",
          _id: "",
          dob: "",
          fullName: "",
          relationWithEmployee: "",
          enrolmentDate: "",
        },
      ],
      policyId: "",
      policyNumber: "",
      startDate: "",
      sumInsured: "",
      productType: "",
      relationId: "",
    });
  const [policyBeingWorkedOnIndex, setPolicyBeingWorkedOnIndex] = useState<
    number | null
  >(null);
  const [docName, setDocName] = useState<string>("");
  const [ecardDoc, setEcardDoc] = useState("");
  const [dropdownArr, setDropdownArr] = useState<
    Array<{
      policyId: string;
      customValue: any;
      options: any;
      policyNumber: string;
    }>
  >([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("policyDetails");
  const policyDetails = useRef(null);
  const [toggle, setToggle] = useState(true);
  const currentDate = new Date();
  const minAge = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const { BROKER, EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [editPermission, setEditPermission] = useState<any>(null);
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const empId = params.get("id");
  const [dropdownError, setDropdownError] = useState({
    index: 0,
    flag: false,
  });

  function compareDates(a: any, b: any) {
    let dateA = new Date(a?.enrollment_date);
    let dateB = new Date(b?.enrollment_date);
    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else {
      return 0;
    }
  }
  let alreadyAddedNames = new Set(
    policyBeingWorkedOn.members?.map((member) => member.fullName)
  );

  useEffect(() => {
    const filteredData = relationEmployee_data?.filter((listData) => {
      if (
        listData.key?.toLowerCase() === "son" ||
        listData.key?.toLowerCase() === "daughter"
      ) {
        return true;
      } else {
        return !employeeMembers?.some(
          (memberData: any) =>
            memberData.relationWithEmployee?.toLowerCase() ===
            listData.key?.toLowerCase() ||
            memberData.relationShip?.toLowerCase() ===
            listData.key?.toLowerCase() ||
            memberData.relation?.toLowerCase() === listData.key?.toLowerCase()
        );
      }
    });
    setrelationEmployee_data(filteredData);
    const filteredDataNominee = relationNomineeEmployee_data?.filter(
      (listData) => {
        if (
          listData.key?.toLowerCase() === "son" ||
          listData.key?.toLowerCase() === "daughter"
        ) {
          return true;
        } else {
          return !employeeMembers.some(
            (memberData: any) =>
              memberData.relationWithEmployee?.toLowerCase() ===
              listData.key?.toLowerCase() ||
              memberData.relationShip?.toLowerCase() ===
              listData.key?.toLowerCase() ||
              memberData.relation?.toLowerCase() === listData.key?.toLowerCase()
          );
        }
      }
    );
    setRelationNomineeEmployee_data(filteredDataNominee);
  }, [policyBeingWorkedOn]);

  useEffect(() => {
    if (usertypeinfo === "BROKER") {
      setEditPermission(
        BROKER?.Employees.edit === undefined ? false : BROKER?.Employees.edit
      );
    } else if (usertypeinfo === "EMPLOYER") {
      // setEditPermission(
      //   EMPLOYER?.Employees.edit === undefined
      //     ? false
      //     : EMPLOYER?.Employees.edit
      // );
    }
  }, []);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMasterState = (attrName: any, value: any) => {
    const policyEndDate = new Date(policyBeingWorkedOn.endDate);
    policyEndDate.setDate(policyEndDate.getDate() + 1);

    const policyStartDate = new Date(policyBeingWorkedOn.startDate);
    policyStartDate.setDate(policyStartDate.getDate() + 1);

    const currentDate = new Date();
    const isTodayBetweenPolicyDates =
      currentDate >= policyStartDate && currentDate <= policyEndDate;

    if (attrName[0] === setSelectedFamily) {
      const selectedMemberToAdd = employeeMembers?.filter(
        (item: any) => item._id === value
      );
      setMemberToAdd(selectedMemberToAdd[0]);
      setSelectedFamily(value);
      setNewMemberDetails({
        ...newMemberDetails,
        warnRelationWithEmployee: false,
      });
    } else if (attrName[0] === setSelectedNominee) {
      const selectedNomineeToAdd: any = employeeNominees?.filter(
        (item: any) => item?._id === value
      );
      if (
        policyBeingWorkedOn.nominee?.find(
          (nom: any) => nom?._id === selectedNomineeToAdd[0]?._id
        )
      ) {
        return toast.info("Nominee already exists in the policy");
      }
      setNomineeToAdd(selectedNomineeToAdd[0]);
      setSelectedNominee(value);
    } else if (attrName[0] === setNewMemberDetails) {
      let key = attrName[1];
      attrName[0]({
        ...newMemberDetails,
        [attrName[1]]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else if (attrName[0] === setDropdownArr) {
      let newDropdownArr = dropdownArr.map((item) => {
        if (item.policyId === attrName[1]) {
          return {
            ...item,
            customValue: value,
          };
        } else {
          return item;
        }
      });
      if (attrName[2] === dropdownError.index) {
        setDropdownError({ ...dropdownError, flag: false });
      }
      setDropdownArr(newDropdownArr);
    } else if (attrName[0] === "setPriorityStatus") {
      if (usertypeinfo === "EMPLOYER") {
      }
      if (!isTodayBetweenPolicyDates && value === 41) {
        return toast.error(
          `Enrolment Status cannot be shifted to Enrolled before the Policy Start Date`
        );
      }

      if (
        (attrName[1].status === 40 && (value === 60 || value === 66)) ||
        (attrName[1].status === 41 && value === 61) ||
        (attrName[1].status === 60 && value === 41) ||
        (attrName[1].status === 61 && value === 42) ||
        (attrName[1].status === 66 && value === 40)
      ) {
        if (attrName[2] >= attrName[3]) {
          return toast.warning(
            "Maximun number of family members Enroled limit exceeds"
          );
        }
        if (
          attrName[1].status === 41 &&
          value === 61 &&
          isTodayBetweenPolicyDates
        ) {
          CreateEndorsementForNomineeAndMember("Member Deletion");
          if (attrName[1]?.members?.length) {
            attrName[1]?.members.forEach((mem: any) => {
              mem.enrolment_status !== 42 &&
                update_status_dependent_policy(value, mem._id, attrName[1]._id);
              mem.enrolment_status !== 42 &&
                CreateEndorsementForNomineeAndMember(
                  "Member Deletion",
                  mem.dep_id,
                  false,
                  "",
                  "",
                  mem.dependentId
                );
            });
          }
        }
        return update_status(value, attrName[1].policyId);
      }
      if (
        (attrName[1].status !== 66 && value !== 40) ||
        (attrName[1].status !== 41 && value !== 61)
      ) {
        let nameFirst = prioritystatusData.find(
          (data: any) => data.label === attrName[1].status
        )?.value;
        let nameSecond = prioritystatusData.find(
          (data: any) => data.label === value
        )?.value;
        return toast.warn(
          `Status can't change from ${nameFirst} to ${nameSecond}`
        );
      } else {
        let nameFirst = prioritystatusData.find(
          (data: any) => data.label === attrName[1].status
        )?.value;
        let nameSecond = prioritystatusData.find(
          (data: any) => data.label === value
        )?.value;
        return toast.warn(
          `Status can't change from ${nameFirst} to ${nameSecond}`
        );
      }
    } else if (attrName[0] === "setPriorityStatusDependent") {
      // if (usertypeinfo === "EMPLOYER") {
      //   if ((attrName[3] !== 66 && value !== 40) || (attrName[3] !== 41 && value !== 61)) {
      //     let nameFirst = prioritystatusData.find((data: any) => data.label === attrName[3])?.value;
      //     let nameSecond = prioritystatusData.find((data: any) => data.label === value)?.value;
      //     return toast.warn(`Status can't change from ${nameFirst} to ${nameSecond}`);
      //   }
      // }
      if (!isTodayBetweenPolicyDates && value === 41) {
        return toast.error(
          `Enrolment Status cannot be shifted to Enrolled before the Policy Start Date`
        );
      }
      if (
        (attrName[3] === 40 && (value === 60 || value === 66)) ||
        (attrName[3] === 41 && value === 61) ||
        (attrName[3] === 60 && value === 41) ||
        (attrName[3] === 61 && value === 42) ||
        (attrName[3] === 66 && value === 40)
      ) {
        if (attrName[6] >= attrName[7]) {
          return toast.warning(
            "Maximun number of family members Enroled limit exceeds"
          );
        }
        if (attrName[3] === 41 && value === 61 && isTodayBetweenPolicyDates) {
          if (attrName[4] && attrName[5]) {
            CreateEndorsementForNomineeAndMember(
              "Member Deletion",
              attrName[4],
              false,
              "",
              "",
              attrName[5]
            );
          } else {
            CreateEndorsementForNomineeAndMember("Member Deletion");
          }
        }
        // if (!isTodayBetweenPolicyDates) {
        //   return toast.error("Enrolment Status cannot be shifted to Enrolled before the Policy Start Date");
        // }
        return update_status_dependent_policy(value, attrName[1], attrName[2]);
      } else {
        let nameFirst = prioritystatusData.find(
          (data: any) => data.label === attrName[3]
        )?.value;
        let nameSecond = prioritystatusData.find(
          (data: any) => data.label === value
        )?.value;
        return toast.warn(
          `Status can't change from ${nameFirst} to ${nameSecond}`
        );
      }
    } else if (attrName[0] === "setPriorityStatusNominee") {
      if (
        (attrName[3] === 63 && value === 44) || //Enrolled Requested to Enrolled
        (attrName[3] === 44 && value === 64) || // Enrolled to Opt-out Requested
        (attrName[3] === 64 && value === 45) //  Opt-out Requested to Opt- Out
      ) {
        if (attrName[4] >= attrName[5]) {
          return toast.error(
            "Maximun number of nominees Enroled limit exceeds"
          );
        }
        if (attrName[3] === 44 && value === 64) {
          CreateEndorsementForNomineeAndMember(
            "Nominee Deletion",
            "",
            false,
            "",
            "",
            "",
            attrName[1]
          );
        }
        update_status_nominee(value, attrName[1], attrName[2], empId || "");
      } else {
        let nameFirst = prioritystatusDataNominee?.find(
          (data: any) => data.label === attrName[3]
        )?.value;
        let nameSecond = prioritystatusDataNominee?.find(
          (data: any) => data.label === value
        )?.value;
        return toast.warn(
          `Status can't change from ${nameFirst} to ${nameSecond}`
        );
      }
    } else {
      attrName[0](value);
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    get_status();
    get_status_nominee();
    getMembers();
    getNominees();
  }, []);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_enrolment(onSuccess, onError);
  };
  const get_status_nominee = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusDataNominee(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_nominee(onSuccess, onError);
  };

  const update_status = (value: number, childId: string) => {
    const onSuccess = (res: any) => {
      if (
        selectedMemberType === "" ||
        selectedMemberType?.toLowerCase() === "depandent" ||
        selectedMemberType?.toLowerCase() === "dependent"
      ) {
        getDepAssignedPolicies();
        getDependentAssignablePolicies();
      } else {
        getAssignedPolicies();
        getAssignablePolicies();
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_POLICY_SERVICES.update_status_policy(
      {
        value: value,
      },
      onSuccess,
      onError,
      employeeId,
      childId,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const update_status_nominee = (
    value: number,
    nomineeId: string,
    policyId: string,
    memberId: string
  ) => {
    const onSuccess = (res: any) => {
      if (
        selectedMemberType?.toLowerCase() === "depandent" ||
        selectedMemberType?.toLowerCase() === "dependent"
      ) {
        getDepAssignedPolicies();
        getDependentAssignablePolicies();
      } else {
        getAssignedPolicies();
        getAssignablePolicies();
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_POLICY_SERVICES.update_status_nominee(
      {
        value: value,
      },
      onSuccess,
      onError,
      nomineeId,
      policyId,
      memberId,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const update_status_dependent_policy = (
    value: number,
    childId: string,
    policyId: string
  ) => {
    let parentId;
    if (selectedMemberType?.toLowerCase() === "employee") {
      parentId = employeeId;
    } else {
      parentId = relatedEmpId;
    }
    const onSuccess = (res: any) => {
      if (
        selectedMemberType?.toLowerCase() === "depandent" ||
        selectedMemberType?.toLowerCase() === "dependent"
      ) {
        getDepAssignedPolicies();
        getDependentAssignablePolicies();
      } else {
        getAssignedPolicies();
        getAssignablePolicies();
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_POLICY_SERVICES.update_status_dependent_policy(
      {
        value: value,
      },
      onSuccess,
      onError,
      parentId,
      childId,
      policyId,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  useEffect(() => {
    if (
      selectedMemberType === "" ||
      selectedMemberType?.toLowerCase() === "depandent" ||
      selectedMemberType?.toLowerCase() === "dependent"
    ) {
      getDepAssignedPolicies();
      getDependentAssignablePolicies();
    } else {
      getAssignedPolicies();
      getAssignablePolicies();
    }
  }, [selectedClient?.id]);

  useEffect(() => {
    const selectedPolicies = [];

    for (let i = 0; i < assignable_policies.length; i++) {
      for (let j = 0; j < employeeAssignedPolicies.length; j++) {
        if (
          employeeAssignedPolicies[j].policyNumber ===
          assignable_policies[i].policyNumber
        ) {
          selectedPolicies.push(assignable_policies[i]._id);
          break;
        }
      }
    }

    setSelectedPolicies(selectedPolicies);
    setExistingAssignedPolicies(selectedPolicies);
  }, [employeeAssignedPolicies.length, viewAssignPolicy]);

  const getAssignedPolicies = () => {
    const onSuccess = (res: any) => {
      const membersToExclude: string[] = [];
      const newPolicies = res.data.data.policyDetails.map(
        (item: any, index: number) => {
          const newMembers = item.members.filter((item: any) => item);
          if (index !== 0) {
            return { ...item, deleteable: true, members: newMembers };
          } else {
            return { ...item, deleteable: false, members: newMembers };
          }
        }
      );

      setEmployeeAssignedPolicies(newPolicies);
      if (policyBeingWorkedOnIndex) {
        const policybeingWorked = newPolicies.filter(
          (pol: any, i: number) => i === policyBeingWorkedOnIndex
        )[0];
        setPolicyBeingWorkedOn(policybeingWorked);
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    {
      usertypeinfo === "EMPLOYER"
        ? EMPLOYER_EMPLOYEE_SERVICES.get_assigned_policies(
          onSuccess,
          onError,
          employeeId
        )
        : EMPLOYEE_POLICY_SERVICES.get_assigned_policies(
          onSuccess,
          onError,
          employeeId
        );
    }
  };

  const getDepAssignedPolicies = () => {
    const onSuccess = (res: any) => {
      setEmployeeAssignedPolicies(res.data.data.policyDetails);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    {
      usertypeinfo === "EMPLOYER"
        ? EMPLOYER_EMPLOYEE_SERVICES.get_dependent_assigned_policies(
          onSuccess,
          onError,
          relatedEmpId,
          policyMemberRelationId
        )
        : EMPLOYEE_SERVICES.get_dependent_assigned_policies(
          onSuccess,
          onError,
          relatedEmpId,
          policyMemberRelationId
        );
    }
  };

  const getDependentAssignablePolicies = () => {
    const onSuccess = (res: any) => {
      setAssignable_policies(res.data.data);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    {
      usertypeinfo === "BROKER"
        ? EMPLOYEE_POLICY_SERVICES.get_dep_assignable_policies(
          onSuccess,
          onError,
          relatedEmpId
        )
        : EMPLOYER_EMPLOYEE_SERVICES.get_employee_assigned_policies_for_dependants(
          onSuccess,
          onError,
          relatedEmpId
        );
    }
  };

  const getAssignablePolicies = () => {
    const onSuccess = (res: any) => {
      setAssignable_policies(res.data.data);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    {
      usertypeinfo === "BROKER"
        ? EMPLOYEE_POLICY_SERVICES.get_assignable_policies(
          onSuccess,
          onError,
          selectedClient?.id
        )
        : EMPLOYER_EMPLOYEE_SERVICES.get_assignable_policies(
          onSuccess,
          onError,
          selectedClient?.id
        );
    }
  };

  const handleClicked = () => {
    setToggleExistingOrNew(false);
    setNewMemberDetails({
      dob: "",
      warnDob: false,
      fullName: "",
      warnFullName: false,
      relationWithEmployee: "",
      warnRelationWithEmployee: false,
    });
    setMemberToAdd({ fullName: "", relation: "", _id: "" });
    setSelectedFamily("");
  };
  const handleClicktoogle = () => {
    setToggleExistingOrNew(true);
    setNewMemberDetails({
      dob: "",
      warnDob: false,
      fullName: "",
      warnFullName: false,
      relationWithEmployee: "",
      warnRelationWithEmployee: false,
    });
    setMemberToAdd({ fullName: "", relation: "", _id: "" });
    setSelectedFamily("");
  };

  const checkEmployementStatus = () => {
    if (MemberStatus !== 32) {
      toast.warning(
        "Member Status should be Active before to assign any Policy"
      );
      return false;
    }
    if (employementStatus === 37 || employementStatus === 38) {
      return true;
    } else {
      toast.warning("Employement Status is not allowing you to Assign policy");
      return false;
    }
  };

  const checkLocation = (selectedPolicy: any) => {
    let isLoactionMatched = selectedPolicy.locations.some((data: any) => {
      return data.id === employeeBaseLocationId;
    });
    if (isLoactionMatched) {
      return true;
    } else {
      toast.warning("This policy is not for Employee's Location");
      return false;
    }
  };

  const checkAgeBand = (selectedPolicy: any) => {
    let policy: any = selectedPolicy;
    const dobDate = new Date(selectedMemberDOB ? selectedMemberDOB : "");
    const ageInYears = getYearsDiff(dobDate);
    for (const band of policy.ageBand) {
      let minAge = band.min.includes("Up to 1 Year") ? 0 : parseInt(band.min);
      let maxAge = band.max.includes("Years")
        ? parseInt(band.max)
        : Number.MAX_SAFE_INTEGER;

      if (ageInYears >= minAge && ageInYears <= maxAge) {
        return true;
      }
    }

    toast.warning("This age band not allowed in this policy");
    return false;
  };
  const checkMaxAgeForChildren = (
    maxChildAge: string,
    selectedMemberDOB: any,
    selectedRelation: string
  ) => {
    if (selectedRelation === "Son" || selectedRelation === "Daughter") {
      const dobDate = new Date(selectedMemberDOB ? selectedMemberDOB : "");
      const ageInYears = getYearsDiff(dobDate);
      let maxage: number = +extractNumbersFromString(maxChildAge);
      if (ageInYears > maxage) {
        toast.warning(`Permissible maximum age for children is ${maxChildAge}`);
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  const handlePolicyCheck = (checked: boolean, data: any) => {
    if (existingAssignedPolicies.includes(data._id)) {
      return;
    }
    let newSelectedPolicies: string[] = [...selectedPolicies];
    let arr: any = [...selectedPoliciesNew];
    if (checked) {
      if (data.gradeCriteria === "Custom") {
        getPolicyGradeDropdownValues(data._id, data.policyNumber);
      }

      arr.push(data);
      newSelectedPolicies.push(data._id);
    } else {
      newSelectedPolicies = newSelectedPolicies.filter((e) => e !== data._id);
      arr = arr.filter((e: any) => e._id !== data._id);

      let newDropdownArr = [...dropdownArr];
      newDropdownArr = newDropdownArr.filter((e) => e.policyId !== data._id);

      setDropdownArr(newDropdownArr);
    }

    setSelectedPoliciesNew(arr);
    setSelectedPolicies(newSelectedPolicies);
  };

  const getPolicyGradeDropdownValues = (id: string, policyNumber: string) => {
    const onSuccess = (res: any) => {
      setDropdownArr([
        ...dropdownArr,
        {
          policyId: id,
          customValue: "",
          options: res.data.data.map((item: any) => {
            var match = /\([0-9]+/.exec(item.label);

            if (match) {
              const obj = {
                ...item,
                label:
                  item.label.substring(0, match.index + 1) +
                  formatCurrency(
                    Number(
                      item.label.substring(
                        match.index + 1,
                        item.label.length - 1
                      )
                    )
                  ) +
                  ")",
              };
              return obj;
            } else {
              return item;
            }
          }),
          policyNumber,
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_POLICY_SERVICES.policy_grade_dropdown(
      onSuccess,
      onError,
      id,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const saveAssignedPolicies = () => {
    //now we are stopping the endorsement creation from assign policies

    // selectedPoliciesNew.map((newPolicy: any) => {
    //   const policyEndDate = new Date(newPolicy.policyDetails.endDate);
    //   const policyStartDate = new Date(newPolicy.policyDetails.startDate);
    //   const currentDate = new Date();

    //   const isTodayBetweenPolicyDates =
    //     currentDate >= policyStartDate && currentDate <= policyEndDate;
    //   if (isTodayBetweenPolicyDates) {
    //     CreateEndorsementForNomineeAndMember(
    //       "Member Addition",
    //       "",
    //       true,
    //       newPolicy._id,
    //       newPolicy.policyNumber
    //     );
    //   }
    // });
    if (disableSaveAss) return;
    if (selectedPoliciesNew?.length > 0) {
      if (
        selectedMemberType?.toLowerCase() === "employee" &&
        dropdownArr.some((item, index) => {
          if (!item.customValue) {
            toast.error("Please select a custom Grade");
            setDropdownError({ index: index, flag: true });
            return true;
          }
          setDropdownError({ index: index, flag: false });
          return false;
        })
      ) {
        return;
      }

      setSelectedPoliciesNew([]);

      const onSuccess = (res: any) => {
        res?.data?.data?.map((msg: any) =>
          toast.warn(msg.message, {
            autoClose: false,
          })
        );
        setViewAssignPolicy(false);
        setDropdownArr([]);
        setDisableSaveAss(false);
        // getAssignedPolicies();
        if (
          selectedMemberType?.toLowerCase() === "depandent" ||
          selectedMemberType?.toLowerCase() === "dependent"
        ) {
          getDepAssignedPolicies();
        } else {
          getAssignedPolicies();
        }
      };

      const onError = (err: any) => {
        setDisableSaveAss(false);
        console.log(err);
      };

      const data: assignPolicyDTO = {
        policy: selectedPolicies.map((item) => {
          const dropdownVal = dropdownArr.filter((e) => e.policyId === item);

          return {
            policyId: item,
            customValue: dropdownVal[0]?.customValue?.id
              ? dropdownVal[0]?.customValue?.id
              : "",
          };
        }),
      };
      setDisableSaveAss(true);
      {
        usertypeinfo === "BROKER"
          ? EMPLOYEE_POLICY_SERVICES.save_assigned_policies(
            data,
            onSuccess,
            onError,
            employeeId
          )
          : EMPLOYER_EMPLOYEE_SERVICES.save_assigned_policies(
            data,
            onSuccess,
            onError,
            employeeId
          );
      }
    }
  };

  const getMembers = () => {
    const onSuccess = (res: any) => {
      setEmployeeMembers(res.data.data);
    };

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_POLICY_SERVICES.get_members(
      onSuccess,
      onError,
      employeeId,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const getNominees = () => {
    const onSuccess = (res: any) => {
      setEmployeeNominees(res.data.data);
    };

    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYEE_POLICY_SERVICES.get_nominees(
      onSuccess,
      onError,
      employeeId,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const handleAddMemberToPolicy = () => {
    if (disableSaveEmp) return;
    const onSuccess = (res: any) => {
      getMembers();
      setAddMember(false);
      setToggleExistingOrNew(true);
      getAssignedPolicies();
      setNewMemberDetails({
        dob: "",
        warnDob: false,
        fullName: "",
        warnFullName: false,
        relationWithEmployee: "",
        warnRelationWithEmployee: false,
      });
      setMemberToAdd({ fullName: "", relation: "", _id: "" });
      setSelectedFamily("");
      setTimeout(() => {
        setDisableSaveEmp(false);
      }, 1000);

      const policyEndDate = new Date(policyBeingWorkedOn.endDate);
      policyEndDate.setDate(policyEndDate.getDate() + 1);

      const policyStartDate = new Date(policyBeingWorkedOn.startDate);
      policyStartDate.setDate(policyStartDate.getDate() + 1);

      const currentDate = new Date();
      const isTodayBetweenPolicyDates =
        currentDate >= policyStartDate && currentDate <= policyEndDate;

      if (
        isTodayBetweenPolicyDates &&
        (policyBeingWorkedOn.status === 60 || policyBeingWorkedOn.status === 41)
      ) {
        CreateEndorsementForNomineeAndMember(
          "Member Addition",
          res.data.data._id,
          false,
          "",
          "",
          `${res.data.data.preFix}${res.data.data.employeeId}`
        );
      }
    };

    const onError = (err: any) => {
      setDisableSaveEmp(false);
      toast.error(err?.response?.data?.errors[0]?.message);
    };
    let sman = newMemberDetails;
    if (toggleExistingOrNew) {
      sman.warnRelationWithEmployee =
        selectedFamily.length === 0 || selectedFamily === "" ? true : false;
    } else {
      sman.warnDob =
        newMemberDetails.dob === null || newMemberDetails.dob === ""
          ? true
          : false;
      sman.warnFullName = newMemberDetails.fullName.length === 0 ? true : false;
      sman.warnRelationWithEmployee =
        newMemberDetails.relationWithEmployee.length === 0 ? true : false;
    }
    setNewMemberDetails({ ...sman });
    if (
      sman.warnFullName === true ||
      sman.warnRelationWithEmployee === true ||
      sman.warnDob === true
    ) {
      return;
    }

    let data;
    if (toggleExistingOrNew) {
      const obj: AddExistingMemberToPolicy = {
        memberId: selectedFamily,
        attachedClient: selectedClient?.id,
        policyId: policyBeingWorkedOn?.policyId,
      };
      data = obj;
    } else {
      const obj: AddNewMemberToPolicyDTO = {
        policyId: policyBeingWorkedOn?.policyId,
        attachedClient: selectedClient?.id,
        members: [
          {
            fullName: newMemberDetails.fullName,
            relationWithEmployee: newMemberDetails.relationWithEmployee,
            dob: newMemberDetails.dob,
          },
        ],
      };
      data = obj;
      if (newMemberDetails.dob === null) {
        return toast.error("Enter DOB");
      }
    }
    let allowedToSubmitMember = false;
    if (toggleExistingOrNew) {
      allowedToSubmitMember =
        checkAllowedAgeBand(memberToAdd) &&
        checkAllowedRelation(memberToAdd.relation) &&
        checkAllowedStatus() &&
        checkAllowedParentOrParentsInLaw(memberToAdd.relation) &&
        checkMaxAgeForChildren(
          policyBeingWorkedOn.maxChildAge,
          memberToAdd.dob,
          memberToAdd.relation
        );
    } else if (!toggleExistingOrNew) {
      allowedToSubmitMember =
        checkAllowedAgeBand(newMemberDetails) &&
        checkAllowedRelation(newMemberDetails.relationWithEmployee) &&
        checkAllowedStatus() &&
        checkAllowedParentOrParentsInLaw(
          newMemberDetails.relationWithEmployee
        ) &&
        checkMaxAgeForChildren(
          policyBeingWorkedOn.maxChildAge,
          newMemberDetails.dob,
          newMemberDetails.relationWithEmployee
        );

      // &&
      // checkLocation(policyBeingWorkedOn);
    }

    let tempVar: any = memberToAdd;
    if (allowedToSubmitMember) {
      setDisableSaveEmp(true);
      EMPLOYEE_POLICY_SERVICES.add_member_to_policy(
        onSuccess,
        onError,
        data,
        employeeId,
        Number(!toggleExistingOrNew),
        tempVar?.is_dep,
        usertypeinfo.toLocaleLowerCase()
      );
    }
  };

  const handleCreateECard = () => {
    if (disableSaveCard) return;
    let warn = { ...eCardError };
    warn.warnMember = selectMember ? false : true;
    warn.warnNumber = healthEcardNumber ? false : true;
    warn.warnDoc = ecardDoc ? false : true;
    seteCardError(warn);

    const onSuccess = (res: any) => {
      setAddHealth(false);
      setSelectMember("");
      setHealthEcardNumber("");
      setDocName("");
      setEcardDoc("");
      seteCardError({
        warnMember: false,
        warnNumber: false,
        warnDoc: false,
      });
      getAssignedPolicies();
      setDisableSaveCard(false);
    };

    const onError = (err: any) => {
      console.log(err);
      setDisableSaveCard(false);
    };

    const data: CreateECardDTO = {
      doc: ecardDoc,
      ecardNumber: healthEcardNumber,
      memberId: selectMember,
      policyId: policyBeingWorkedOn.policyId,
    };

    if (
      !eCardError.warnDoc &&
      !eCardError.warnMember &&
      !eCardError.warnNumber
    ) {
      setDisableSaveCard(true);
      EMPLOYEE_POLICY_SERVICES.create_policy_ecard(
        onSuccess,
        onError,
        data,
        employeeId,
        usertypeinfo.toLocaleLowerCase()
      );
    }
  };

  const handleDeassignPolicy = (relationId: string) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    const onSuccess = (res: any) => {
      getAssignedPolicies();
    };

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_POLICY_SERVICES.deassign_employee_policy(
      onSuccess,
      onError,
      employeeId,
      relationId
    );
  };

  const handleEntityDeassign = (
    type: PolicyDeassignEntityType,
    relationId: string
  ) => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    const onSuccess = (res: any) => {
      getAssignedPolicies();
    };

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_POLICY_SERVICES.deassign_entity_policy(
      onSuccess,
      onError,
      type,
      employeeId,
      policyBeingWorkedOn.policyId,
      relationId,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const checkRelationExist = (relation: string) => {
    if (
      relation?.toLowerCase() === "wife" ||
      relation?.toLowerCase() === "husband"
    ) {
      const isExist = employeeMembers?.some(
        (member) =>
          member?.relation?.toLowerCase() === "wife" ||
          member?.relation?.toLowerCase() === "husband"
      );
      if (isExist) {
        toast.warning("Spouse already exists");
      }
      return !isExist;
    } else {
      return true;
    }
  };

  const handleCreateNomineeInPolicy = () => {
    if (disableSaveNom) return;
    const onSuccess = (res: any) => {
      setToggle(true);
      getMembers();
      getNominees();
      setAddNominee(false);
      getAssignedPolicies();
      setFullName("");
      setRelationEmployee("");
      setNomineeDob("");
      setSelectedNominee("");
      setNomineeToAdd({ label: "", relation: "", _id: "", dob: "" });
      setTimeout(() => {
        setDisableSaveNom(false);
      }, 1500);
      if (res.status === 200) {
        const policyEndDate = new Date(policyBeingWorkedOn.endDate);
        policyEndDate.setDate(policyEndDate.getDate() + 1);
        const policyStartDate = new Date(policyBeingWorkedOn.startDate);
        policyStartDate.setDate(policyStartDate.getDate() + 1);

        const currentDate = new Date();
        const isTodayBetweenPolicyDates =
          currentDate >= policyStartDate && currentDate <= policyEndDate;

        if (
          isTodayBetweenPolicyDates &&
          (policyBeingWorkedOn?.status === 60 ||
            policyBeingWorkedOn?.status === 41)
        ) {
          CreateEndorsementForNomineeAndMember(
            "Nominee Addition",
            "",
            false,
            "",
            "",
            "",
            res.data.data._id
          );
        }
      }
    };
    const onError = (err: any) => {
      setDisableSaveNom(false);
      console.log(err);
    };
    if (toggle) {
      if (!selectedNominee || selectedNominee?.trim() === "") {
        return toast.warning("Select Nominee");
      }
    } else {
      if (
        !fullName ||
        fullName?.trim() === "" ||
        !relationEmployee ||
        !nomineeDob
      ) {
        return toast.warning("Enter all fields");
      }
    }

    let obj;
    if (toggle) {
      const data = {
        policyId: policyBeingWorkedOn.policyId,
        nomineeId: selectedNominee,
      };
      obj = data;
    } else {
      const data: CreateNomineeInPolicyDTO = {
        policyId: policyBeingWorkedOn.policyId,
        fullName,
        relation: relationEmployee,
        dob: nomineeDob || "",
        age: calculateAgeFromDob(nomineeDob),
      };
      obj = data;
    }

    if (nomineeDob === null) {
      return toast.error("Enter DOB");
    }
    if (checkRelationExist(relationEmployee)) {
      setDisableSaveNom(true);
      EMPLOYEE_POLICY_SERVICES.create_nominee_in_policy(
        onSuccess,
        onError,
        employeeId,
        obj,
        usertypeinfo.toLocaleLowerCase(),
        toggle === false ? "1" : "0"
      );
    }
  };

  const CreateEndorsementForNomineeAndMember = (
    endorsementFor: string,
    depId?: string,
    multiAssignedPolicy?: boolean,
    policyId?: string,
    policyNumber?: string,
    memberIndentification?: string,
    nomineeId?: string
  ) => {
    let data = {
      memberId: depId ? depId : employeeId, // wrong "65f91b55df711f0ba0411773"
      member: memberIndentification || memberId, // wrong "EMP0027"
      policyId: multiAssignedPolicy ? policyId : policyBeingWorkedOn.policyId,
      policy: multiAssignedPolicy
        ? policyNumber
        : policyBeingWorkedOn.policyNumber,
      clientId: selectedClient?.id,
      client: selectedClient?.label,
      type: endorsementFor.includes("Member") ? "COMMERCIAL" : "NON COMMERCIAL",
      endorsementFor: endorsementFor,
      old: "",
      new: {
        dob: "",
      },
      attachment: {},
      nomineeId,
      deletionDate: new Date(),
    };
    const onSuccess = (res: any) => { };

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_POLICY_SERVICES.CreateEndorsementForNominee(
      onSuccess,
      onError,
      data,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  const createDropDownForPolicy = (policyBeingWorkedOn: any) => {
    let mainEmp = [
      {
        dob: selectedMemberDOB,
        fullName: empFirstName,
        relationWithEmployee: relationWithEmployee,
        _id: employeeId,
      },
    ];
    let combineArray = [...mainEmp, ...policyBeingWorkedOn.members];
    let arr = combineArray.filter((item: any) => {
      let retVal = true;
      policyBeingWorkedOn.ecard &&
        policyBeingWorkedOn.ecard.map((filterItem: any) => {
          if (filterItem.memberId === item._id) {
            retVal = false;
          }
        });
      return retVal;
    });
    let dropdownData: any = [];
    arr.map((data: any) => {
      dropdownData.push({ key: data._id, label: data.fullName });
    });
    setdropdowndataDynamic(dropdownData);
  };

  const statusValue = (members: any) => {
    for (const data of members) {
      if (data._id === policyMemberRelationId) {
        const foundDropdown = prioritystatusData.find(
          (dropdown: any) =>
            dropdown.label.toString() === data.enrolment_status?.toString()
        );
        if (foundDropdown) {
          return foundDropdown;
        }
      }
    }
    return null;
  };
 
  const checkAllowedRelation = (addUser: string) => {
    let validationFlag = false;
    const allowedMember = policyBeingWorkedOn.planType.basicConfig;
    const isSpouseAllowed = allowedMember.includes("1S");

    // Check if spouse is allowed and if the user is Husband or Wife
    if (
      isSpouseAllowed &&
      (addUser?.toLowerCase() === "husband" ||
        addUser?.toLowerCase() === "wife")
    ) {
      let isSpousePresentInMember = policyBeingWorkedOn.members.some((data) => {
        return (
          data.relationWithEmployee?.toLowerCase() === "husband" ||
          data.relationWithEmployee?.toLowerCase() === "wife"
        );
      });

      if (isSpousePresentInMember) {
        toast.warning("Spouse is already present in Policy");
      } else {
        return true;
      }
    } else if (
      !isSpouseAllowed &&
      (addUser?.toLowerCase() === "husband" ||
        addUser?.toLowerCase() === "wife")
    ) {
      toast.warning("Spouse is Not allowed in this policy");
    }

    //Here check the child is present or not

    const isChildAllowed = allowedMember.includes("1C");

    if (
      isChildAllowed &&
      (addUser?.toLowerCase() === "son" ||
        addUser?.toLowerCase() === "daughter")
    ) {
      // Count the occurrences of '1C'
      let totalAllowedChildInPolicy = allowedMember
        .split("+")
        .filter((item) => item === "1C").length;
      let childCountInMember = 0;

      policyBeingWorkedOn.members.forEach((employee: any) => {
        if (
          employee.relationWithEmployee?.toLowerCase() === "son" ||
          employee.relationWithEmployee?.toLowerCase() === "daughter"
        ) {
          childCountInMember++;
        }
      });
      if (totalAllowedChildInPolicy > childCountInMember) {
        return true;
      } else {
        toast.warning("Child Count is exceeded in this Policy");
      }
    } else if (
      !isChildAllowed &&
      (addUser?.toLowerCase() === "son" ||
        addUser?.toLowerCase() === "daughter")
    ) {
      toast.warning("Child is Not allowed in this policy");
    }

    // Check For Parent

    const allowedParent = policyBeingWorkedOn.planType.parentConfig;
    const isParentAllowed = allowedParent.includes("1P");

    // Check if Mother is allowed and if the user is Mother
    if (isParentAllowed && addUser?.toLowerCase() === "mother") {
      let isMotherPresentInMember = policyBeingWorkedOn?.members?.some(
        (data) => {
          return data.relationWithEmployee?.toLowerCase() === "mother";
        }
      );

      if (isMotherPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        return true;
      }
    } else if (!isParentAllowed && addUser?.toLowerCase() === "mother") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check if Father is allowed and if the user is Father
    if (isParentAllowed && addUser?.toLowerCase() === "father") {
      let isFatherPresentInMember = policyBeingWorkedOn?.members?.some(
        (data) => {
          return data.relationWithEmployee?.toLowerCase() === "father";
        }
      );

      if (isFatherPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        return true;
      }
    } else if (!isParentAllowed && addUser?.toLowerCase() === "father") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check for parent in law

    const isParentInLawAllowed = allowedParent.includes("1PIL");

    // Check if Mother-In-Law is allowed and if the user is Mother-In-Law
    if (isParentInLawAllowed && addUser?.toLowerCase() === "mother-in-law") {
      let isMotherInLawPresentInMember = policyBeingWorkedOn?.members?.some(
        (data) => {
          return data.relationWithEmployee?.toLowerCase() === "mother-in-law";
        }
      );

      if (isMotherInLawPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        return true;
      }
    } else if (
      !isParentInLawAllowed &&
      addUser?.toLowerCase() === "mother-in-law"
    ) {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check if Father-In-Law is allowed and if the user is Father-In-Law
    if (isParentInLawAllowed && addUser?.toLowerCase() === "father-in-law") {
      let isFatherInLawPresentInMember = policyBeingWorkedOn.members.some(
        (data) => {
          return data.relationWithEmployee?.toLowerCase() === "father-in-law";
        }
      );

      if (isFatherInLawPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        return true;
      }
    } else if (
      !isParentInLawAllowed &&
      addUser?.toLowerCase() === "father-in-law"
    ) {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }
    if (
      addUser?.toLowerCase() === "sister" ||
      addUser?.toLowerCase() === "brother"
    ) {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }
    return validationFlag;
  };

  const checkAllowedParentOrParentsInLaw = (addUser: string) => {
    let allowed = true;
    const { setOfParents } = policyBeingWorkedOn.planType;
    if (setOfParents === "both") return true;
    if (
      addUser?.toLowerCase() === "mother-in-law" ||
      addUser?.toLowerCase() === "father-in-law"
    ) {
      let isParentPresentInMember = policyBeingWorkedOn.members.some((data) => {
        return (
          data.relationWithEmployee?.toLowerCase() === "mother" ||
          data.relationWithEmployee?.toLowerCase() === "father"
        );
      });
      if (isParentPresentInMember) {
        toast.warn(
          "This policy allows you to add  either Parent or Parents-In-Law"
        );
        allowed = false;
      } else {
        allowed = true;
      }
    } else if (
      addUser?.toLowerCase() === "mother" ||
      addUser?.toLowerCase() === "father"
    ) {
      let isParentPresentInMember = policyBeingWorkedOn?.members?.some(
        (data) => {
          return (
            data.relationWithEmployee?.toLowerCase() === "mother-in-law" ||
            data.relationWithEmployee?.toLowerCase() === "father-in-law"
          );
        }
      );
      if (isParentPresentInMember) {
        toast.warn(
          "This policy allows you to add  either Parent or Parents-In-Law"
        );
        allowed = false;
      } else {
        allowed = true;
      }
    }

    return allowed;
  };

  const checkAllowedRelationForDep = (
    basicConfig: string,
    parentConfig: string
  ) => {
    const allowedMember = basicConfig;
    const isSpouseAllowed = allowedMember.includes("1S");
    const isChildAllowed = allowedMember.includes("1C");
    const allowedParent = parentConfig;
    const isParentAllowed = allowedParent.includes("1P");
    const isParentInLawAllowed = allowedParent.includes("1PIL");

    if (
      !isSpouseAllowed ||
      !isChildAllowed ||
      !isParentAllowed ||
      !isParentInLawAllowed
    ) {
      return toast.warning(
        `${relationWithEmployee || "This relation"
        } is Not allowed in this policy`
      );
    }
    return true;
  };

  const checkAllowedAgeBand = (data: any) => {
    let policy: any = policyBeingWorkedOn;

    // Check if policy.ageBand is iterable
    if (!policy.ageBand || !Array.isArray(policy.ageBand)) {
      console.error("policy.ageBand is not iterable");
      return false;
    }

    const dobDate = new Date(data.dob);
    const ageInYears = getYearsDiff(dobDate);

    for (const band of policy.ageBand) {
      let minAge = band.min.includes("Up to 1 Year") ? 0 : parseInt(band.min);
      let maxAge = band.max.includes("Years")
        ? parseInt(band.max)
        : Number.MAX_SAFE_INTEGER;

      if (ageInYears >= minAge && ageInYears <= maxAge) {
        return true;
      }
    }

    toast.warning("This age band is not allowed in this policy");
    return false;
  };

  // Check for allowed status in policy
  const checkAllowedStatus = () => {
    let existStatusCount = 0;
    policyBeingWorkedOn.members.forEach((employee: any) => {
      if (
        employee.enrolment_status === 40 ||
        employee.relationWithEmployee === 41
      ) {
        existStatusCount++;
      }
    });

    if (
      policyBeingWorkedOn.policySettings.maxMemberPerFamily > existStatusCount
    ) {
      return true;
    } else {
      toast.warning(
        "Member's Status is not allowing you to add in this Policydfsf"
      );
      return false;
    }
  };

  const getYearsDiff = (dob: Date) => {
    const today = new Date();
    const diffMilliseconds = today.getTime() - dob.getTime();
    const ageInYears = diffMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Consider leap years
    const roundedAge = Math.ceil(ageInYears);
    return roundedAge;
  };

  const Clicktoogle = () => {
    setToggle(true);
    setFullName("");
    setRelationEmployee("");
    setNomineeDob("");
    setSelectedNominee("");
    setNomineeToAdd({ label: "", relation: "", _id: "", dob: "" });
  };
  const Clicked = () => {
    setToggle(false);
    setFullName("");
    setRelationEmployee("");
    setNomineeDob("");
    setSelectedNominee("");
    setNomineeToAdd({ label: "", relation: "", _id: "", dob: "" });
  };
  const giveMemberName = (policyEcard: any) => {
    // Filter employeeMembers based on policyEcardId
    const filteredMembers = employeeMembers.filter(
      (member) => member._id === policyEcard.memberId
    );
    // Extract fullName from filtered members
    const memberNames = filteredMembers.map((member) => member.fullName);
    return memberNames;
  };

  return (
    <>
      <DocumentViewer
        url={selectedDocUrl}
        open={docviewOpenStatus}
        onClose={() => {
          setDocviewOpenStatus(false);
        }}
      />
      <Grid container spacing={3}>
        <Grid
          xs={2}
          style={{
            maxWidth: "260px",
            position: "relative",
            top: "-75px",
            paddingLeft: "0",
          }}
        >
          <div
            className="leftSection h-100"
            style={{ position: "fixed", paddingLeft: "8px" }}
          >
            <Link
              className={
                sectionStatus === "policyDetails"
                  ? "active sectionLink policyDetails"
                  : "sectionLink policyDetails"
              }
              onClick={() => {
                scrollToSection(policyDetails);
                SetSectionStatus("policyDetails");
              }}
            >
              <span> Policy Details</span>
            </Link>
          </div>
        </Grid>
        <Grid xs>
          <>
            <Grid container spacing={3}>
              <Grid xs={12} className="text-right">
                <Link
                  underline="none"
                  className="greenBtn addfile"
                  onClick={() => {
                    if (editPermission === false) {
                      return toast.error("Permissions Not Allowed");
                    }

                    if (
                      selectedMemberType?.toLowerCase() === "employee" &&
                      (mainStatus !== 32 ||
                        employementStatus === 35 ||
                        employementStatus === 36)
                    ) {
                      return toast.error(
                        "No new policy may be assigned to the member as his/her Employment Status is Resigned/Terminated"
                      );
                    } else {
                      setViewAssignPolicy(true);
                    }
                  }}
                >
                  Assign Policy
                </Link>
              </Grid>
            </Grid>

            <Box ref={policyDetails} className="detailSection mt-4">
              <div className="sectionTitle">
                <h4>Policies Details</h4>
              </div>
              <Box className="employee_detailpolicy">
                {employeeAssignedPolicies.length > 0 &&
                  // employeeAssignedPolicies.sort(compareDates)
                  employeeAssignedPolicies?.map((policy, index: number) => {
                    let enroledMembers: number = policy.members?.filter(
                      (data: any) => data?.enrolment_status === 41
                    )?.length;
                    if (
                      policy?.status === 41 &&
                      selectedMemberType?.toLowerCase() === "employee"
                    ) {
                      enroledMembers += 1;
                    }
                    let enroledNominees: number =
                      policy?.nominee?.filter(
                        (data: any) => data?.enrolment_status === 44
                      )?.length || 0;
                    return (
                      <Accordion
                        className="Accordion_section employee_policiesSection accordion_padding"
                        onChange={() => {
                          setPolicyBeingWorkedOn(policy);
                          setPolicyBeingWorkedOnIndex(index);
                          createDropDownForPolicy(policy);
                          handleChange("panel1");
                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid xs className="px-0">
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                display: "inline-block",
                                width: "100%",
                                padding: "0",
                              }}
                            >
                              <Typography
                                className="title"
                                style={{ width: "100%" }}
                              >
                                <Grid
                                  container
                                  spacing={3}
                                  style={{ paddingLeft: "0" }}
                                >
                                  <Grid xs={3} sm={3}>
                                    <h5>{policy.policyNumber}</h5>
                                  </Grid>
                                  <Grid xs={3} sm={3}>
                                    <h5>
                                      CD Balance -{" "}
                                      {formatCurrency(policy?.cdBalance ?? "0")}
                                    </h5>
                                  </Grid>
                                  <Grid
                                    xs={6}
                                    sm={6}
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "25px",
                                      alignItems: "center",
                                    }}
                                  >
                                    {selectedMemberType?.toLowerCase() ===
                                      "employee" ? (
                                      <div
                                        //  style={{ minWidth: '240px' }}
                                        id={`_${policy.status?.toString()}`}
                                      >
                                        <div style={{ minWidth: "225px" }}>
                                          <div
                                            className="status_dropdown"
                                            style={{ width: "auto" }}
                                            id={`_${policy.status?.toString()}`}
                                          >
                                            {policy.status === 40 ? (
                                              <EnrolmentInitated
                                                id={`_${policy.status?.toString()}`}
                                              />
                                            ) : policy.status === 41 ? (
                                              <Enrolled
                                                id={`_${policy.status?.toString()}`}
                                              />
                                            ) : policy.status === 42 ? (
                                              <Optout
                                                id={`_${policy.status?.toString()}`}
                                              />
                                            ) : policy.status === 43 ? (
                                              <NA
                                                id={`_${policy.status?.toString()}`}
                                              />
                                            ) : policy.status === 60 ? (
                                              <HowToRegIcon
                                                id={`_${policy.status?.toString()}`}
                                              />
                                            ) : policy.status === 61 ? (
                                              <Requested
                                                id={`_${policy.status?.toString()}`}
                                              />
                                            ) : policy.status === 73 ? (
                                              <Expired
                                                id={`_${policy.status?.toString()}`}
                                              />
                                            ) : null}

                                            <span>
                                              {
                                                prioritystatusData.find(
                                                  (data: any) =>
                                                    data.label.toString() ===
                                                    policy.status?.toString()
                                                )?.value
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      // <div id={`_${policy.status?.toString()}`}                                        >
                                      //   <div className="status_dropdown"
                                      //     id={`_${statusValue(policy.members)?.label}`}
                                      //   >
                                      //     {statusValue(policy.members)
                                      //       ?.label === 40 ? (
                                      //       <EnrolmentInitated
                                      //         id={`_${statusValue(policy.members)?.label}`}
                                      //       />
                                      //     ) : statusValue(policy.members)
                                      //       ?.label === 41 ? (
                                      //       <Enrolled
                                      //         id={`_${statusValue(policy.members)?.label}`}
                                      //       />
                                      //     ) : statusValue(policy.members)
                                      //       ?.label === 42 ? (
                                      //       <Optout
                                      //         id={`_${statusValue(policy.members)?.label}`}
                                      //       />
                                      //     ) : statusValue(policy.members)
                                      //       ?.label === 43 ? (
                                      //       <NA
                                      //         id={`_${statusValue(policy.members)?.label}`}
                                      //       />
                                      //     ) : null}
                                      //     <span>
                                      //       {
                                      //         statusValue(policy.members)?.value
                                      //       }
                                      //     </span>
                                      //   </div>
                                      // </div>
                                      <div
                                        id={`_${policy?.dep_status?.toString()}`}
                                      >
                                        <div
                                          className="status_dropdown"
                                          id={`_${policy?.dep_status?.toString()}`}
                                        >
                                          {policy?.dep_status === 40 ? (
                                            <EnrolmentInitated
                                              id={`_${policy?.dep_status?.toString()}`}
                                            />
                                          ) : policy?.dep_status === 41 ? (
                                            <Enrolled
                                              id={`_${policy?.dep_status?.toString()}`}
                                            />
                                          ) : policy?.dep_status === 42 ? (
                                            <Optout
                                              id={`_${policy?.dep_status?.toString()}`}
                                            />
                                          ) : policy?.dep_status === 43 ? (
                                            <NA
                                              id={`_${policy?.dep_status?.toString()}`}
                                            />
                                          ) : null}
                                          <span>
                                            {
                                              prioritystatusData.find(
                                                (data: any) =>
                                                  data.label.toString() ===
                                                  policy?.dep_status?.toString()
                                              )?.value
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <h6>{enroledMembers} Members</h6>{" "}
                                    <h6>{policy.productType}</h6>
                                    {/* <h6>{policy.insurer}</h6> */}
                                  </Grid>
                                </Grid>
                              </Typography>
                            </AccordionSummary>
                          </Grid>
                          <Grid
                            xs={"auto"}
                            style={{
                              alignSelf: "Center",
                              display: "flex",
                              justifyContent: "end",
                              padding: "0",
                            }}
                          >
                            {/* {selectedMemberType !== "Dependent" &&
                            (policy.deleteable === false ? null : (
                              <div
                                className="text-right"
                                style={{ alignSelf: "center" }}
                              >
                                <Link
                                  className="redBtn delete ml-3"
                                  underline="none"
                                  onClick={() =>
                                    handleDeassignPolicy(policy.relationId)
                                  }
                                >
                                  Remove
                                </Link>
                              </div>
                            ))} */}
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ paddingRight: "0" }}
                            >
                              <Typography></Typography>
                            </AccordionSummary>
                          </Grid>
                        </Grid>
                        <AccordionDetails style={{ padding: "0" }}>
                          <Grid container spacing={3}>
                            <Grid xs={12} className="policies_detail">
                              <div>
                                <h4>
                                  Policy Number
                                  <span>{policy?.policyNumber}</span>
                                </h4>
                              </div>
                              <div>
                                <h4>
                                  Sum Insured{" "}
                                  <span>
                                    {formatCurrency(
                                      extractNumbersFromString(
                                        policy.sumInsured
                                      )
                                    )}
                                  </span>
                                </h4>
                              </div>
                              <div>
                                <h4>
                                  Start Date{" "}
                                  <span>
                                    {format(
                                      new Date(policy.startDate),
                                      "dd-MM-yyyy"
                                    )}
                                  </span>
                                </h4>
                              </div>
                              <div>
                                <h4>
                                  End Date{" "}
                                  <span>
                                    {format(
                                      new Date(policy.endDate),
                                      "dd-MM-yyyy"
                                    )}
                                  </span>
                                </h4>
                              </div>
                              <div>
                                <h4>
                                  Insurer Name
                                  <span>
                                    {policy?.insurer || policy?.insurerName}
                                  </span>
                                </h4>
                              </div>
                              <div>
                                <h4>
                                  Product Type{" "}
                                  <span>{policy?.productType}</span>
                                </h4>
                              </div>
                            </Grid>
                          </Grid>

                          {/* member details */}
                          <Grid container spacing={3}>
                            <Grid xs={6}>
                              <h4 className="subHeading">Member Details</h4>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              <div className="actionBtns">
                                {selectedMemberType?.toLowerCase() !==
                                  "depandent" &&
                                  selectedMemberType?.toLowerCase() !==
                                  "dependent" &&
                                  policy?.productType != "Group Term" &&
                                  policy.status !== 73 && (
                                    <Link
                                      className="greenBtn addfile"
                                      sx={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (editPermission === false) {
                                          return toast.error(
                                            "Permissions Not Allowed"
                                          );
                                        }
                                        if (
                                          enroledMembers >=
                                          policy?.policySettings
                                            ?.maxMemberPerFamily
                                        ) {
                                          return toast.warning(
                                            "Maximum number of family members Enroled limit exceeds"
                                          );
                                        }

                                        if (
                                          policy.status === 42 ||
                                          policy.status === 61
                                        ) {
                                          return toast.warning(
                                            "Since the Employee has been Opt-Out from this policy, no new member may be added to this Policy."
                                          );
                                        }
                                        setPolicyBeingWorkedOn(policy);
                                        setPolicyBeingWorkedOnIndex(index);
                                        setAddMember(true);
                                      }}
                                    >
                                      Add Member
                                    </Link>
                                  )}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employeeDetailtable">
                                <div className="employeePolicy_table">
                                  <ul className="heading">
                                    <li>Member ID </li>
                                    <li>Member Type</li>
                                    <li>Member Name</li>
                                    <li>Relationship with Employee</li>
                                    <li>DOB</li>
                                    <li>Age</li>
                                    <li>Enrolment Status</li>
                                    <li>Enrolment Date</li>
                                    <li>Enrolment Time</li>
                                  </ul>
                                  {selectedMemberType?.toLowerCase() ===
                                    "employee" && (
                                      <ul className="subheading">
                                        <li>{memberId}</li>
                                        <li>Employee</li>
                                        <li>
                                          {empFirstName + " " + empLastName}
                                        </li>
                                        <li>Self</li>
                                        <li>
                                          {dob
                                            ? customFormatDate(dob)
                                            : customFormatDate(
                                              policy["Date Of Birth"] ?? ""
                                            )}
                                        </li>
                                        <li>
                                          {dob
                                            ? calculateAgeFromDob(dob)
                                            : calculateAgeFromDob(
                                              policy["Date Of Birth"] ?? ""
                                            )}
                                        </li>
                                        <li>
                                          <div>
                                            <div
                                              id={`_${policy.status?.toString()}`}
                                            >
                                              <div
                                                className="status_dropdown"
                                                id={`_${policy.status?.toString()}`}
                                              >
                                                {policy.status === 40 ? (
                                                  <EnrolmentInitated
                                                    id={`_${policy.status?.toString()}`}
                                                  />
                                                ) : policy.status === 41 ? (
                                                  <Enrolled
                                                    id={`_${policy.status?.toString()}`}
                                                  />
                                                ) : policy.status === 42 ? (
                                                  <Optout
                                                    id={`_${policy.status?.toString()}`}
                                                  />
                                                ) : policy.status === 43 ? (
                                                  <NA
                                                    id={`_${policy.status?.toString()}`}
                                                  />
                                                ) : policy.status === 60 ? (
                                                  <HowToRegIcon
                                                    id={`_${policy.status?.toString()}`}
                                                  />
                                                ) : policy.status === 61 ? (
                                                  <Requested
                                                    id={`_${policy.status?.toString()}`}
                                                  />
                                                ) : policy.status === 73 ? (
                                                  <Expired
                                                    id={`_${policy.status?.toString()}`}
                                                  />
                                                ) : null}
                                                <SelectDropdown
                                                  class_name="inputFieldd"
                                                  title=""
                                                  value={policy.status}
                                                  attrName={[
                                                    "setPriorityStatus",
                                                    policy,
                                                    enroledMembers,
                                                    policy?.policySettings
                                                      ?.maxMemberPerFamily,
                                                  ]}
                                                  value_update={updateMasterState}
                                                  dropdown_data={
                                                    prioritystatusData
                                                  }
                                                  warn_status={false}
                                                  disabled={
                                                    (usertypeinfo === "BROKER" &&
                                                      editPermission === false) ||
                                                    isIntegratedTpa(
                                                      policy?.tpa?.name
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          {policy?.enrollment_date
                                            ? customFormatDate(
                                              policy?.enrollment_date
                                            )
                                            : "-"}
                                        </li>
                                        <li>
                                          {policy?.enrollment_date
                                            ? customFormatTime(
                                              policy?.enrollment_date
                                            )
                                            : "-"}
                                        </li>
                                      </ul>
                                    )}
                                  {(selectedMemberType?.toLowerCase() ===
                                    "depandent" ||
                                    selectedMemberType?.toLowerCase() ===
                                    "dependent") &&
                                    usertypeinfo === "BROKER" ? (
                                    <>
                                      {policy.members.length > 0 &&
                                        policy.members
                                          ?.filter(
                                            (emp: any) =>
                                              emp.dependentId === memberId
                                          )
                                          ?.map((policyMember: any, index) => {
                                            return (
                                              <ul className="subheading">
                                                <li>
                                                  {policyMember?.dependentId}
                                                </li>
                                                <li>Dependent</li>
                                                <li>
                                                  {policyMember?.fullName}
                                                </li>
                                                <li>
                                                  {
                                                    policyMember?.relationWithEmployee
                                                  }
                                                </li>
                                                <li>
                                                  {customFormatDate(
                                                    policyMember?.dob
                                                  )}
                                                </li>
                                                <li>
                                                  {policyMember &&
                                                    calculateAgeFromDob(
                                                      policyMember?.dob
                                                    )}
                                                </li>
                                                <li>
                                                  <div>
                                                    <div
                                                      id={`_${policyMember.enrolment_status?.toString()}`}
                                                    >
                                                      <div
                                                        className="status_dropdown"
                                                        id={`_${policyMember.enrolment_status?.toString()}`}
                                                        style={{
                                                          minWidth: `${policyMember.enrolment_status ===
                                                              40
                                                              ? "200px"
                                                              : ""
                                                            }`,
                                                        }}
                                                      >
                                                        {policyMember.enrolment_status ===
                                                          40 ? (
                                                          <EnrolmentInitated
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          41 ? (
                                                          <Enrolled
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          42 ? (
                                                          <Optout
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          43 ? (
                                                          <NA
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          60 ? (
                                                          <HowToRegIcon
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          61 ? (
                                                          <Requested
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          73 ? (
                                                          <Expired
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : null}

                                                        <span>
                                                          {
                                                            prioritystatusData.find(
                                                              (data: any) =>
                                                                data.label ===
                                                                policyMember.enrolment_status
                                                            )?.value
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li>
                                                  {policyMember &&
                                                    customFormatDate(
                                                      policyMember.enrolmentDate
                                                    )}
                                                </li>
                                                <li>
                                                  {policyMember &&
                                                    customFormatTime(
                                                      policyMember.enrolmentDate
                                                    )}
                                                </li>
                                              </ul>
                                            );
                                          })}
                                    </>
                                  ) : (
                                    <>
                                      {policy.members.length > 0 &&
                                        policy.members.map(
                                          (policyMember: any, index) => {
                                            return (
                                              <ul className="subheading">
                                                <li>
                                                  {policyMember?.dependentId}
                                                </li>
                                                <li>Dependent</li>
                                                <li>
                                                  {policyMember?.fullName}{" "}
                                                </li>
                                                <li>
                                                  {
                                                    policyMember?.relationWithEmployee
                                                  }
                                                </li>
                                                <li>
                                                  {customFormatDate(
                                                    policyMember?.dob
                                                  )}
                                                </li>
                                                <li>
                                                  {policyMember &&
                                                    calculateAgeFromDob(
                                                      policyMember?.dob
                                                    )}{" "}
                                                </li>
                                                <li>
                                                  <div>
                                                    <div
                                                      id={`_${policyMember.enrolment_status?.toString()}`}
                                                    >
                                                      <div
                                                        className="status_dropdown"
                                                        id={`_${policyMember.enrolment_status?.toString()}`}
                                                        style={{
                                                          minWidth: `${policyMember.enrolment_status ===
                                                              40
                                                              ? "200px"
                                                              : ""
                                                            }`,
                                                        }}
                                                      >
                                                        {policyMember.enrolment_status ===
                                                          40 ? (
                                                          <EnrolmentInitated
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          41 ? (
                                                          <Enrolled
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          42 ? (
                                                          <Optout
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          43 ? (
                                                          <NA
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          60 ? (
                                                          <HowToRegIcon
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : policyMember.enrolment_status ==
                                                          61 ? (
                                                          <Requested
                                                            id={`_${policyMember.enrolment_status?.toString()}`}
                                                          />
                                                        ) : null}

                                                        {selectedMemberType?.toLowerCase() ===
                                                          "employee" ||
                                                          (selectedMemberType?.toLowerCase() !==
                                                            "employee" &&
                                                            policyMemberRelationId ===
                                                            policyMember._id) ? (
                                                          <SelectDropdown
                                                            class_name="inputFieldd"
                                                            title=""
                                                            value={
                                                              policyMember.enrolment_status
                                                            }
                                                            attrName={[
                                                              "setPriorityStatusDependent",
                                                              policyMember._id,
                                                              policy.policyId,
                                                              policyMember.enrolment_status,
                                                              policyMember?.dep_id,
                                                              policyMember?.dependentId,
                                                              enroledMembers,
                                                              policy
                                                                ?.policySettings
                                                                ?.maxMemberPerFamily,
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            dropdown_data={
                                                              prioritystatusData
                                                            }
                                                            warn_status={false}
                                                            disabled={
                                                              selectedMemberType?.toLowerCase() ===
                                                              "depandent" ||
                                                              selectedMemberType?.toLowerCase() ===
                                                              "dependent" ||
                                                              isIntegratedTpa(
                                                                policy?.tpa
                                                                  ?.name
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <span>
                                                            {
                                                              prioritystatusData.find(
                                                                (data: any) =>
                                                                  data.label ===
                                                                  policyMember.enrolment_status
                                                              )?.value
                                                            }
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li>
                                                  {policyMember &&
                                                    customFormatDate(
                                                      policyMember.enrolmentDate
                                                    )}
                                                </li>
                                                <li>
                                                  {policyMember &&
                                                    customFormatTime(
                                                      policyMember.enrolmentDate
                                                    )}
                                                </li>
                                              </ul>
                                            );
                                          }
                                        )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>

                          {/* nominee section */}
                          <Grid container spacing={3}>
                            <Grid xs={6}>
                              <h4 className="subHeading">Nominee Details</h4>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              <div className="actionBtns">
                                {selectedMemberType?.toLowerCase() ===
                                  "employee" &&
                                  policy.status !== 73 && (
                                    <Link
                                      className="greenBtn addfile"
                                      sx={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (editPermission === false) {
                                          return toast.error(
                                            "Permissions Not Allowed"
                                          );
                                        }
                                        if (!policy.policySettings.nomination) {
                                          return toast.error(
                                            "You can't add Nominees"
                                          );
                                        } else if (
                                          enroledNominees >=
                                          policy?.policySettings?.maxNomination
                                        ) {
                                          return toast.error(
                                            "Maximun number of nominees Enroled limit exceeds"
                                          );
                                        } else {
                                          setPolicyBeingWorkedOn(policy);
                                          setPolicyBeingWorkedOnIndex(index);
                                          setAddNominee(true);
                                        }
                                      }}
                                    >
                                      Add Nominee
                                    </Link>
                                  )}
                              </div>
                            </Grid>
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employeeDetailtable">
                                <div
                                  className="employeePolicy_table"
                                  style={{
                                    overflow: "hidden",
                                    minWidth: "3000px",
                                  }}
                                >
                                  <ul className="heading">
                                    <li>Nominee Name</li>
                                    <li>Relationship with Employee</li>
                                    <li>DOB</li>
                                    <li>Age</li>
                                    <li>Status</li>
                                    <li>Date</li>
                                    <li>Time</li>
                                  </ul>

                                  {Array.isArray(policy?.nominee) &&
                                    policy?.nominee.length === 1 &&
                                    Object.keys(policy.nominee[0]).length ===
                                    0 ? (
                                    ""
                                  ) : (
                                    <>
                                      {policy.nominee?.map(
                                        (policyNominee, index) => {
                                          return (
                                            <ul className="subheading">
                                              <li>{policyNominee.fullName}</li>
                                              <li>{policyNominee.relation}</li>
                                              <li>
                                                {customFormatDate(
                                                  policyNominee.dob
                                                )}
                                              </li>
                                              <li>
                                                {calculateAgeFromDob(
                                                  policyNominee.dob
                                                )}
                                              </li>
                                              <li>
                                                <div
                                                  id={`_${policyNominee.enrolment_status?.toString()}`}
                                                  style={{
                                                    width: "fit-content",
                                                  }}
                                                >
                                                  <div
                                                    className="status_dropdown"
                                                    id={`_${policyNominee.enrolment_status?.toString()}`}
                                                  >
                                                    {policyNominee.enrolment_status ==
                                                      44 ? (
                                                      <Enrolled
                                                        id={`_${policyNominee.enrolment_status?.toString()}`}
                                                      />
                                                    ) : policyNominee.enrolment_status ==
                                                      45 ? (
                                                      <Optout
                                                        id={`_${policyNominee.enrolment_status?.toString()}`}
                                                      />
                                                    ) : policyNominee.enrolment_status ==
                                                      63 ? (
                                                      <EnrollmentRequested
                                                        id={`_${policyNominee.enrolment_status?.toString()}`}
                                                      />
                                                    ) : policyNominee.enrolment_status ==
                                                      64 ? (
                                                      <EnrollmentRequested
                                                        id={`_${policyNominee.enrolment_status?.toString()}`}
                                                      />
                                                    ) : null}

                                                    {selectedMemberType?.toLowerCase() ===
                                                      "employee" ? (
                                                      <SelectDropdown
                                                        class_name="inputFieldd"
                                                        title=""
                                                        value={
                                                          policyNominee.enrolment_status
                                                        }
                                                        attrName={[
                                                          "setPriorityStatusNominee",
                                                          policyNominee._id,
                                                          policy?._id,
                                                          policyNominee.enrolment_status,
                                                          enroledNominees,
                                                          policy?.policySettings
                                                            ?.maxNomination,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        dropdown_data={
                                                          prioritystatusDataNominee
                                                        }
                                                        warn_status={false}
                                                        disabled={
                                                          usertypeinfo ===
                                                          "BROKER" &&
                                                          editPermission ===
                                                          false
                                                        }
                                                      />
                                                    ) : (
                                                      <span>
                                                        {
                                                          prioritystatusDataNominee?.find(
                                                            (data: any) =>
                                                              data.label ===
                                                              policyNominee.enrolment_status
                                                          )?.value
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </li>
                                              <li>
                                                {customFormatDate(
                                                  policyNominee.createdAt
                                                )}
                                              </li>
                                              <li>
                                                {customFormatTime(
                                                  policyNominee.createdAt
                                                )}
                                              </li>
                                              {/* this is delete button- code is working */}

                                              {/* {usertypeinfo === "BROKER" && (
                                      <li
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        {selectedMemberType !== "Dependent" && (
                                          <Link
                                            className="redBtn delete ml-0"
                                            onClick={() => {
                                              handleEntityDeassign(
                                                PolicyDeassignEntityType.NOMINEE,
                                                policyNominee._id
                                              );
                                            }}
                                            sx={{
                                              textDecoration: "none",
                                              cursor: "pointer",
                                            }}
                                          ></Link>
                                        )}
                                      </li>
                                    )} */}
                                            </ul>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>

                          {/* health/ e-card section */}
                          <Grid container spacing={3}>
                            <Grid xs={6}>
                              <h4 className="subHeading">
                                Health/E-Card Details
                              </h4>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              <div className="actionBtns">
                                {selectedMemberType?.toLowerCase() ===
                                  "employee" &&
                                  policy.status !== 73 && (
                                    <Link
                                      className="greenBtn addfile"
                                      sx={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          usertypeinfo === "BROKER" &&
                                          editPermission === false
                                        ) {
                                          return toast.error(
                                            "Permissions Not Allowed"
                                          );
                                        }
                                        if (
                                          !policy.policySettings.healthCard ||
                                          policy?.tpa?.integrated
                                        ) {
                                          return toast.warn(
                                            "You are not allowed to add E-Card"
                                          );
                                        } else if (
                                          policy.policySettings
                                            .healthCardIssued ===
                                          "Per Individual Member of the Family" &&
                                          policy.members.length + 1 ===
                                          policy.ecard.length
                                        ) {
                                          return toast.warn(
                                            "E-Card can't be more then Member's Count"
                                          );
                                        } else if (
                                          policy.policySettings
                                            .healthCardIssued ===
                                          "Per Employee Only" &&
                                          policy.ecard.length === 1
                                        ) {
                                          return toast.warn(
                                            "You are allowed to add 1 E-Card only for Employee"
                                          );
                                        } else {
                                          createDropDownForPolicy(policy);
                                          setPolicyBeingWorkedOn(policy);
                                          setPolicyBeingWorkedOnIndex(index);
                                          setAddHealth(true);
                                        }
                                      }}
                                    >
                                      Add Health/E-Card
                                    </Link>
                                  )}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employeeDetailtable">
                                <div
                                  className="employeePolicy_table"
                                  style={{ overflow: "hidden" }}
                                >
                                  <ul className="heading">
                                    <li>Member Type</li>
                                    <li>Member Name</li>
                                    <li>Health/E-Card Number</li>
                                    <li>Health/E-Card Document</li>
                                    <li>Date</li>
                                    <li>Time</li>
                                    <li>Action</li>
                                  </ul>

                                  {policy.ecard.map(
                                    (policyEcard: any, index) => {
                                      return (
                                        <ul className="subheading">
                                          <li>
                                            {policy?.tpa?.integrated
                                              ? selectedMemberType === "Depandent"?"Dependent":selectedMemberType
                                              : memberId?.includes("EMP")?"Employee":"Dependent"}
                                          </li>
                                          <li>
                                            {giveMemberName(policyEcard)
                                              ?.length !== 0
                                              ? giveMemberName(policyEcard)
                                              : empFirstName +
                                              " " +
                                              empLastName}
                                              
                                          </li>
                                          <li>{policyEcard?.ecardNumber}</li>
                                          <li>
                                            <a
                                              target="_blank"
                                              // href={policyEcard.downloadLink}
                                              onClick={() =>
                                                downloadAttachement(
                                                  policyEcard.downloadLink,
                                                  usertypeinfo
                                                )
                                              }

                                              download
                                            >
                                              <InsertDriveFile />
                                            </a>
                                          </li>
                                          <li>
                                            {customFormatDate(
                                              policyEcard.createdAt
                                            )}
                                          </li>
                                          <li>
                                            {customFormatTime(
                                              policyEcard.createdAt
                                            )}
                                          </li>
                                          <li
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            {!policy?.tpa?.integrated &&
                                              selectedMemberType?.toLowerCase() !==
                                              "dependent" &&
                                              selectedMemberType?.toLowerCase() !==
                                              "depandent" && (
                                                <Link
                                                  className="redBtn delete ml-0"
                                                  onClick={() => {
                                                    handleEntityDeassign(
                                                      PolicyDeassignEntityType.ECARD,
                                                      policyEcard._id
                                                    );
                                                    createDropDownForPolicy(
                                                      policy
                                                    );
                                                  }}
                                                  sx={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                  }}
                                                ></Link>
                                              )}
                                          </li>
                                        </ul>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>

                          {/* Document Checklist */}
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h4 className="subHeading">Document Checklist</h4>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employeeDetailtable">
                                <div
                                  className="employeePolicy_table"
                                  style={{ minWidth: "unset" }}
                                >
                                  <ul className="heading">
                                    <li>Title</li>
                                    <li>Description</li>
                                    <li>Action</li>
                                  </ul>
                                  {policy?.tpa_check_list &&
                                    policy?.tpa_check_list?.map((data: any) => (
                                      <ul className="subheading">
                                        <li>{data?.title}</li>
                                        <li>{data?.description}</li>
                                        <li
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          <Link
                                            className="blueBtn view"
                                            onClick={async () => {
                                              let docUrl = data?.attachment?.includes("http") ? data?.attachment : await getFileSignedUrl(data?.attachment, usertypeinfo) || ""
                                              setselectedDocUrl(docUrl);
                                              setDocviewOpenStatus(true);
                                            }}
                                            sx={{
                                              textDecoration: "none",
                                              cursor: "pointer",
                                              marginLeft: "0 !important",
                                            }}
                                          ></Link>
                                          <Link
                                            className="blueBtn download ml-4"
                                            // href={data?.downloadLink}
                                            onClick={() =>
                                              downloadAttachement(
                                                data.downloadLink,
                                                usertypeinfo
                                              )
                                            }
                                            target={"_blank"}
                                            sx={{
                                              textDecoration: "none",
                                              cursor: "pointer",
                                            }}
                                          ></Link>
                                        </li>
                                      </ul>
                                    ))}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          {/* Document Checklist */}
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h4 className="subHeading">Claim Documents</h4>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employeeDetailtable">
                                <div
                                  className="employeePolicy_table"
                                  style={{ minWidth: "unset" }}
                                >
                                  <ul className="heading">
                                    <li>Title</li>
                                    <li>Description</li>
                                    <li>Subtype</li>
                                    <li>Product</li>
                                    <li>Action</li>
                                  </ul>
                                  {policy?.physicalForm &&
                                    policy?.physicalForm?.map((data: any) => (
                                      <ul className="subheading">
                                        <li>{data?.title}</li>
                                        <li>{data?.description}</li>
                                        <li>{data?.subType}</li>
                                        <li>{data?.product}</li>

                                        <li
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          <Link
                                            className="blueBtn view"
                                            onClick={async () => {
                                              let docUrl = data?.attachment?.includes("http") ? data?.attachment : await getFileSignedUrl(data?.attachment, usertypeinfo) || ""
                                              setselectedDocUrl(docUrl);
                                              setDocviewOpenStatus(true);
                                            }}
                                            target={"_blank"}
                                            sx={{
                                              textDecoration: "none",
                                              cursor: "pointer",
                                              marginLeft: "0 !important",
                                            }}
                                          ></Link>
                                          <Link
                                            className="blueBtn download ml-4"
                                            // href={data?.downloadLink}
                                            onClick={() =>
                                              downloadAttachement(
                                                data.downloadLink,
                                                usertypeinfo
                                              )
                                            }
                                            target={"_blank"}
                                            sx={{
                                              textDecoration: "none",
                                              cursor: "pointer",
                                            }}
                                          ></Link>
                                        </li>
                                      </ul>
                                    ))}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </Box>

              {/* assign policy slider */}
              <SlidingPanel
                type={"right"}
                isOpen={viewAssignPolicy}
                size={75}
                panelClassName="sliding-panel"
                backdropClicked={() => {
                  setViewAssignPolicy(false);
                  setDropdownArr([]);
                  setDisableSaveAss(false);
                }}
              >
                <div>
                  <Grid container spacing={3} alignItems="center">
                    <Grid xs={10}>
                      <h4 className="mb-0 mt-3 text-left">Assign Policy</h4>
                    </Grid>
                    <Grid xs={2} textAlign="end" className="mt-2">
                      <GridCloseIcon
                        onClick={() => {
                          setViewAssignPolicy(false);
                          setDropdownArr([]);
                          setDisableSaveAss(false);
                        }}
                      />
                    </Grid>
                    <Grid xs={12} className="">
                      <hr />
                    </Grid>
                  </Grid>
                  <Grid
                    container padding={0}
                    columnSpacing={3}
                    alignItems="center"
                    sx={{ paddingTop: "0" }}
                  >
                    <Grid xs={12}>
                      <div className="scrollable_area smScroll">
                        <Grid container padding={0}>
                          <Grid xs={6}>
                            <div className="addPolicy_info_section mb-2">
                              <p className="addPolicy_info_p">
                                Client: <span>{selectedClient?.label}</span>
                              </p>
                            </div>
                          </Grid>
                          <Grid xs={12} className="mb-3">
                            <h6 className="policy_subheading">
                              Select Policy{" "}
                            </h6>
                          </Grid>
                          <Grid xs={12}>
                            <div className="ppolicy_table">
                              <div
                                className="policy_table_inner"
                                style={{ overflow: "hidden" }}
                              >
                                <ul className="heading">
                                  <li>Choose Policy</li>
                                  <li>Active Policy Number</li>
                                  <li>Product Type</li>
                                  <li>Insurer</li>
                                  <li>Basic configuration</li>
                                  <li>Individual configuration</li>
                                  <li>Family Defination</li>
                                </ul>
                                {assignable_policies
                                  ?.filter((item: any) => {
                                    if (
                                      new Date(item?.policyDetails?.startDate) >
                                      new Date() &&
                                      item?.status === 20
                                    ) {
                                      return false;
                                    } else {
                                      return true;
                                    }
                                  })
                                  ?.map((data: any, index) => {
                                    return (
                                      <ul className="subheading">
                                        <li>
                                          <div className="Checkbox">
                                            <input
                                              checked={selectedPolicies.includes(
                                                data._id
                                              )}
                                              onClick={() => {
                                                if (
                                                  selectedPolicies.includes(
                                                    data._id
                                                  )
                                                ) {
                                                  return toast.warning(
                                                    "To unassign a member from a policy, in the policies tab change the respective member's status to Opt Out"
                                                  );
                                                }
                                              }}
                                              onChange={(e) => {
                                                if (
                                                  new Date(
                                                    data?.policyDetails?.startDate
                                                  ) > new Date() &&
                                                  data?.status === 20
                                                ) {
                                                  return toast.warn(
                                                    "Policy status is Draft, Can't assign this policy right now"
                                                  );
                                                }
                                                if (
                                                  data.employeeStatus === 42 ||
                                                  data.employeeStatus === 61
                                                ) {
                                                  return toast.warn(
                                                    "Since the Employee has been Opt-Out/Opt-Out Requested from this policy, no new member may be added to this Policy."
                                                  );
                                                }
                                                if (
                                                  checkEmployementStatus() &&
                                                  // checkAllowedRelation() &&
                                                  (selectedMemberType?.toLowerCase() ===
                                                    "dependent" ||
                                                    selectedMemberType?.toLowerCase() ===
                                                    "depandent"
                                                    ? checkAllowedRelationForDep(
                                                      data.basicConfig,
                                                      data.individualConfig
                                                    ) &&
                                                    checkMaxAgeForChildren(
                                                      data.maxChildAge,
                                                      selectedMemberDOB,
                                                      relationWithEmployee ??
                                                      ""
                                                    )
                                                    : true) &&
                                                  // checkLocation(data) &&
                                                  checkAgeBand(data)
                                                ) {
                                                  handlePolicyCheck(
                                                    e.target.checked,
                                                    data
                                                  );
                                                }
                                              }}
                                              type="checkbox"
                                              name="check"
                                            />
                                            <label className="employee"></label>
                                          </div>
                                        </li>
                                        <li>{data.policyNumber}</li>
                                        <li>{data.productType}</li>
                                        <li>{data.insurer}</li>
                                        <li>{data.basicConfig}</li>
                                        <li>{data.individualConfig}</li>
                                        <li>{data?.familyDefinition}</li>
                                      </ul>
                                    );
                                  })}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid xs={12} className="mt-3">
                      {selectedMemberType?.toLowerCase() === "depandent" ||
                        selectedMemberType?.toLowerCase() === "dependent" ? (
                        ""
                      ) : (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Grid xs={6}>
                            <div style={{ marginBottom: 10 }}>
                              <h6 className="empl_detail_policy_subheading">
                                Employee Salary
                              </h6>
                              <p>
                                {empSalary
                                  ? formatCurrency(Number(empSalary))
                                  : "-"}
                              </p>
                            </div>
                            <h6 className="empl_detail_policy_subheading">
                              Employee Salary Bracket
                            </h6>
                            <p>{empSalaryBracket ? empSalaryBracket : "-"}</p>
                          </Grid>
                          <Grid xs={6}>
                            <div style={{ marginBottom: 10 }}>
                              <h6 className="empl_detail_policy_subheading">
                                Employee Department
                              </h6>
                              <p>{empDepartment ? empDepartment : "-"}</p>
                            </div>
                            <h6 className="empl_detail_policy_subheading">
                              Employee Designation
                            </h6>
                            <p>{empDesignation ? empDesignation : "-"}</p>
                          </Grid>
                        </div>
                      )}
                      {
                        <Box className="mt-3">
                        <Grid container columnSpacing={3} padding={0}>
                          {selectedMemberType?.toLowerCase() === "employee" &&
                            dropdownArr.map((item, index) => {
                              return (
                                <Grid xs={6}>
                                  <h6 style={{ marginBottom: 8 }}>
                                    {item.policyNumber}
                                  </h6>
                                  <SearchDropdown
                                    class_name="inputField"
                                    title="Select Grade"
                                    value={item.customValue}
                                    attrName={[
                                      setDropdownArr,
                                      item.policyId,
                                      index,
                                    ]}
                                    value_update={updateMasterState}
                                    data={item.options}
                                    error_message="select grade"
                                    warn_status={
                                      dropdownError.flag &&
                                      dropdownError.index === index
                                    }
                                  />
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Box>
                      }
                    </Grid>

                    <Grid xs={12} className="footer_sec">
                      <Button
                        disabled={disableSaveAss}
                        className="save_btn"
                        onClick={saveAssignedPolicies}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </SlidingPanel>

              {/* Nominee add policy popup */}
              <SlidingPanel
                type={"right"}
                isOpen={addNominee}
                size={75}
                panelClassName="sliding-panel"
              >
                <div>
                  <Grid container spacing={3} alignItems="center">
                    <Grid xs={10}>
                      <h4 className="mb-0 mt-3 text-left">
                        Fill Nominee Details
                      </h4>
                    </Grid>
                    <Grid xs={2} textAlign="end" className="mt-2">
                      <GridCloseIcon
                        onClick={() => {
                          setAddNominee(false);
                          setToggle(true);
                          setFullName("");
                          setRelationEmployee("");
                          setNomineeDob("");
                          setSelectedNominee("");
                          setDisableSaveNom(false);
                          setNomineeToAdd({
                            label: "",
                            relation: "",
                            _id: "",
                            dob: "",
                          });
                        }}
                      />
                    </Grid>
                    <Grid xs={12} className="mb-4">
                      <hr />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    sx={{ paddingTop: "0" }}
                  >
                    <Grid xs={12} className="radio_button no-border">
                      <>
                        <RadioGroup row defaultValue="existing">
                          <FormControlLabel
                            value="existing"
                            control={<Radio />}
                            label="Choose from existing nominee"
                            onClick={Clicktoogle}
                          />
                          <FormControlLabel
                            // checked
                            value="new"
                            control={<Radio />}
                            label="Add new nominee"
                            onClick={Clicked}
                          />
                        </RadioGroup>
                      </>
                    </Grid>
                    <Grid xs={12}>
                      <div className="scrollable_area">
                        <Grid container spacing={3}>
                          {toggle ? (
                            <>
                              <Grid xs={6}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Select Nominee"
                                  value={selectedNominee}
                                  attrName={[setSelectedNominee]}
                                  value_update={updateMasterState}
                                  dropdown_data={employeeNominees?.map(
                                    (item: any) => {
                                      return {
                                        key: item?._id,
                                        value: item?.label,
                                      };
                                    }
                                  )}
                                  warn_status={false}
                                  error_messg="Select RelationShip"
                                />
                              </Grid>
                              <Grid xs={6}>
                                <p className="health_ecard">
                                  Relationship with Employee
                                  <span>{nomineeToAdd?.relation}</span>
                                </p>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid xs={6}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Full Name"
                                  value={fullName}
                                  attrName={[setFullName]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  validation_type="name"
                                />
                              </Grid>
                              <Grid xs={6}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Relationship with employee"
                                  value={relationEmployee}
                                  attrName={[setRelationEmployee]}
                                  value_update={updateMasterState}
                                  dropdown_data={relationNomineeEmployee_data}
                                  warn_status={false}
                                />
                              </Grid>
                              <Grid xs={6}>
                                <DatePicker
                                  class_name="inputField"
                                  title="Date of Birth"
                                  value={nomineeDob}
                                  attrName={[setNomineeDob]}
                                  value_update={updateMasterState}
                                  error_message="Please enter Date of Birth"
                                  warn_status={false}
                                  max_date={minAge}
                                />
                              </Grid>
                              <Grid xs={6}>
                                <p className="health_ecard">
                                  Age
                                  <span>{calculateAgeFromDob(nomineeDob)}</span>
                                </p>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </div>
                    </Grid>
                    <Grid xs={12} className="footer_sec">
                      <Button
                        disabled={disableSaveNom}
                        className="save_btn"
                        onClick={handleCreateNomineeInPolicy}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </SlidingPanel>

              {/* add health ecard policy popup */}
              <SlidingPanel
                type={"right"}
                isOpen={addHealth}
                size={75}
                panelClassName="sliding-panel"
              >
                <div>
                  <Grid container spacing={3} alignItems="center">
                    <Grid xs={10}>
                      <h4 className="mb-0 mt-3 text-left">
                        Add Health/E-Card Details
                      </h4>
                    </Grid>
                    <Grid xs={2} textAlign="end" className="mt-2">
                      <GridCloseIcon
                        onClick={() => {
                          setAddHealth(false);
                          setSelectMember("");
                          setHealthEcardNumber("");
                          setDocName("");
                          setEcardDoc("");
                          setDisableSaveCard(false);
                          seteCardError({
                            warnMember: false,
                            warnNumber: false,
                            warnDoc: false,
                          });
                        }}
                      />
                    </Grid>
                    <Grid xs={12} className="mb-4">
                      <hr />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    sx={{ paddingTop: "0" }}
                  >
                    <Grid xs={12}>
                      <div className="scrollable_area">
                        <Grid container spacing={3}>
                          <Grid xs={12}>
                            <p className="health_ecard">
                              Policy Number
                              <span>{policyBeingWorkedOn.policyNumber}</span>
                            </p>
                          </Grid>
                          <Grid xs={6}>
                            <SelectDropdown
                              class_name="inputField"
                              title="Select Member"
                              value={selectMember}
                              attrName={[setSelectMember]}
                              value_update={updateMasterState}
                              dropdown_data={
                                policyBeingWorkedOn?.policySettings
                                  ?.healthCardIssued ===
                                  "Per Individual Member of the Family"
                                  ? dropdowndataDynamic
                                  : [
                                    {
                                      key: employeeId,
                                      label: empFirstName + " " + empLastName,
                                    },
                                  ]
                              }
                              warn_status={eCardError.warnMember}
                            />
                          </Grid>

                          <Grid xs={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Enter Health/E-Card Number"
                              value={healthEcardNumber}
                              attrName={[setHealthEcardNumber]}
                              value_update={updateMasterState}
                              warn_status={eCardError.warnNumber}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={0} className="mt-4">
                          <Grid xs={10} className="inputField uploadFile">
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Upload Document"
                              value={docName}
                              className="textarea"
                              disabled
                            />
                          </Grid>
                          <Grid xs={2}>
                            <Button
                              variant="contained"
                              className="browsebtn"
                              component="label"
                            >
                              Browse{" "}
                              <input
                                hidden
                                accept="application/pdf, image/*"
                                multiple
                                type="file"
                                style={{ zIndex: "99" }}
                                onChange={(e: any) => {
                                  const file = e.target.files[0];
                                  const fileSize = file?.size;
                                  const maxSizeBytes = 8 * 1024 * 1024;
                                  if (fileSize && fileSize > maxSizeBytes) {
                                    toast.error("File size exceeds 8 MB limit");
                                    return (e.target.value = null);
                                  }
                                  const allowedFormats = [
                                    "image/png",
                                    "image/jpeg",
                                    "image/jpg",
                                    "application/pdf",
                                  ];
                                  if (allowedFormats?.includes(file?.type)) {
                                    uploadImage(
                                      file,
                                      (base64Code: any, base64String: any) => {
                                        setDocName(e.target.files[0]?.name);
                                        setEcardDoc(base64Code);
                                      }
                                    );
                                  } else {
                                    toast.error(
                                      "Uploaded format not allowed. Only .png, .jpeg, .jpg & .pdf allowed"
                                    );
                                  }
                                }}
                              />
                            </Button>
                          </Grid>

                          <Grid xs={9}>
                            <div className="uploadAttachmentInfo">
                              <span>Maximum size of attachment is 8 MB*</span>
                            </div>
                          </Grid>
                          <Grid xs={3} textAlign={"right"}>
                            {eCardError.warnDoc === true ? (
                              <span
                                className="textAreaerror"
                                style={{ color: "#eb5757", fontSize: "11px" }}
                              >
                                Upload File
                              </span>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid xs={12} className="footer_sec">
                      <Button
                        disabled={disableSaveCard}
                        className="save_btn"
                        onClick={handleCreateECard}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </SlidingPanel>

              {/*Add member policy popup */}
              <SlidingPanel
                type={"right"}
                isOpen={addMember}
                size={75}
                panelClassName="sliding-panel"
                backdropClicked={() => {
                  setAddMember(false);
                  setToggleExistingOrNew(true);
                  setNewMemberDetails({
                    dob: "",
                    warnDob: false,
                    fullName: "",
                    warnFullName: false,
                    relationWithEmployee: "",
                    warnRelationWithEmployee: false,
                  });
                  setMemberToAdd({ fullName: "", relation: "", _id: "" });
                  setSelectedFamily("");
                  setDisableSaveEmp(false);
                }}
              >
                <div>
                  <Grid container spacing={3} alignItems="center">
                    <Grid xs={10}>
                      <h4 className="mb-0 mt-3 text-left">Add Member</h4>
                    </Grid>
                    <Grid xs={2} textAlign="end" className="mt-2">
                      <GridCloseIcon
                        onClick={() => {
                          setAddMember(false);
                          setToggleExistingOrNew(true);
                          setNewMemberDetails({
                            dob: "",
                            warnDob: false,
                            fullName: "",
                            warnFullName: false,
                            relationWithEmployee: "",
                            warnRelationWithEmployee: false,
                          });
                          setMemberToAdd({
                            fullName: "",
                            relation: "",
                            _id: "",
                          });
                          setSelectedFamily("");
                          setDisableSaveEmp(false);
                        }}
                      />
                    </Grid>
                    <Grid xs={12} className="mb-4">
                      <hr />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    sx={{ paddingTop: "0" }}
                  >
                    <Grid xs={12} className="radio_button no-border">
                      <>
                        <RadioGroup row defaultValue="existing">
                          <FormControlLabel
                            value="existing"
                            control={<Radio />}
                            label="Choose from existing family member"
                            onClick={handleClicktoogle}
                          />
                          <FormControlLabel
                            // checked
                            value="new"
                            control={<Radio />}
                            label="Add new member"
                            onClick={handleClicked}
                          />
                        </RadioGroup>
                      </>
                    </Grid>
                    <Grid xs={12}>
                      <div className="scrollable_area ">
                        <Grid container spacing={3}>
                          {toggleExistingOrNew === true ? (
                            <>
                              <Grid xs={6}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Select Family Member"
                                  value={selectedFamily}
                                  attrName={[setSelectedFamily]}
                                  value_update={updateMasterState}
                                  dropdown_data={employeeMembers
                                    ?.filter(
                                      (item) =>
                                        !alreadyAddedNames?.has(item?.fullName)
                                    ) // Filter out names already in the added members
                                    ?.map((item) => ({
                                      key: item._id,
                                      value: item.fullName,
                                    }))}
                                  warn_status={
                                    newMemberDetails.warnRelationWithEmployee
                                  }
                                />
                              </Grid>
                              <Grid xs={6}>
                                <p className="health_ecard">
                                  Relationship with Employee
                                  <span>{memberToAdd.relation}</span>
                                </p>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid xs={6}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Full Name"
                                  value={newMemberDetails?.fullName}
                                  attrName={[setNewMemberDetails, "fullName"]}
                                  value_update={updateMasterState}
                                  warn_status={newMemberDetails?.warnFullName}
                                />
                              </Grid>
                              <Grid xs={6}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Relationship with employee"
                                  value={newMemberDetails?.relationWithEmployee}
                                  attrName={[
                                    setNewMemberDetails,
                                    "relationWithEmployee",
                                  ]}
                                  value_update={updateMasterState}
                                  dropdown_data={relationEmployee_data}
                                  warn_status={
                                    newMemberDetails?.warnRelationWithEmployee
                                  }
                                />
                              </Grid>
                              <Grid xs={6}>
                                <DatePicker
                                  class_name="inputField"
                                  title="Date of Birth"
                                  value={newMemberDetails?.dob}
                                  max_date={new Date()}
                                  attrName={[setNewMemberDetails, "dob"]}
                                  value_update={updateMasterState}
                                  error_message="Please enter Date of Birth"
                                  warn_status={newMemberDetails?.warnDob}
                                />
                              </Grid>
                              <Grid xs={6}>
                                <p className="health_ecard">
                                  Age
                                  <span>
                                    {calculateAgeFromDob(newMemberDetails.dob)}
                                  </span>
                                </p>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </div>
                    </Grid>
                    <Grid xs={12} className="footer_sec">
                      <Button
                        disabled={disableSaveEmp}
                        className="save_btn"
                        onClick={handleAddMemberToPolicy}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </SlidingPanel>
            </Box>
          </>
        </Grid>
      </Grid>
    </>
  );
}

export default EmployeeDetailPolicies;
