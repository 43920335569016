import { TextareaAutosize } from "@material-ui/core";
import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { allowFileExtention, uploadImage } from "../../../Supporting files/HelpingFunction";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  closeOnSuccess?: boolean;
}

const BulkUploadForm: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  closeOnSuccess,
}) => {
  const [uploadFileStep, setUploadFileStep] = useState(1);
  const [displayData, setDisplayData] = useState<any>({
    doc: "",
    warning: false,
    description: "",
    warnDescription: false,
    doc_name: "",
  });

  const updateMasterState = (
    attrName: any,
    value: string | boolean | number
  ) => {
    if (attrName === "description") {
      setDisplayData({
        ...displayData,
        description: value,
        warnDescription: false,
      });
    }
    // attrName(value);
  };

  const attachment_submission = () => {
   
    let data: any = displayData;
    data.warning = displayData?.doc === "" ? true : false;
    data.warnDescription = displayData?.description ? false : true;
    setDisplayData({ ...data });
    if (data.warning === false && data.warnDescription === false) {
      if (closeOnSuccess) {
        value_update(attrName, false);
      }
      value_update("attachment_new_data", displayData);
      setDisplayData({
        doc: "",
        warning: false,
        description: "",
        warnDescription: false,
        doc_name: "",
        docType:""
      });
      attrName(false); //
    }
  };

  const cross_Icon = () => {
    value_update(attrName, false);
    setDisplayData({
      doc: "",
      warning: false,
      description: "",
      docType:"",
      warnDescription: false,
    });
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="sliding-panel"
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-3 text-left">Upload your Document</h4>
          </Grid>
          <Grid xs={2} textAlign={"right"}>
            <GridCloseIcon onClick={cross_Icon} />
          </Grid>
          <Grid xs={12} className="mb-4">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          {uploadFileStep === 1 ? (
            <div>
              <Grid
                container
                spacing={0}
                justifyContent="center"
                className="mb-5"
              >
                <Grid xs={10} className="inputField uploadFile">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label={
                      displayData.doc_name || "Upload .xl .csv .xls .pdf file"
                    }
                    variant="outlined"
                    className="textarea"
                    value={displayData.doc_name}
                    disabled
                  />
                </Grid>

                <Grid xs={2}>
                  <Button
                    variant="contained"
                    className="browsebtn"
                    component="label"
                  >
                    Browse
                    <input
                      hidden
                      // accept="image/*"
                      multiple
                      type="file"
                      style={{ zIndex: "99" }}
                      onChange={(e: any) => {
                        const file = e.target.files[0];
                        const fileSize = file?.size;
                        const maxSizeBytes = 8 * 1024 * 1024;
                        if (fileSize && fileSize > maxSizeBytes) {
                          toast.warn("File size exceeds 8 MB limit");
                          return (e.target.value = null);
                        }
                        const allowExt = allowFileExtention(file)
                        if(!allowExt){
                          toast.warn("Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file.");
                          return (e.target.value = null);
                        }
                        uploadImage(file, (base64Image: any, base64String: any) => {
                          setDisplayData((prevDisplayData: any) => ({
                            ...prevDisplayData,
                            warning:
                              base64Image === "" ||
                                prevDisplayData.data === ""
                                ? true
                                : false,
                            doc: base64Image,
                            doc_name: e.target.files[0]?.name,
                            docType:file?.type,
                            description: prevDisplayData?.description,
                            warnDescription: false,
                          }));
                        }
                        );
                      }}
                    />
                  </Button>
                </Grid>

                <Grid xs={9}>
                  <div className="uploadAttachmentInfo">
                    <span>Maximum size of attachment is 8 MB*</span>
                  </div>
                </Grid>
                <Grid xs={3} textAlign={"right"}>
                  {displayData.warning === true && (
                    <span
                      className="textAreaerror"
                      style={{ color: "#eb5757", fontSize: "11px" }}
                    >
                      Upload File
                    </span>
                  )}
                </Grid>
              </Grid>
              <Grid xs={12} className="textAreaSection">
                <TextareaAutosize
                  value={displayData.description}
                  onChange={(e) => { updateMasterState("description", e.target.value) }}
                  className="text_area"
                  aria-label="empty textarea"
                  placeholder="Description"
                />
                {displayData.warnDescription && (
                  <span
                    className="textAreaerror"
                    style={{
                      color: "#eb5757",
                      display: "flex",
                      justifyContent: "end",
                      fontSize: "11px",
                    }}
                  >
                    Enter Description
                  </span>
                )}
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12} className="ctaBtn"
                  style={{ textAlign: "center" }} >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={attachment_submission}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <h1></h1>
          )}
        </div>
      </div>
    </SlidingPanel>
  );
};

export default BulkUploadForm;
