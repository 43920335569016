import {
  Box,
  FormControlLabel,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "rsuite";
import { URL_CONSTANTS } from "../../../../../../APIVar/URLConstants";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { premiumType } from "../../../../../../Services/Enum/EPremiumPaymentType";
import { POLICIES_SERVICES } from "../../../../../../Services/Policies/PoliciesServices";
import { TUserData } from "../../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../../Supporting files/CustomHooks";
import {
  add_date_format,
  containsLeapYearFebruary,
  extractNumbersFromString,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  titleCase
} from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import moment from "moment";

function PolicyConfig() {
  const { formatCurrency } = useFormatCurrency();
  const policy_permission = useAppSelector((state) => state.permissionSlice.BROKER?.Policies);
  const userState: TUserData = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [configurationData, setconfigurationData] = useState<any>({});
  const [configurationDataInputs, setconfigurationDataInputs] = useState<any>([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("PlanDetails");
  const [modifiedArrayAgeBand, setModifiedArrayAgeBand] = useState<any>([]);
  const [designations, setDesignations] = useState("");
  const [showhealth, setShowhealth] = useState(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const [showcorporate, setShowcorporate] = useState(false);
  const [planType, setPlanType] = useState(0);
  const [id, setId] = useState("");
  const [gradedDesignation, setGradedDesignation] = useState("Salary");
  const [sumInsuredTwo, setSumInsuredTwo] = useState("₹3,00,000");
  const [indivisualGradeLives, setindivisualGradeLives] = useState(0);
  const [liveGraded, setliveGraded] = useState(0);
  const [liveGradedDesignation, setliveGradedDesignation] = useState(0);
  const PlanDetails = useRef(null);
  const GradeCriterion = useRef(null);
  const PlanSettings = useRef(null);
  const LivesInformation = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const [editPolicyPermission, setEditPolicyPermission] = useState<any>(null);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    setEditPolicyPermission(
      policy_permission?.edit === undefined ? false : policy_permission?.edit
    );
  }, []);
  const updateMasterState = (attrName: any, value: any) => {
    let dataArr: any = [...modifiedArrayAgeBand];
    if (attrName[2] === "flatData") {
      dataArr[attrName[0]].coverageTypeData[attrName[1]].value = value;
      let totalValue = 0;

      dataArr[attrName[0]].coverageTypeData?.forEach((obj: any) => {
        const name = obj.name;
        if (name !== "amount" && name !== "total Live") {
          const value = parseFloat(obj.value) || 0;
          totalValue += value;
        }
      });

      dataArr[attrName[0]].coverageTypeData[
        dataArr[attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
    } else if (attrName[2] === "gradeData") {
      dataArr[liveGraded].data[attrName[0]].coverageTypeData[
        attrName[1]
      ].value = value;
      let total: number = 0;
      let totalValue = 0;

      dataArr[liveGraded].data[attrName[0]].coverageTypeData.forEach(
        (obj: any) => {
          const name = obj.name;
          if (name !== "amount" && name !== "total Live") {
            const value = parseFloat(obj.value) || 0;
            totalValue += value;
          }
        }
      );

      dataArr[liveGraded].data[attrName[0]].coverageTypeData[
        dataArr[liveGraded].data[attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
    }

    setModifiedArrayAgeBand(dataArr);
  };


  console.log("modifiedArrayAgeBand", modifiedArrayAgeBand)

  const get_policy_configuration_tab = (id: string) => {
    const onSuccess = (res: any) => {
      console.log("res", res);
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1000);
      }
      setconfigurationData(res.data.data[0]);
      setToggle(res.data.data[0]?.policy?.nomination);
      setShowhealth(res.data.data[0]?.policy?.healthCard);
      setShowcorporate(res.data.data[0]?.policy?.isCorporateBuffer);
      let arr = [...res.data.data[0].planType?.coverageType];
      arr.push({ name: "test" }, { name: "test2" });
      setconfigurationDataInputs(arr.length === 0 ? [] : arr);
      if (res.data.data[0].planType.name === "FLAT") {
        if (res.data.data[0].livesInfoFlat[0]) {
          let test = res.data.data[0].livesInfoFlat;
          setModifiedArrayAgeBand(test);
        } else {
          let data_arr = res.data.data[0].ageBand.map((data: any) => ({
            ...data,
            coverageTypeData: [
              ...res.data.data[0].planType.coverageType.map(
                (dataCover: any) => ({
                  ...dataCover,
                  value: "",
                })
              ),
              { name: "amount", value: "" },
              { name: "total Live", value: "" },
            ],
          }));
          data_arr?.coverageTypeData?.push(
            { name: "amount", value: "" },
            { name: "total Live", value: "" }
          );
          setModifiedArrayAgeBand(data_arr);
        }
      } else if (res.data.data[0].planType.name === "GRADED") {
        if (res.data.data[0].livesInfoGrade[0]) {
          let test = res.data.data[0].livesInfoGrade;
          setModifiedArrayAgeBand(test);
        } else {
          let updatedArr: any = [];
          for (
            let i = 0;
            i < res.data.data[0].benefitsDetailsGrade.length;
            i++
          ) {
            const data_arr = res.data.data[0].ageBand.map((data: any) => ({
              ...data,
              coverageTypeData: [
                ...res.data.data[0].planType.coverageType.map(
                  (dataCover: any) => ({
                    ...dataCover,
                    value: "",
                  })
                ),
                { name: "amount", value: "" },
                { name: "total Live", value: "" },
              ],
            }));

            data_arr?.coverageTypeData?.push(
              { name: "amount", value: "" },
              { name: "total Live", value: "" }
            );

            updatedArr.push({
              gradeName: `Grade ${i + 1}`,
              data: data_arr,
            });
          }

          // res.data.data[0].benefitsDetailsGrade.map(
          //   (graded: any, index: any) => {}
          // );

          setModifiedArrayAgeBand(updatedArr);
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.get_policy_detail_view(
      `${URL_CONSTANTS.get_policy_detail_view}${id}&tab=CONFIGURATION`,
      onSuccess,
      onError
    );
  };

  const update_live_information_count = () => {
    if (!editPolicyPermission) return toast.error("Edit permission not allowed");
    const onSuccess = (res: any) => {
      toast.success("Data Updated Successfully");
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    POLICIES_SERVICES.update_live_count(
      { livesData: modifiedArrayAgeBand },
      onSuccess,
      onError,
      id
    );
  };
  console.log("configurationData", configurationData)
  const updateMasterStateLives = (attrName: any, value: any) => {
    let dataArr: any = [...modifiedArrayAgeBand];
    let proRataUnit = configurationData?.policy.proreta_by;

    if (!configurationData?.policyDetails?.startDate) {
      return toast.warn("Please enter policy start date")
    }
    let yearDays = 365;
    if (containsLeapYearFebruary(new Date(configurationData?.policyDetails?.startDate), 365)) {
      yearDays = 366;
    }

    let unit =
      proRataUnit === "YEARLY"
        ? yearDays
        : proRataUnit === "MONTHLY"
          ? 30
          : proRataUnit === "WEEKLY"
            ? 7
            : 1;
    
    if (attrName[2] === "flatData") {
      dataArr[attrName[0]].coverageTypeData[attrName[1]].value = value;
      dataArr[attrName[0]].coverageTypeData[attrName[1] + 1].value = value / unit;
      let totalValue = 0;
      dataArr[attrName[0]].coverageTypeData?.forEach((obj: any) => {
        const name = obj.name;
        if (name !== "amount" && name !== "total Live" && name !== "perDay") {
          const value = parseFloat(obj.value) || 0;
          totalValue += value;
        }
      });
      dataArr[attrName[0]].coverageTypeData[dataArr[attrName[0]].coverageTypeData.length - 1].value = totalValue;
    } else if (attrName[2] === "gradeData") {
      dataArr[liveGraded].data[attrName[0]].coverageTypeData[attrName[1]].value = value;
      dataArr[liveGraded].data[attrName[0]].coverageTypeData[attrName[1] + 1].value = value / unit;
      let totalValue = 0;

      dataArr[liveGraded].data[attrName[0]].coverageTypeData.forEach((obj: any) => {
        const name = obj.name;
        if (name !== "amount" && name !== "total Live" && name !== "perDay") {
          const value = parseFloat(obj.value) || 0;
          totalValue += value;
        }
      });

      dataArr[liveGraded].data[attrName[0]].coverageTypeData[
        dataArr[liveGraded].data[attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
    }
    setModifiedArrayAgeBand(dataArr);
  };

  const indivisualgradedlives = () => {
    let totalValue = 0;

    switch (configurationData?.planType?.name) {
      case "GRADED":
        modifiedArrayAgeBand?.forEach((array: any) => {
          array?.data?.forEach((data: any) => {
            data.coverageTypeData.forEach((coverageData: any) => {
              if (coverageData.name === "total Live") {
                totalValue += parseInt(coverageData.value);
              }
            });
          });
        });
        break;

      case "FLAT":
        modifiedArrayAgeBand?.forEach((array: any) => {
          array.coverageTypeData.forEach((coverageData: any) => {
            if (coverageData.name === "total Live") {
              totalValue += parseInt(coverageData.value);
            }
          });
        });
        break;

      default:
        break;
    }

    setindivisualGradeLives(totalValue);
  };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    get_policy_configuration_tab(id ?? "");
    setShowLoader(true);
  }, []);

  useEffect(() => {
    indivisualgradedlives();
  }, [modifiedArrayAgeBand]);

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={3}>
          <Grid
            xs
            style={{
              maxWidth: "260px",
              position: "relative",
              top: "-75px",
              paddingLeft: "0",
            }}
          >
            <div
              className="leftSection h-100"
              style={{ position: "fixed", paddingLeft: "8px" }}
            >
              <Link
                className={
                  sectionStatus === "PlanDetails"
                    ? "active sectionLink PlanDetails"
                    : "sectionLink PlanDetails"
                }
                onClick={() => {
                  scrollToSection(PlanDetails);
                  SetSectionStatus("PlanDetails");
                }}
              >
                <span> Plan Details</span>
              </Link>
              {configurationData?.planType?.name === "FLAT" ||
                configurationData?.planType?.gradeCriteria === "Custom" ? (
                ""
              ) : (
                <Link
                  className={
                    sectionStatus === "GradeCriterion"
                      ? "active sectionLink GradeCriterion"
                      : "sectionLink GradeCriterion"
                  }
                  onClick={() => {
                    scrollToSection(GradeCriterion);
                    SetSectionStatus("GradeCriterion");
                  }}
                >
                  <span>Grade Criterion</span>
                </Link>
              )}
              <Link
                className={
                  sectionStatus === "PlanSettings"
                    ? "active sectionLink PlanSettings"
                    : "sectionLink PlanSettings"
                }
                onClick={() => {
                  scrollToSection(PlanSettings);
                  SetSectionStatus("PlanSettings");
                }}
              >
                <span>Plan Settings</span>
              </Link>
              <Link
                className={
                  sectionStatus === "LivesInformation"
                    ? "active sectionLink LivesInformation"
                    : "sectionLink LivesInformation"
                }
                onClick={() => {
                  scrollToSection(LivesInformation);
                  SetSectionStatus("LivesInformation");
                }}
              >
                <span>Lives Information</span>
              </Link>
            </div>
          </Grid>
          <Grid xs>
            <>
              <Box className="detailSection" ref={PlanDetails}>
                <div className="sectionTitle">
                  <h4>Plan Details</h4>
                </div>
                <Grid container spacing={3} className="premium_payment_section">
                  <Grid xs={4}>
                    <>
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <h5 className="plan_type mb-0">Plan Type</h5>
                        </Grid>
                        <Grid xs={12}>
                          <Box className="plantTypeView">
                            {configurationData?.planType?.name === "FLAT" ? (
                              <div className="select_plan_inner">
                                <h5>Flat Coverage</h5>
                                <p>
                                  Common average amount for all employees in the
                                  policy.
                                </p>
                              </div>
                            ) : configurationData?.planType?.name ===
                              "GRADED" ? (
                              <div className="select_plan_inner">
                                <h5>Graded Coverage</h5>
                                <p>
                                  Different average amount for different
                                  employees in the policy.
                                </p>
                              </div>
                            ) : configurationData?.planType?.name ===
                              "FLEXI" ? (
                              <div className="select_plan_inner">
                                <h5>Flexi Coverage</h5>
                                <p>
                                  Different average amount for different
                                  employees in the policy.
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                  <Grid xs={8}>
                    <Box className="coverageDetail">
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <h5 className="empsecHeading">Coverage Type</h5>
                        </Grid>
                        <Grid xs={12}>
                          <Box className="coverageTypeView">
                            <Grid container spacing={3}>
                              <Grid xs={12} className="px-0">
                                <ul className="coverage_type_section ">
                                  {configurationData?.planType?.coverageType?.map(
                                    (data: any) => (
                                      <li>
                                        <div
                                          className={`coverage_box ${data.name.toLowerCase()}`}
                                        >
                                          <p>{titleCase(data.name)}</p>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid xs={12} className="px-0">
                    {/* Coverage Section */}

                    <Box className="coverageDetail">
                      <Grid container spacing={3}>
                        <Grid xs={12}>
                          <h5 className="subHeading">Coverage Configuration</h5>
                        </Grid>
                        <Grid xs={12}>
                          <Box>
                            <Grid container spacing={3}>
                              <Grid sm={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Basic Configuration"
                                  value={
                                    configurationData?.planType?.basicConfig
                                  }
                                  attrName="disabled"
                                  value_update={updateMasterState}
                                  warn_status={false}
                                />
                              </Grid>

                              <Grid sm={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Indivisual Configuration"
                                  value={
                                    configurationData?.planType?.parentConfig
                                  }
                                  attrName="disabled"
                                  value_update={updateMasterState}
                                  warn_status={false}
                                />
                              </Grid>
                              {configurationData.planType.coverageType.some(
                                (data: any) => data.name === "KIDS"
                              ) && (
                                  <Grid sm={3}>
                                    <RKTextField
                                      class_name="inputField"
                                      title="Maximum Age for Children"
                                      value={configurationData?.maxChildAge}
                                      attrName="disabled"
                                      value_update={updateMasterState}
                                      warn_status={false}
                                    />
                                  </Grid>
                                )}
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid xs={12}>
                          <Box>
                            <Grid container spacing={3}>
                              <Grid sm={6}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Family Definition"
                                  value={
                                    configurationData?.planType
                                      ?.familyDefinition
                                  }
                                  attrName="disabled"
                                  value_update={updateMasterState}
                                  warn_status={false}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        {/* <Grid sm={6} md={4} lg={3} xl={1.5}>
                          <RKTextField
                            class_name="inputField"
                            title="Basic Configuration"
                            value={configurationData?.planType?.basicConfig}
                            attrName="disabled"
                            value_update={updateMasterState}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid sm={6} md={4} lg={3} xl={1.5}>
                          <RKTextField
                            class_name="inputField"
                            title="Indivisual Configuration"
                            value={configurationData?.planType?.parentConfig}
                            attrName="disabled"
                            value_update={updateMasterState}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid sm={6} md={4} lg={3} xl={1.5}>
                          <RKTextField
                            class_name="inputField"
                            title="Family Definition"
                            value={
                              configurationData?.planType?.familyDefinition
                            }
                            attrName="disabled"
                            value_update={updateMasterState}
                            warn_status={false}
                          />
                        </Grid>
                        {configurationData.planType.coverageType.some(
                          (data: any) => data.name === "KIDS"
                        ) && (
                          <Grid sm={6} md={4} lg={3} xl={1.5}>
                            <RKTextField
                              class_name="inputField"
                              title="Maximum Age for Children"
                              value={configurationData?.maxChildAge}
                              attrName="disabled"
                              value_update={updateMasterState}
                              warn_status={false}
                            />
                          </Grid>
                        )} */}
                      </Grid>
                      {configurationData?.planType?.name === "FLAT" ? (
                        ""
                      ) : (
                        <Grid container spacing={3}>
                          <Grid xs={12}>
                            <h5 className="subHeading">
                              Criterion for Grade Creation
                            </h5>
                          </Grid>
                          <Grid sm={6} md={4} lg={3} xl={3}>
                            <RKTextField
                              class_name="inputField"
                              title="Select Criteria"
                              value={configurationData?.planType?.gradeCriteria}
                              attrName={setGradedDesignation}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </Grid>

                  {configurationData.planType.parentConfig.includes("1P+") &&
                    configurationData.planType.parentConfig.includes(
                      "1PIL"
                    ) && (
                      <Grid xs={12} className="payment_radio_group pl-5">
                        <>
                          <RadioGroup row defaultValue="both">
                            <FormControlLabel
                              checked={
                                configurationData.planType.setOfParents ===
                                "both"
                              }
                              value="both"
                              control={<Radio />}
                              label="Include both the set of Parents and Parents in law"
                            />

                            <FormControlLabel
                              checked={
                                configurationData.planType.setOfParents ===
                                "single"
                              }
                              value="single"
                              control={<Radio />}
                              label="Include only a set of Parents or Parents in law"
                            />
                          </RadioGroup>
                        </>
                      </Grid>
                    )}
                </Grid>
              </Box>
              {configurationData?.planType?.name === "FLAT" ||
                configurationData?.planType?.gradeCriteria === "Custom" ? (
                ""
              ) : (
                <Box className="detailSection">
                  <div className="sectionTitle">
                    <h4>Information on Grade Criterion</h4>
                    <div className="total_lives"></div>
                  </div>
                  <div className="policy_lives">
                    <Grid container spacing={3}>
                      {planType === 0 ? (
                        <Grid sm={12} md={12} lg={9}>
                          <ul className="coverageAmt">
                            {configurationData?.benefitsDetailsGrade?.map(
                              (data: any, mainIndex: any) => (
                                <li key={mainIndex}>
                                  <div className="radioBox ">
                                    <input
                                      type="radio"
                                      name="coveragedesignation"
                                      onClick={() =>
                                        setliveGradedDesignation(mainIndex)
                                      }
                                      checked={
                                        liveGradedDesignation === mainIndex
                                          ? true
                                          : false
                                      }
                                    />
                                    <label>Grade {mainIndex + 1}</label>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </Grid>
                      ) : null}
                      <Grid sm={12} md={4} lg={3}>
                        <RKTextField
                          class_name="inputField"
                          title={
                            configurationData?.productType?.name ===
                              "Group Term"
                              ? "Sum Assured"
                              : "Sum Insured"
                          }
                          value={
                            configurationData?.benefitsDetailsGrade &&
                            formatCurrencyAccToUser(
                              Number(
                                extractNumbersFromString(
                                  configurationData?.benefitsDetailsGrade[
                                    liveGradedDesignation
                                  ]?.sumInsured
                                )
                              ),
                              getCurrencyCodeRegex(
                                userState.localInformation.currency
                              ),
                              userState.localInformation.countryCurrencyCode,
                              0
                            )
                          }
                          attrName={setSumInsuredTwo}
                          value_update={updateMasterState}
                          warn_status={false}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid xs={12} className="px-0">
                        <RKTextField
                          class_name="inputField"
                          title={configurationData?.planType?.gradeCriteria}
                          value={
                            configurationData?.benefitsDetailsGrade &&
                            configurationData?.benefitsDetailsGrade[
                              liveGradedDesignation
                            ]?.gradeDivisions?.map(
                              (data: any) => `  ${data.label}`
                            )
                          }
                          attrName={setDesignations}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              )}

              <Box>
                {/* payment configuration */}
                <Grid container spacing={3} className="px-0">
                  <Grid xs={12}>
                    <Box className="detailSection mb-0">
                      <div className="sectionTitle">
                        <h4>Premium Payment Configuration</h4>
                      </div>
                      <div className="premium_payment_section">
                        <Grid container spacing={3}>
                          <Grid xs={12}>
                            <h5 className="subHeading">Premium Payment Type</h5>
                          </Grid>
                          <Grid xs={12} className="payment_radio_group">
                            <>
                              <RadioGroup row defaultValue="new">
                                {configurationData.payType?.type ===
                                  premiumType.STANDARD && (
                                    <FormControlLabel
                                      checked={
                                        configurationData.payType?.type ===
                                        premiumType.STANDARD
                                      }
                                      disabled
                                      value="existing"
                                      control={<Radio />}
                                      label="Standard Premium"
                                    />
                                  )}

                                {configurationData.payType?.type ===
                                  premiumType.SPLIT && (
                                    <FormControlLabel
                                      checked={
                                        configurationData.payType?.type ===
                                        premiumType.SPLIT
                                      }
                                      disabled
                                      value="new"
                                      control={<Radio />}
                                      label="Split Premium"
                                    />
                                  )}
                              </RadioGroup>
                            </>
                          </Grid>
                        </Grid>
                        {configurationData?.payType?.type ==
                          premiumType.SPLIT ? (
                          <Grid container spacing={3}>
                            {configurationData?.payType?.data.map(
                              (pType: any, index: any) => {
                                return (
                                  <Grid xs xl={1}>
                                    <div>
                                      <RKTextField
                                        disabled
                                        class_name="inputField"
                                        title={pType.name}
                                        value={
                                          configurationData.payType?.data.find(
                                            (el: any) => el.name === pType.name
                                          )?.value
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "payType",
                                          "data",
                                          pType.name,
                                        ]}
                                        value_update={() => { }}
                                        warn_status={false}
                                        inputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                );
                              }
                            )}
                            <Grid xs={12} className="pt-0">
                              <h6>
                                Define % of the premium value to be paid by the
                                employee for the respective associated
                                relationship
                              </h6>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid xs={12} className="pt-0">
                            <h6>
                              100 % of the premium value will be paid by the
                              employer
                            </h6>
                          </Grid>
                        )}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box className="detailSection mt-4" ref={PlanSettings}>
                <div className="sectionTitle">
                  <h4>Plan Settings</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Critical CD Balance Amount"
                      //value={configurationData?.policy?.criticalCd}
                      value={formatCurrencyAccToUser(
                        configurationData?.policy?.criticalCd,
                        getCurrencyCodeRegex(
                          userState.localInformation.currency
                        ),
                        userState.localInformation.countryCurrencyCode,
                        0
                      )}
                      attrName="disabled"
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Critical CD Balance %"
                      attrName="disabled"
                      value={configurationData?.policy?.criticalPercentage}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Enrolment Start Date"
                      attrName="disabled"
                      value={add_date_format(
                        configurationData?.policy?.enrolmentStartDate
                      )}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Enrolment End Date"
                      attrName="disabled"
                      value={add_date_format(
                        configurationData?.policy?.enrolmentEndDate
                      )}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Enrolment Period"
                      attrName="disabled"
                      value={configurationData?.policy?.period}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Grace Period"
                      attrName="disabled"
                      value={configurationData?.policy?.gracePeriod}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Maximum Members per Family"
                      attrName="disabled"
                      value={configurationData?.policy?.maxMemberPerFamily}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Total Members per Family"
                      attrName="disabled"
                      value={configurationData?.policy?.totalMemberPerFamily}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="New Employee Enrolment Period"
                      attrName="disabled"
                      value={configurationData?.policy?.newMemberPeriod}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid
                    xs={4}
                    className="switchFields"
                    display={"flex"}
                    alignItems="center"
                    sx={{ height: "70px" }}
                  >
                    <span>Nomination</span>
                    {toggle === true ? (
                      <input
                        type="checkbox"
                        className="switch"
                        checked
                        disabled
                      />
                    ) : (
                      <input type="checkbox" className="switch" disabled />
                    )}

                    {toggle === true ? (
                      <div className="ml-2" style={{ width: "300px" }}>
                        <RKTextField
                          class_name="inputField"
                          title="Maximum No. of Nominee"
                          value={configurationData?.policy?.maxNomination}
                          attrName="disabled"
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </div>
                    ) : null}
                  </Grid>
                  <Grid
                    xs={4}
                    className="switchFields"
                    display={"flex"}
                    alignItems="center"
                    sx={{ height: "70px" }}
                  >
                    <span>Health Card</span>
                    {showhealth === true ? (
                      <input
                        type="checkbox"
                        className="switch"
                        disabled
                        checked
                      />
                    ) : (
                      <input type="checkbox" className="switch" disabled />
                    )}

                    {showhealth === true ? (
                      <div className="ml-2" style={{ width: "300px" }}>
                        <RKTextField
                          class_name="inputField"
                          title="Card Type"
                          value={configurationData?.policy?.cardType}
                          attrName="disabled"
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </div>
                    ) : null}
                  </Grid>
                  <Grid xs={4}>
                    {showhealth === true ? (
                      <RKTextField
                        class_name="inputField"
                        title="Health Card Issued"
                        value={configurationData?.policy?.healthCardIssued}
                        attrName="disabled"
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title="Pro- Rata Premium Pricing By"
                      attrName="disabled"
                      value={configurationData?.policy?.proreta_by}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid xs={12} className="px-0">
                    <>
                      <Grid container spacing={3}>
                        <Grid
                          xs={4}
                          className="switchFields"
                          display={"flex"}
                          alignItems="center"
                          sx={{ height: "70px" }}
                        >
                          {/* <Grid xs={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Pro- Rata Premium Pricing By"
                              attrName="disabled"
                              value={configurationData?.policy?.proreta_by}
                              value_update={updateMasterState}
                              warn_status={false}
                            />
                          </Grid> */}
                          {/* <span>Corporate Buffer</span>
                          {showcorporate === true ? (
                            <input
                              type="checkbox"
                              className="switch"
                              disabled
                              checked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              className="switch"
                              disabled
                            />
                          )}

                          {showcorporate === true ? (
                            <div className="ml-2" style={{ width: "250px" }}>
                              <RKTextField
                                class_name="inputField"
                                title="Corporate Buffer Value"
                                value={formatCurrency(
                                  Number(
                                    configurationData?.policy?.corporateBuffer
                                  )
                                )}
                                attrName="disabled"
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </div>
                          ) : null} */}

                          {/* <Grid xs={4}>
                        <RKTextField
                          class_name="inputField"
                          title="Pro- Rata Premium Pricing By"
                          attrName="disabled"
                          value={configurationData?.policy?.proreta_by}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid> */}
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </Box>
              <Box className="detailSection" ref={LivesInformation}>
                <div className="sectionTitle">
                  <h4>Relationship-Lives Information</h4>
                  <div className="total_lives">
                    <p>
                      {indivisualGradeLives ? indivisualGradeLives : 0}{" "}
                      <span>Total Lives</span>
                    </p>
                  </div>
                </div>

                <div className="policy_lives">
                  <Grid container spacing={3}>
                    {configurationData?.planType?.name === "FLAT" ? (
                      <>
                        <Grid sm={12} md={6} lg={3}>
                          <RKTextField
                            class_name="inputField"
                            title={
                              configurationData.productType.name ===
                                "Group Super TopUp"
                                ? "Super Top-Up Sum Insured"
                                : configurationData?.productType?.name ===
                                  "Group Term"
                                  ? "Sum Assured"
                                  : "Sum Insured"
                            }
                            value={
                              configurationData?.benefitsDetailsflat
                                ?.sumInsured &&
                              formatCurrencyAccToUser(
                                Number(
                                  extractNumbersFromString(
                                    configurationData?.benefitsDetailsflat
                                      ?.sumInsured
                                  )
                                ),
                                getCurrencyCodeRegex(
                                  userState.localInformation.currency
                                ),
                                userState.localInformation.countryCurrencyCode,
                                0
                              )
                            }
                            attrName={""}
                            value_update={updateMasterState}
                            warn_status={false}
                            disabled={true}
                          />
                        </Grid>
                        <Grid sm={12} md={6} lg={3}>
                          {configurationData?.productType?.name ===
                            "Group Super TopUp" ? (
                            <RKTextField
                              class_name="inputField"
                              title="Deductible"
                              value={formatCurrency(
                                Number(
                                  configurationData?.benefitsDetailsflat
                                    ?.deductible
                                )
                              )}
                              attrName={""}
                              value_update={updateMasterState}
                              warn_status={false}
                              disabled={true}
                            />
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid xs={12}>
                          <div className="table">
                            <div className="livesinformation-table">
                              <ul className="heading">
                                <li>Age Band (in years)</li>
                                <li>
                                  {" "}
                                  Premium Pricing for Addition/Deletion per
                                  Member{" "}
                                </li>
                                <li>Premium Pricing of Per Day</li>
                                {configurationData?.planType?.coverageType?.map(
                                  (data: any) => (
                                    <li>{titleCase(data.name)}</li>
                                  )
                                )}
                                <li>Total Lives Count/Age Band</li>
                              </ul>

                              {modifiedArrayAgeBand?.map(
                                (data: any, ageindex: any) => (
                                  <ul className="subheading">
                                    <li>
                                      {data.min}
                                      {" - "}
                                      {data.max}
                                    </li>
                                    {data.coverageTypeData?.map(
                                      (dataArr: any, index: number) => (
                                        <li>
                                          <RKTextField
                                            validation_type="numeric"
                                            class_name="inputField"
                                            title={
                                              index === 0 || index === 1
                                                ? "Amount"
                                                : "Lives"
                                            }
                                            value={
                                              dataArr.name === "amount"
                                                ? formatCurrency(
                                                  Number(dataArr.value)
                                                )
                                                : dataArr.name === "perDay"
                                                  ? formatCurrency(
                                                    Number(dataArr.value),
                                                    2
                                                  )
                                                  : dataArr.value
                                            }
                                            attrName={[
                                              ageindex,
                                              index,
                                              "flatData",
                                            ]}
                                            value_update={
                                              dataArr.name === "total Live"
                                                ? () => { }
                                                : updateMasterStateLives
                                            }
                                            warn_status={false}
                                            charcterlimit={
                                              dataArr.name === "amount"
                                                ? 12
                                                : dataArr.name === "total Live"
                                                  ? 10
                                                  : 5
                                            }
                                            // disabled={
                                            //   dataArr.name === "total Live"
                                            //     ? true
                                            //     : false
                                            // }
                                            disabled={
                                              index === 0 ? false : true
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid xs={12} sx={{ textAlign: "center" }}>
                          <Button
                            style={{ color: "#fff", background: "#8a83e3" }}
                            onClick={update_live_information_count}
                          >
                            Update
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid sm={12} md={12} lg={12}>
                        <Grid container spacing={3}>
                          <Grid xs={8}>
                            <ul className="coverageAmt mb-4">
                              {modifiedArrayAgeBand.map(
                                (data: any, mainIndex: any) => (
                                  <li key={mainIndex}>
                                    <div className="radioBox ">
                                      <input
                                        type="radio"
                                        name="coveragesd"
                                        onClick={() => setliveGraded(mainIndex)}
                                        checked={
                                          liveGraded === mainIndex
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>Grade {mainIndex + 1}</label>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </Grid>

                          <Grid xs={4}>
                            <Grid container spacing={3}>
                              <Grid xs={6}>
                                <RKTextField
                                  class_name="inputField"
                                  title={
                                    configurationData?.productType?.name ===
                                      "Group Super TopUp"
                                      ? "Super Top-Up Sum Insured"
                                      : configurationData?.productType?.name ===
                                        "Group Term"
                                        ? "Sum Assured"
                                        : "Sum Insured"
                                  }
                                  value={
                                    configurationData?.benefitsDetailsGrade
                                      ? formatCurrencyAccToUser(
                                        Number(
                                          configurationData
                                            ?.benefitsDetailsGrade[liveGraded]
                                            .sumInsured
                                        ),
                                        getCurrencyCodeRegex(
                                          userState.localInformation.currency
                                        ),
                                        userState.localInformation
                                          .countryCurrencyCode,
                                        0
                                      )
                                      : ""
                                  }
                                  attrName={""}
                                  value_update={""}
                                  warn_status={false}
                                  disabled={true}
                                />
                              </Grid>
                              {configurationData?.productType?.name ===
                                "Group Super TopUp" ? (
                                <Grid xs={6}>
                                  <RKTextField
                                    class_name="inputField"
                                    title="Deductible"
                                    value={
                                      configurationData?.benefitsDetailsGrade
                                        ? formatCurrency(
                                          Number(
                                            configurationData
                                              ?.benefitsDetailsGrade[
                                              liveGraded
                                            ].deductible
                                          )
                                        )
                                        : ""
                                    }
                                    attrName={""}
                                    value_update={""}
                                    warn_status={false}
                                    disabled={true}
                                  />
                                </Grid>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                          <Grid xs={12}>
                            <div className="table">
                              <div className="livesinformation-table">
                                <ul className="heading">
                                  <li>Age Band (in years)</li>
                                  <li>
                                    Premium Pricing for Addition/Deletion per
                                    Member
                                  </li>
                                  <li>Premium Pricing of Per Day</li>
                                  {configurationData?.planType?.coverageType?.map(
                                    (data: any) => (
                                      <li>{titleCase(data.name)}</li>
                                    )
                                  )}

                                  <li>Total Lives Count/Age Band</li>
                                </ul>

                                {Array.isArray(modifiedArrayAgeBand) &&
                                  modifiedArrayAgeBand[liveGraded]?.data?.map(
                                    (data: any, ageindex: any) => (
                                      <ul key={ageindex} className="subheading">
                                        <li>
                                          {data.min}-{data.max}
                                        </li>
                                        {data?.coverageTypeData?.map(
                                          (dataArr: any, index: number) => (
                                            <li key={`${ageindex}+ ${index}`}>
                                              <RKTextField
                                                validation_type="numeric"
                                                class_name="inputField"
                                                title={
                                                  index === 0 || index === 1
                                                    ? "Amount" : "Lives"
                                                }
                                                value={
                                                  dataArr.name === "amount"
                                                    ? formatCurrency(Number(dataArr.value))
                                                    : dataArr.name === "perDay"
                                                      ? formatCurrency(Number(dataArr.value), 2
                                                      ) : dataArr.value === null ? "" : dataArr.value
                                                }
                                                attrName={[
                                                  ageindex,
                                                  index,
                                                  "gradeData",
                                                ]}
                                                value_update={
                                                  dataArr?.name === "total Live"
                                                    ? () => { }
                                                    : updateMasterStateLives//updateMasterState
                                                }
                                                warn_status={false}
                                                charcterlimit={
                                                  dataArr?.name === "amount"
                                                    ? 12
                                                    : dataArr?.name ===
                                                      "total Live"
                                                      ? 10
                                                      : 5
                                                }
                                                disabled={
                                                  index === 0 ? false : true
                                                }
                                              />
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )
                                  )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid xs={12} sx={{ textAlign: "center" }}>
                          <Button
                            style={{ color: "#fff", background: "#8a83e3" }}
                            onClick={update_live_information_count}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Box>
            </>
          </Grid>
        </Grid>
      )}{" "}
    </>
  );
}

export default PolicyConfig;
