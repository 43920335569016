//used
import Renewed from "@mui/icons-material/Autorenew";
import Inforced from "@mui/icons-material/Beenhere";
import Draft from "@mui/icons-material/BookmarkAdd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Rejected from "@mui/icons-material/Cancel";
import Cancelled from "@mui/icons-material/CancelPresentation";
import {
  default as Active,
  default as CheckCircleIcon,
} from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Void from "@mui/icons-material/DoDisturbOff";
import Expired from "@mui/icons-material/GppBad";
import {
  default as HighlightOffIcon,
  default as Inactive,
} from "@mui/icons-material/HighlightOff";
import New from "@mui/icons-material/NewReleases";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Published from "@mui/icons-material/PublishedWithChanges";
import Renewal from "@mui/icons-material/Sync";
import Grace from "@mui/icons-material/TrendingUp";
import { Box, Button, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Attachment from "../../../../../Components/Broker/Attachment/Attachment";
import LocationTableView from "../../../../../Components/Broker/ProductConfigurator/LocationTableView/LocationTableView";
import SalaryBracketInputs from "../../../../../Components/Broker/SalaryBracketInput/SalaryBracketInput";
import UploadAttachmentClientDetails from "../../../../../Components/Common/UploadAttachmentClientDetails/UploadAttachmentClientDetails";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { CLIENT_SERVICES } from "../../../../../Services/Clients/ClientService";
import { clientsFormDTO } from "../../../../../Services/DTO/ClientDTO";
import { contactsFormDTO } from "../../../../../Services/DTO/ContactsDTO";
import { E_BE_FILTER_CODE } from "../../../../../Services/Enum/E_UGTable";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextField from "../../../../../Supporting files/Currency/CurrencyTextField";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  date_time_format,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  get_city_list_via_name,
  get_country_list,
  get_industry_list,
  get_state_list,
  get_subindustry_list,
  uploadImage,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SearchDropdownDisable from "../../../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import SelectDropdownDisable from "../../../../../Supporting files/SelectDropdownDisable/SelectDropdownDisable";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import ARMultiInputFieldDisable from "../../../../../Supporting files/UGEmailInput/ARMultiInputFieldDisable";
import UGEmailInputDisable from "../../../../../Supporting files/UGEmailInput/UGEmailInputDisable";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import "../../../../../formLayout.scss";
import LocationPopup from "./ClientDetailsPopup/LocationPopup";
import Contacts from "./Components/Contacts";
import Subsidiary from "./Components/Subsidiary";
import { useDebouncedApiCall } from "../../../../../Supporting files/CustomHooks";

interface TStatusCode {
  key: string;
  value: string;
  color: string;
}

function ClientDetails() {
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const { dateFormat, timeZone } = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation
  );
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [selectedState, setSelectedState] = useState({});
  const [selectedCountry, setSelectedcountry] = useState({});
  const [productTabsStatus, setProductTabsStatus] = useState("overview");
  const [locationPopup, setLocationPopup] = useState(false);
  const [parentOrg, setParentOrg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [policyData, setPolicyData] = useState<any>([]);
  const [clientstatus, setclientstatus] = useState<{
    name: string;
    color: string;
  }>({ name: "", color: "" });
  const [isClientAssigned, setIsClientAssigned] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [stateDataSubsidairy, setStateDataSubsidairy] = useState(false);
  const [addClientLogo, setAddClientLogo] = useState<any[]>([]);
  const [sidepanelLayout, setsidepanelLayout] = useState<clientsFormDTO[]>([]);
  const [priorityStatus, setPriorityStatus] = useState(17);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [displayLogo, setDisplayLogo] = useState<{
    logo: string;
    warning: boolean;
  }>({ logo: "", warning: false });
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [notesData, setNotesData] = useState<
    Array<{ name: string; date: Date; description: string }>
  >([]);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [statusChange, setstatusChange] = useState(false);
  const [clientaddnewlayout, setclientaddnewlayout] = useState<
    clientsFormDTO[]
  >([]);
  const [clientLocation, setclientLocation] = useState<clientsFormDTO[]>([]);
  const [addClass, setAddClass] = useState<boolean>(false);
  const [IndustryData, setIndustryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [subIndustryData, setSubIndustryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [statusCode, setstatusCode] = useState<Array<TStatusCode>>([]);
  const [spocdata, setspocdata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [attachmentData, setAttachmentData] = useState<
    Array<{
      doc: string;
      description: string;
      date: Date;
      time: string;
      doc_name?: string;
    }>
  >([]);
  const [individualEditKey, setIndividualEditKey] = useState<{
    key: string;
    value: any;
  }>({ key: "", value: "" });
  const [firstNameChar, setFirstNameChar] = useState("");
  const [ClientIds, setClientId] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [ClientPrefix, setClientPrefix] = useState("");
  const [countryId, setcountryId] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [featureEnable, setfeatureEnable] = useState<{
    enableEmployeePortal: any;
    enableEmployerPortal: any;
  }>({ enableEmployeePortal: false, enableEmployerPortal: false });
  const [sectionStatus, SetSectionStatus] = useState<any>(
    "personalDetailsSection"
  );
  const [subsectionStatus, SetsubSectionStatus] = useState<any>("Basic Info");
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [disableAddress, setDisableAddress] = useState<boolean>(false);
  const [disableIndustry, setDisableIndustry] = useState<boolean>(false);
  const [salaryBracketsInfo, setSalaryBracketsInfo] = useState<{
    invalidSalaryBrackets: boolean;
    salaryBracketsString: string;
  }>({ invalidSalaryBrackets: true, salaryBracketsString: "" });
  const officeLocation = useRef(null);
  const attachmentTabs = useRef(null);
  const [otherIndustrySubindustry, setotherIndustrySubindustry] = useState<{
    industry: string;
    subIndustry: string;
    industryWarn: boolean;
  }>({ industry: "", subIndustry: "", industryWarn: false });

  const [logsData, setLogsData] = useState([
    {
      log_creation_time: "11:00PM",
      log_creation_date: "23 Nov, 2022",
      log_activty: "Change SPOC Detail",
      field: "Email Id",
      log_desc: "SP01-Rahul email id changes to rahul@evervent.in",
      type: "Modification",
    },
    {
      log_creation_time: "11:00PM",
      log_creation_date: "23 Nov, 2022",
      log_activty: "Change SPOC Detail",
      field: "Email Id",
      log_desc: "SP01-Rahul email id changes to rahul@evervent.in",
      type: "Modification",
    },
    {
      log_creation_time: "11:00PM",
      log_creation_date: "23 Nov, 2022",
      log_activty: "Change SPOC Detail",
      field: "Email Id",
      log_desc: "SP01-Rahul email id changes to rahul@evervent.in",
      type: "Modification",
    },
    {
      log_creation_time: "11:00PM",
      log_creation_date: "23 Nov, 2022",
      log_activty: "Change SPOC Detail",
      field: "Email Id",
      log_desc: "SP01-Rahul email id changes to rahul@evervent.in",
      type: "Modification",
    },
  ]);
  const [columns, setColumns] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [clientEiditPermission, setClientEiditPermission] = useState(false);

  const handleClicks = (id: any) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    // console.count("setIsClientAssigned")
    // const onSuccess = (res: any) => {
    //   setIsClientAssigned(res.data.data.founded);
    // };

    // const onError = (err: any) => {
    //   console.log("err", err);
    // };
    // CLIENT_SERVICES.check_isclient_attached_toPolicy(onSuccess, onError, id);
  }, []);
  let timeout: any;
  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }
    const delayedApiCall = () => {
      
      isPolicyAttached();
    }
 
    timeout = setTimeout(delayedApiCall, 900);
 
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

  },[])
  useEffect(() => {
    
  })
  const isPolicyAttached = () => {
    const onSuccess = (res: any) => {
      setIsClientAssigned(res.data.data.founded);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };
    
    CLIENT_SERVICES.check_isclient_attached_toPolicy(onSuccess, onError, id);
  }




  let timeoutIdData: any;

  useEffect(() => {
    console.count("Broker");
    setShowLoader(true);
    setClientEiditPermission(
      BROKER?.Clients.edit === undefined ? false : BROKER.Clients.edit
    );

    if (timeoutIdData) {
      clearTimeout(timeoutIdData);
    }
 
    const delayedApiCall = () => {
      console.count("Baingin")
      get_policy_data();
      get_policy_status();
      get_industry_list((cb:any) => {
        setIndustryData(cb);
      });
       getSpoc();
      get_country_list((cb:any) => {
        setCountryData(cb);
      });
    }
    timeoutIdData = setTimeout(delayedApiCall, 1000);
 
    return () => {
      if (timeoutIdData) {
        clearTimeout(timeoutIdData);
      }
    };

  }, []);


  // useEffect(() => {
  //   console.log('hello')
  //   get_state_list(selectedCountry, (cb: any) => {
  //     setStateData(cb);
  //   });
  

  //   get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
  //     setCityData(cb);
  //   });
  // }, [selectedCountry, selectedState]);
  let checkCountry: any
  let timeoutIdData1: any;

  useEffect(() => {
    if (timeoutIdData1) {
      clearTimeout(timeoutIdData1);
    }
 
    const delayedApiCall = () => {
      console.log("selectCountry---",selectedCountry,checkCountry,  checkCountry !== selectedCountry)

      if (selectedCountry && checkCountry !== selectedCountry) {
        checkCountry = selectedCountry
        get_state_list(selectedCountry, (cb: any) => {
          setStateData(cb);
        });
      }
    }
    timeoutIdData1 = setTimeout(delayedApiCall, 2000);
 
    return () => {
      if (timeoutIdData1) {
        clearTimeout(timeoutIdData1);
      }
    };

  }, [selectedCountry]);
 

  let timeoutIdData2: any;

  useEffect(() => {
    if (timeoutIdData2) {
      clearTimeout(timeoutIdData2);
    }
 
    const delayedApiCall = () => {
      if (selectedCountry && selectedState) {
        get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
          setCityData(cb);
        });
      }
    }
    timeoutIdData2 = setTimeout(delayedApiCall, 2000);
 
    return () => {
      if (timeoutIdData2) {
        clearTimeout(timeoutIdData2);
      }
    };

  }, [selectedState]);
 

  const get_policy_data = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      const data: any = res.data.data.data.map((data: any) => ({
        ...data,
        id: data._id,
      }));
      setTotalRecords(res.data.data.countData);
      setPolicyData(data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.policy_data(
      onSuccess,
      onError,
      limit ? limit : 25,
      skip ? skip : 0,
      id
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    let data: clientsFormDTO[] = clientaddnewlayout;
    if (attrName === "location_updated") {
      get_client_one_data();
    }
    if (attrName === setclientstatus) {
      setclientstatus({ name: value, color: "" });
      setstatusChange(true);
      client_status(value);
      return;
    }
    if (attrName === "attachment_deleted") {
      get_client_one_data();
    }

    let filedtype_check: any =
      data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]?.children[
        attrName[3]
      ]?.children[attrName[4]]?.field_type;
    if (
      filedtype_check === "spoc" ||
      filedtype_check === "country" ||
      filedtype_check === "state" ||
      filedtype_check === "city" ||
      filedtype_check === "industry" ||
      filedtype_check === "subIndustry"
    ) {
      setIndividualEditKey({
        key:
          "layout." +
          attrName[0] +
          ".children." +
          attrName[1] +
          ".children." +
          attrName[2] +
          ".children." +
          attrName[3] +
          ".children." +
          attrName[4] +
          ".value",
        value: value && value.label,
      });
    } else {
      setIndividualEditKey({
        key:
          "layout." +
          attrName[0] +
          ".children." +
          attrName[1] +
          ".children." +
          attrName[2] +
          ".children." +
          attrName[3] +
          ".children." +
          attrName[4] +
          ".value",
        value: value,
      });
    }
    let key = attrName[5];
    if (attrName === "notes_new_data") {
      setNotesData((notesData) => [
        ...notesData,

        { name: "Umesh", date: new Date(), description: value },
      ]);
    } else if (attrName === setParentOrg) {
      setStateDataSubsidairy(false);
    } else if (attrName === "attachment_new_data") {
      const date = new Date();

      let day: any = date.getDate();
      let month: any = date.getMonth() + 1;
      let year: any = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      let currentDate: any = `${day}-${month}-${year}`;
      setAttachmentData((prevState) => [
        ...prevState,

        {
          doc: value.doc,

          description: value.description,

          date: currentDate,

          time: new Date().toLocaleTimeString(),

          doc_name: value.doc_name,
        },
      ]);
    } else if (attrName === setPostQueryForm) {
      attrName(value);
    } else if (attrName === setUploadFile) {
      attrName(value);
    } else if (key === "value") {
      //For required fields
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].required === true &&
        (value === undefined || value === null
          ? 0
          : value.toString().length) === 0
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = true;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = false;
      }
      //update value
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "industry"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
        // empty sub-industry
        data[attrName[0]].children[attrName[0]].children[
          attrName[2]
        ].children[1].children[attrName[0]].value = "";
        get_subindustry_list(value, (cb: any) => {
          setSubIndustryData(cb);
        });
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "subIndustry"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "country"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
        get_state_list(value, (cb: any) => {
          setStateData(cb);
        });
        setcountryId(value.label);
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 1
        ].children[attrName[4]].value = "";
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 2
        ].children[attrName[4]].value = "";
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "state"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
        get_city_list_via_name(
          countryId ? countryId : selectedCountry,
          value.label,
          (cb: any) => {
            setCityData(cb);
          }
        );
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value === "N.A."
          ? (data[attrName[0]].children[attrName[1]].children[
              attrName[2]
            ].children[attrName[3] + 1].children[attrName[4]].value = "N.A.")
          : (data[attrName[0]].children[attrName[1]].children[
              attrName[2]
            ].children[attrName[3] + 1].children[attrName[4]].value = "");
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "city"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "domainSelect"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "spoc"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      }
      setclientaddnewlayout([...data]);
    }
  };

  const updateAddressDetails = (key: string, value: string, city?: string) => {
    const onSuccess = (res: any) => {};
    const onError = (err: any) => {
      console.log(".....err", err);
    };
    CLIENT_SERVICES.client_indivisualEdit(
      {
        key: key,
        value: value,
      },
      onSuccess,
      {},
      onError,
      id
    );
  };

  //get one client data
  const get_client_one_data = () => {
    const onSuccess = (res: any) => {
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
      setclientstatus(res.data.data[0].status);
      setPriorityStatus(res.data.data[0].status);
      setClientId(res.data.data[0].clientId);
      setClientPrefix(res.data.data[0].preFix);
      setclientLocation(res.data.data[0].location);
      setAttachmentData(res.data.data[0].attachments);
      setfeatureEnable(res.data.data[0].settings);
      setCreatedAt(res.data.data[0].createdAt);
      setUpdatedAt(res.data.data[0].updatedAt);
      getStatusCode(res.data.data[0]._id);
      setEmployeeCount(res.data.data[0].employeeCount);

      let arr: any = [
        ...res.data.data[0].layout,

        { type: "section", id: "Locations", name: "Locations", children: [] },
        {
          type: "section",
          id: "Attachments",
          name: "Attachments",
          children: [],
        },
      ];
      setsidepanelLayout(arr);
      setDisplayLogo({
        logo: res.data.data[0].logo ?? "clientstaticlogo.jpg",
        warning: false,
      });
      let data: any = [];
      res.data.data[0].location.forEach((e: any) => {
        data.push({ ...e, id: e._id });
      });
      let newData = res.data.data[0].layout.map(
        (dataMainsection: clientsFormDTO) => {
          let newMainSection = { ...dataMainsection };
          newMainSection.children = dataMainsection.children.map(
            (dataSubsection) => {
              let newSubsection = { ...dataSubsection };
              newSubsection.children = dataSubsection.children.map(
                (dataRow) => {
                  let newRow = { ...dataRow };
                  newRow.children = dataRow.children.map((dataColumn) => {
                    let newColumn = { ...dataColumn };
                    newColumn.children = dataColumn.children.map(
                      (dataInput) => {
                        if (dataInput.field_lable === "Country") {
                          setSelectedcountry(dataInput.value);
                        }
                        if (dataInput.field_lable === "State") {
                          setSelectedState(dataInput.value);
                        }
                        if (dataInput.field_lable === "Organisation") {
                          setFirstNameChar(dataInput.value);
                        }
                        if (dataInput.field_type === "domainSelect") {
                          return { ...dataInput };
                        }
                        if (
                          (dataInput.field_lable === "Departments" ||
                            dataInput.field_lable === "Designations") &&
                          dataInput.value[0].label === ""
                        ) {
                          return {
                            ...dataInput,
                            value: [],
                          };
                        } else {
                          return { ...dataInput };
                        }
                      }
                    );
                    return newColumn;
                  });
                  return newRow;
                }
              );
              return newSubsection;
            }
          );
          return newMainSection;
        }
      );

      setclientaddnewlayout(newData);
    };

    const onError = (err: any) => {
      setShowLoader(false);
    };

    CLIENT_SERVICES.get_edit_one_data(onSuccess, onError, id);
  };

  //get spoc list
  const getSpoc = () => {
    const onSuccess = (res: any) => {
      setspocdata(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.get_Spoc(onSuccess, onError);
  };

  //get Status list
  const getStatusCode = (id: string) => {
    const onSuccess = (res: any) => {
      setstatusCode(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.dynamic_status_list(onSuccess, onError, id);
  };

  //get status
  const client_status = (value: any) => {
    setstatusChange(true);
    const data: any = statusCode.find((e: any) => e.value === value);
    let body = {
      status: {
        name: data?.value,
        color: data?.color,
      },
    };
    const onSuccess = (res: any) => {
      setstatusChange(false);
    };

    const onError = (err: any) => {
      console.log(".....err", err);
    };

    CLIENT_SERVICES.client_status(body, id, onSuccess, onError);
  };

  const handleClick = (e: any) => {
    const { name, checked } = e.target;
    setIndividualEditKey({ key: "settings." + name, value: checked });
    individual_edit({ key: "settings." + name, value: checked });
    setfeatureEnable((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      };
    });
    setToggle(!toggle);
  };

  const individual_edit = (attrName?: any) => {
    console.log("attrName", attrName);
    let data: clientsFormDTO[] = clientaddnewlayout;
    let useArg = false;
    if (!attrName) {
      return;
    }
    if (Object.keys(attrName).includes("key")) {
      for (let i = 0; i < Object.keys(attrName).length; i++) {
        if (typeof attrName[Object.keys(attrName)[i]] === "string") {
          if (attrName[Object.keys(attrName)[i]].includes("settings.")) {
            useArg = true;
          }
        }
      }
    }
    if (
      !data[attrName[0]]?.children[attrName[1]]?.children[attrName[2]]
        ?.children[attrName[3]]?.children[attrName[4]].value
    ) {
      if (!useArg) {
        setIndividualEditKey({ key: "", value: "" });
        return toast.error("Please enter valid value");
      }
    }

    if (
      attrName[6] !== "otherIndustrySubindustry" &&
      !Object.keys(attrName).includes("key")
    ) {
      if (attrName[0] || attrName[0] === 0) {
        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type === "email"
        ) {
          if (
            !validateEmail(
              data[attrName[0]].children[attrName[1]].children[attrName[2]]
                .children[attrName[3]].children[attrName[4]].value
            )
          ) {
            if (!useArg) {
              return toast.error("Please Enter Valid Email");
            }
          }
        }
        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type === "phone"
        ) {
          if (
            !validateMobileNumber(
              data[attrName[0]].children[attrName[1]].children[attrName[2]]
                .children[attrName[3]].children[attrName[4]].value
            )
          ) {
            if (!useArg) {
              return toast.error("Please Enter Valid Phone number");
            }
          }
        }
        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_lable ===
          "Domain"
        ) {
          if (
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[attrName[3]].children[attrName[4]].value?.length === 0
          ) {
            setIndividualEditKey({ key: "", value: "" });
            return toast.error("Please enter valid value");
          }
        }
        if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ===
            "country" ||
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ===
            "state" ||
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type === "city"
        ) {
          const index_country =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[attrName[3]].children[attrName[4]].field_type ===
            "country"
              ? attrName[3]
              : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                  .children[attrName[3]].children[attrName[4]].field_type ===
                "state"
              ? attrName[3] - 1
              : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                  .children[attrName[3]].children[attrName[4]].field_type ===
                "city"
              ? attrName[3] - 2
              : 0;
          const index_state =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[attrName[3]].children[attrName[4]].field_type ===
            "country"
              ? attrName[3] + 1
              : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                  .children[attrName[3]].children[attrName[4]].field_type ===
                "state"
              ? attrName[3]
              : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                  .children[attrName[3]].children[attrName[4]].field_type ===
                "city"
              ? attrName[3] - 1
              : 0;
          const index_city =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[attrName[3]].children[attrName[4]].field_type ===
            "country"
              ? attrName[3] + 2
              : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                  .children[attrName[3]].children[attrName[4]].field_type ===
                "state"
              ? attrName[3] + 1
              : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                  .children[attrName[3]].children[attrName[4]].field_type ===
                "city"
              ? attrName[3]
              : 0;

          const country =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[index_country].children[attrName[4]].value;
          const state =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[index_state].children[attrName[4]].value;
          const city =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[index_city].children[attrName[4]].value;

          if (!country || !state || !city) {
            return toast.error(
              "Please check Country, state or city may be empty"
            );
          } else {
            updateAddressDetails(
              "layout." +
                attrName[0] +
                ".children." +
                attrName[1] +
                ".children." +
                attrName[2] +
                ".children." +
                index_country +
                ".children." +
                attrName[4] +
                ".value",
              country
            );
            updateAddressDetails(
              "layout." +
                attrName[0] +
                ".children." +
                attrName[1] +
                ".children." +
                attrName[2] +
                ".children." +
                index_state +
                ".children." +
                attrName[4] +
                ".value",
              state
            );
            updateAddressDetails(
              "layout." +
                attrName[0] +
                ".children." +
                attrName[1] +
                ".children." +
                attrName[2] +
                ".children." +
                index_city +
                ".children." +
                attrName[4] +
                ".value",
              city,
              "city"
            );
            setDisableAddress(false);
          }
        } else if (
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ==
            "industry" ||
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]].field_type ===
            "subIndustry"
        ) {
          const index_industry =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[attrName[3]].children[attrName[4]].field_type ===
            "industry"
              ? attrName[3]
              : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                  .children[attrName[3]].children[attrName[4]].field_type ===
                "subIndustry"
              ? attrName[3] - 1
              : 0;
          const index_subIndustry =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[attrName[3]].children[attrName[4]].field_type ===
            "industry"
              ? attrName[3] + 1
              : data[attrName[0]].children[attrName[1]].children[attrName[2]]
                  .children[attrName[3]].children[attrName[4]].field_type ===
                "subIndustry"
              ? attrName[3]
              : 0;
          const industryy =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[index_industry].children[attrName[4]].value;
          const subIndustryy =
            data[attrName[0]].children[attrName[1]].children[attrName[2]]
              .children[index_subIndustry].children[attrName[4]].value;
          if (!industryy || !subIndustryy) {
            return toast.error(
              "Please check Industry or Sub-Industry may be empty"
            );
          } else {
            const onSuccess = (res: any) => {
              toast.success("Updated Successfully");
            };
            const onError = (err: any) => {
              console.log(".....err", err);
            };
            CLIENT_SERVICES.client_indivisualEdit(
              {
                key:
                  "layout." +
                  attrName[0] +
                  ".children." +
                  attrName[1] +
                  ".children." +
                  attrName[2] +
                  ".children." +
                  index_industry +
                  ".children." +
                  attrName[4] +
                  ".value",
                value: industryy,
              },
              onSuccess,
              {},
              onError,
              id
            );
            CLIENT_SERVICES.client_indivisualEdit(
              {
                key:
                  "layout." +
                  attrName[0] +
                  ".children." +
                  attrName[1] +
                  ".children." +
                  attrName[2] +
                  ".children." +
                  index_subIndustry +
                  ".children." +
                  attrName[4] +
                  ".value",
                value: subIndustryy,
              },
              onSuccess,
              {},
              onError,
              id
            );
            setDisableIndustry(false);
            return;
          }
        }
      }
    }

    const onSuccess = (res: any) => {
      toast.success("Updated Successfully");
    };
    const onError = (err: any) => {
      console.log(".....err", err);
    };

    if (attrName[6] === "otherIndustrySubindustry") {
      CLIENT_SERVICES.client_indivisualEdit(
        {
          key:
            "layout." +
            attrName[0] +
            ".children." +
            attrName[1] +
            ".children." +
            attrName[2] +
            ".children." +
            attrName[3] +
            ".children." +
            attrName[4] +
            ".value/salaryBracket",
          value:
            attrName[7] === "Industry"
              ? otherIndustrySubindustry.industry
              : otherIndustrySubindustry.subIndustry,
        },
        onSuccess,
        {},
        onError,
        id
      );
      return;
    }
    CLIENT_SERVICES.client_indivisualEdit(
      useArg
        ? attrName
        : individualEditKey?.key ===
          "layout.1.children.0.children.0.children.0.children.0.value"
        ? { key: "departments", value: individualEditKey.value }
        : individualEditKey?.key ===
          "layout.1.children.0.children.0.children.1.children.0.value"
        ? { key: "designations", value: individualEditKey.value }
        : individualEditKey,
      onSuccess,
      {},
      onError,
      id
    );
    setIndividualEditKey({ key: "", value: "" });
  };
  let timeoutId: any;


  useEffect(() => {
    if (timeoutId) {
        clearTimeout(timeoutId);
      }
   
    const delayedApiCall = () => {
      
        get_client_one_data();

      }
   
      timeoutId = setTimeout(delayedApiCall, 900);
   
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };

  }, [statusChange, attachment, uploadFile]); 

  const updateSalaryBrackets = (attrName: any) => {
    const onSuccess = (res: any) => {
      toast.success("Updated Successfully");
      get_client_one_data();
    };
    const onError = (err: any) => {
      console.log(".....err", err);
    };

    CLIENT_SERVICES.client_indivisualEdit(
      {
        key:
          "layout." +
          attrName[0] +
          ".children." +
          attrName[1] +
          ".children." +
          attrName[2] +
          ".children." +
          attrName[3] +
          ".children." +
          attrName[4] +
          ".value/salaryBracket",
        value: salaryBracketsInfo.salaryBracketsString,
      },
      onSuccess,
      {},
      onError,
      id
    );
  };

  const get_policy_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      setColumns([
        {
          field: "policyId",
          headerName: "Policy ID",
          width: 170,
          check_status: true,
        },
        {
          field: "clientName",
          headerName: "Client Name",
          width: 220,
          check_status: true,
        },
        {
          field: "clientId",
          headerName: "Client ID",
          width: 150,
          check_status: true,
        },
        {
          field: "policyType",
          headerName: "Policy Type",
          width: 200,
          check_status: true,
        },
        {
          field: "insurerDetails",
          headerName: "Insurer",
          width: 180,
          check_status: true,
        },
        {
          field: "planType",
          headerName: "Plan Type",
          width: 100,
          check_status: true,
        },
        {
          field: "policyNumber",
          headerName: "Policy Number",
          width: 180,
          check_status: true,
        },
        {
          field: "policyStartDate",
          headerName: "Policy Start Date",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row.policyStartDate,
                  userState.localInformation.dateFormat,
                  userState.localInformation.timeZone
                )}
              </span>
            );
          },
        },
        {
          field: "policyEndDate",
          headerName: "Policy End Date",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row.policyEndDate,
                  userState.localInformation.dateFormat,
                  userState.localInformation.timeZone
                )}
              </span>
            );
          },
        },
        {
          field: "status",
          headerName: "Policy Status",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 20 ? (
                    <Draft id={`_${row.status.toString()}`} />
                  ) : row.status === 21 ? (
                    <Inforced id={`_${row.status.toString()}`} />
                  ) : row.status === 22 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 23 ? (
                    <Renewed id={`_${row.status.toString()}`} />
                  ) : row.status === 24 ? (
                    <Void id={`_${row.status.toString()}`} />
                  ) : row.status === 25 ? (
                    <Cancelled id={`_${row.status.toString()}`} />
                  ) : row.status === 26 ? (
                    <Rejected id={`_${row.status.toString()}`} />
                  ) : row.status === 62 ? (
                    <Published id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "Enrollmentstatus",
          headerName: "Enrollment Status ",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.enrolment_status.value}`}>
                <div
                  className="status_dropdown"
                  id={`_${row.enrolment_status.value}`}
                >
                  {row.enrolment_status.value === 27 ? (
                    <Active id={`_${row.enrolment_status.value.toString()}`} />
                  ) : row.enrolment_status.value === 28 ? (
                    <Inactive
                      id={`_${row.enrolment_status.value.toString()}`}
                    />
                  ) : row.enrolment_status.value === 29 ? (
                    <Grace id={`_${row.enrolment_status.value.toString()}`} />
                  ) : null}
                  <span>{row.enrolment_status.label}</span>
                </div>
              </div>
            );
          },
        },
        {
          field: "B.Type",
          headerName: "B.Type",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.btype_status.value}`}>
                <div
                  className="status_dropdown"
                  id={`_${row.btype_status.value}`}
                >
                  {row.btype_status.value === 30 ? (
                    <New id={`_${row.btype_status.value.toString()}`} />
                  ) : row.btype_status.value === 31 ? (
                    <Renewal id={`_${row.btype_status.value.toString()}`} />
                  ) : null}
                  <span>{row.btype_status.label}</span>
                </div>
              </div>
            );
          },
        },
        {
          field: "liveCounts",
          headerName: "No. of Dependants",
          width: 150,
          check_status: true,
        },
        {
          field: "employeeCount",
          headerName: "No. of Employees",
          width: 150,
          check_status: true,
        },
        {
          field: "sumInsured",
          headerName: "SumInsured/Assured",
          width: 180,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;

            const formatCurrency = (value: any) =>
              formatCurrencyAccToUser(
                value,
                getCurrencyCodeRegex(userState.localInformation.currency),
                userState.localInformation.countryCurrencyCode,
                0
              );

            return (
              <span>
                {typeof row.sumInsured === "string"
                  ? formatCurrency(row.sumInsured)
                  : Array.isArray(row.sumInsured)
                  ? row.sumInsured.map(formatCurrency).join(", ")
                  : null}
              </span>
            );
          },
        },
        {
          field: "cdBalance",
          headerName: "CD Balance",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {formatCurrencyAccToUser(
                  row.cdBalance,
                  getCurrencyCodeRegex(userState.localInformation.currency),
                  userState.localInformation.countryCurrencyCode,
                  0
                )}
              </span>
            );
          },
        },
        {
          field: "premium",
          headerName: "Premium",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {formatCurrencyAccToUser(
                  row.cdBalance,
                  getCurrencyCodeRegex(userState.localInformation.currency),
                  userState.localInformation.countryCurrencyCode,
                  0
                )}
              </span>
            );
          },
        },
        {
          field: "locations",
          headerName: "Locations",
          width: 130,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.locations.map((obj: any) => obj.name).join(", ");
          },
        },
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.createdAt, dateFormat, timeZone)}
              </span>
            );
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.updatedAt, dateFormat, timeZone)}
              </span>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    POLICIES_SERVICES.get_status(onSuccess, onError);
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <Box className="layoutWrapper">
        {/* <LocationPopup
          open_status={locationPopup}
          attrName={setLocationPopup}
          value_update={updateMasterState}
        /> */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            <span className="detailIcon">{firstNameChar.charAt(0)}</span>
            <div>
              <h3>{firstNameChar}</h3>
              <p>
                {ClientPrefix}
                {ClientIds} - Created on:{" "}
                {date_time_format(createdAt, dateFormat, timeZone)}
              </p>
            </div>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {clientEiditPermission && (
              <div>
                <Tooltip title="Global Edit">
                  <Link
                    className="actionLinks"
                    onClick={() => {
                      navigate(`${ALL_ROUTES.EDIT_CLIENT}?id=${id}`);
                    }}
                  >
                    <BorderColorIcon />
                  </Link>
                </Tooltip>
              </div>
            )}

            {/* Status Dropdown */}
            <div id={`_${priorityStatus.toString()}`}>
              <div
                className="status_dropdown"
                id={`_${priorityStatus.toString()}`}
              >
                {priorityStatus === 17 ? (
                  <PostAddIcon id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 18 ? (
                  <CheckCircleIcon id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 19 ? (
                  <HighlightOffIcon id={`_${priorityStatus.toString()}`} />
                ) : null}
                {priorityStatus === 17 ? (
                  <span>Created</span>
                ) : priorityStatus === 18 ? (
                  <span>Active</span>
                ) : priorityStatus === 19 ? (
                  <span>Inactive</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="mt-0">
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection h-100">
                {productTabsStatus === "overview" ? (
                  <>
                    {sidepanelLayout.map(
                      (dataMainSection: contactsFormDTO, index: number) => (
                        <div key={index}>
                          <Link
                            className={
                              sectionStatus === dataMainSection.name
                                ? `active sectionLink`
                                : "sectionLink"
                            }
                            onClick={() => {
                              handleClicks(dataMainSection.name);
                              SetSectionStatus(
                                dataMainSection.name === undefined
                                  ? ""
                                  : dataMainSection.name
                              );
                            }}
                          >
                            <span>{dataMainSection.name}</span>
                          </Link>
                          {dataMainSection.children.map(
                            (dataSubsection, subSectionIndex) => (
                              <ul
                                key={subSectionIndex}
                                className="accordion_list"
                              >
                                <li>
                                  <Link
                                    className={
                                      subsectionStatus === dataSubsection.name
                                        ? `inner_link active`
                                        : `inner_link`
                                    }
                                    onClick={() => {
                                      SetSectionStatus(
                                        dataMainSection.name === undefined
                                          ? ""
                                          : dataMainSection.name
                                      );
                                      handleClicks(dataSubsection.name);
                                      SetsubSectionStatus(dataSubsection.name);
                                      SetSectionStatus(
                                        dataMainSection.name === undefined
                                          ? ""
                                          : dataMainSection.name
                                      );
                                    }}
                                  >
                                    {dataSubsection.name}
                                  </Link>
                                </li>
                              </ul>
                            )
                          )}
                        </div>
                      )
                    )}
                  </>
                ) : null}

                {productTabsStatus === "policies" ? (
                  <Link className={`active sectionLink`}>
                    <span>Policies</span>
                  </Link>
                ) : null}

                {productTabsStatus === "subsidiaries" ? (
                  <Link className={`active sectionLink`}>
                    <span>Subsidiaries</span>
                  </Link>
                ) : null}

                {productTabsStatus === "contacts" ? (
                  <Link className={`active sectionLink`}>
                    <span>Contacts</span>
                  </Link>
                ) : null}

                {productTabsStatus === "logs" ? (
                  <Link className={`active sectionLink`}>
                    <span>Logs</span>
                  </Link>
                ) : null}

                {productTabsStatus === "settings" ? (
                  <Link className={`active sectionLink`}>
                    <span>Settings</span>
                  </Link>
                ) : null}
              </Grid>
              <Grid
                xs
                className="rightSection h-100"
                sx={{ overflowX: "hidden" }}
              >
                <Grid
                  container
                  spacing={1}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid lg={7} md={9} xs={12} className="text-sm-center">
                    <div className="productTabs">
                      <Button
                        className={
                          productTabsStatus === "overview"
                            ? "defaultTab active"
                            : "defaultTab"
                        }
                        onClick={() => {
                          setProductTabsStatus("overview");
                        }}
                      >
                        Overview
                      </Button>
                      <Button
                        className={
                          productTabsStatus === "policies"
                            ? "defaultTab active"
                            : "defaultTab"
                        }
                        onClick={() => {
                          setProductTabsStatus("policies");
                        }}
                      >
                        Policies
                      </Button>
                      <Button
                        className={
                          productTabsStatus === "subsidiaries"
                            ? "defaultTab active"
                            : "defaultTab"
                        }
                        onClick={() => {
                          setProductTabsStatus("subsidiaries");
                        }}
                      >
                        Subsidiaries
                      </Button>
                      <Button
                        className={
                          productTabsStatus === "contacts"
                            ? "defaultTab active"
                            : "defaultTab"
                        }
                        onClick={() => {
                          setProductTabsStatus("contacts");
                        }}
                      >
                        Contacts
                      </Button>

                      <Button
                        className={
                          productTabsStatus === "settings"
                            ? "defaultTab active"
                            : "defaultTab"
                        }
                        onClick={() => {
                          setProductTabsStatus("settings");
                        }}
                      >
                        Settings
                      </Button>
                    </div>
                  </Grid>
                  <Grid
                    lg={5}
                    md={3}
                    xs={12}
                    style={{ textAlign: "right" }}
                    className="text-sm-center"
                    alignSelf="center"
                  >
                    <p className="lastupdate">
                      Last updated on:{" "}
                      {date_time_format(updatedAt, dateFormat, timeZone)}
                    </p>
                  </Grid>
                </Grid>

                {productTabsStatus === "overview" ? (
                  <>
                    <Grid container className="mb-3 mt-3">
                      <Grid xs={12}>
                        <div className="client_info_section">
                          <p className="client_info_p empID md_width">
                            Client ID :{" "}
                            <span>
                              {ClientPrefix}
                              {ClientIds}{" "}
                            </span>
                          </p>
                          <p className="client_info_p totalEmployees md_width">
                            Total Employees: <span>{employeeCount}</span>
                          </p>
                          <p className="client_info_p totalEmployees md_width mr-0">
                            Total Locations:{" "}
                            <span>{clientLocation.length + 1}</span>
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <Box className="upload-logo">
                          <Grid container spacing={0} className="p-0">
                            <Grid xs={12}>
                              <img
                                src={
                                  displayLogo.logo ??
                                  "images/preview-logo-img.svg"
                                }
                                alt=""
                                onError={(e: any) => {
                                  e.target.src = "clientstaticlogo.jpg";
                                }}
                                className="upload_company_logo add_logo"
                              />
                              <Link className="upload_img" component="label">
                                <input
                                  disabled={clientEiditPermission === false}
                                  hidden
                                  accept="image/*"
                                  multiple
                                  type="file"
                                  onChange={(e: any) => {
                                    let displaydata: any = displayLogo;
                                    displaydata.warning =
                                      displayLogo.logo !== "" ? true : false;
                                    setDisplayLogo({ ...displaydata });
                                    const file = e.target.files[0];
                                    uploadImage(
                                      file,
                                      (base64Image: any, base64String: any) => {
                                        setAddClientLogo(base64Image);
                                        setDisplayLogo({
                                          logo: base64String,
                                          warning: false,
                                        });
                                        const onSuccess = (res: any) => {
                                          toast.success("Updated Successfully");
                                        };
                                        const onError = (err: any) => {
                                          console.log(".....err", err);
                                        };
                                        CLIENT_SERVICES.client_indivisualEdit(
                                          {
                                            key: "logo",
                                            value: base64Image,
                                          },
                                          onSuccess,
                                          {},
                                          onError,
                                          id
                                        );
                                      }
                                    );
                                  }}
                                />

                                <img src="images/upload_logo_icon.svg" alt="" />
                                <span>
                                  {displayLogo.logo === "clientstaticlogo.jpg"
                                    ? "Replace Sample Logo"
                                    : "Update Logo"}
                                </span>
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid xs className="">
                      {clientaddnewlayout?.map(
                        (
                          dataMainSection: clientsFormDTO,
                          indexMainSection: number
                        ) => (
                          // Main section
                          <Box
                            onMouseEnter={() => {
                              SetSectionStatus(dataMainSection.name);
                            }}
                            className="detailSection"
                          >
                            <div id={dataMainSection.name}>
                              <div className="sectionTitle">
                                <h4>{dataMainSection.name}</h4>
                              </div>
                              {dataMainSection.children.map(
                                (dataSubsection, indexSubsection) => (
                                  // Sub Section
                                  <Grid
                                    onMouseEnter={() => {
                                      SetsubSectionStatus(dataSubsection.name);
                                    }}
                                    container
                                  >
                                    <Grid xs={12}>
                                      <h5
                                        id={dataSubsection.name}
                                        key={dataSubsection.type}
                                        className="subHeading"
                                      >
                                        {dataSubsection.name}
                                      </h5>
                                      {dataSubsection.children.map(
                                        (dataRow, indexRow) => {
                                          // Row
                                          return (
                                            <>
                                              <Grid
                                                container
                                                className="subRow"
                                                spacing={3}
                                              >
                                                {dataRow.children.map(
                                                  (dataColumn, indexColumn) => (
                                                    <>
                                                      {/* // Columns created */}

                                                      {dataColumn.children.map(
                                                        (
                                                          dataInput,
                                                          indexInput
                                                        ) => {
                                                          if (
                                                            dataInput.field_lable ===
                                                            "Salary Brackets"
                                                          ) {
                                                            return null;
                                                          }

                                                          // input Component will be here
                                                          return (
                                                            <Grid
                                                              xs={
                                                                dataInput.field_lable ===
                                                                  "Departments" ||
                                                                dataInput.field_lable ===
                                                                  "Designations"
                                                                  ? 6
                                                                  : true
                                                              }
                                                              className="subColumn"
                                                            >
                                                              {dataInput.field_type ===
                                                              "singleselect" ? (
                                                                <SelectDropdownDisable
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable +
                                                                    " singleselect"
                                                                  }
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  dropdown_data={
                                                                    dataInput.pickliststep
                                                                      ? dataInput.pickliststep
                                                                      : []
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                  "domainSelect" &&
                                                                dataInput.field_lable ===
                                                                  "Domain" ? (
                                                                <>
                                                                  <UGEmailInputDisable
                                                                    class_name="inputField"
                                                                    title={
                                                                      dataInput.field_lable
                                                                    }
                                                                    value={
                                                                      dataInput.value
                                                                    }
                                                                    attrName={[
                                                                      indexMainSection,
                                                                      indexSubsection,
                                                                      indexRow,
                                                                      indexColumn,
                                                                      indexInput,
                                                                      "value",
                                                                    ]}
                                                                    value_update={
                                                                      updateMasterState
                                                                    }
                                                                    params={
                                                                      individual_edit
                                                                    }
                                                                    warn_status={
                                                                      dataInput?.error
                                                                    }
                                                                    required={
                                                                      dataInput?.required
                                                                    }
                                                                    disabled={
                                                                      clientEiditPermission ===
                                                                      false
                                                                    }
                                                                    error_messg={`Enter ${dataInput.field_lable}`}
                                                                  />
                                                                </>
                                                              ) : dataInput.field_type ===
                                                                "domainSelect" ? (
                                                                <ARMultiInputFieldDisable
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                  stopEdit={
                                                                    isClientAssigned
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "phone" ? (
                                                                <RKTextFieldDisable
                                                                  validation_type="numeric"
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  error_messg={
                                                                    dataInput
                                                                      .value
                                                                      .length ===
                                                                    0
                                                                      ? `Enter ${dataInput.field_lable}`
                                                                      : dataInput.characterLimit &&
                                                                        dataInput
                                                                          .value
                                                                          .length >
                                                                          dataInput.characterLimit
                                                                      ? `Maximum ${dataInput.characterLimit} limit`
                                                                      : ""
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  charcterlimit={
                                                                    !dataInput.characterLimit
                                                                      ? 11
                                                                      : dataInput.characterLimit
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "email" ? (
                                                                <RKTextFieldDisable
                                                                  validation_type="email"
                                                                  type="email"
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  error_messg={
                                                                    dataInput
                                                                      .value
                                                                      .length ===
                                                                    0
                                                                      ? `Enter ${dataInput.field_lable}`
                                                                      : validateEmail(
                                                                          dataInput.value
                                                                        ) ===
                                                                        false
                                                                      ? `Enter a valid email Id`
                                                                      : ""
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  charcterlimit={
                                                                    !dataInput.characterLimit
                                                                      ? 50
                                                                      : dataInput.characterLimit
                                                                  }
                                                                  disabled={
                                                                    dataInput.field_lable ===
                                                                      "Organization Email" ||
                                                                    clientEiditPermission ===
                                                                      false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "clientId" ? null : dataInput.field_type ===
                                                                "datepicker" ? (
                                                                <DatePicker
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value={
                                                                    dataInput.value ||
                                                                    null
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  error_message="Enter a Valid Date"
                                                                  warn_status={
                                                                    dataInput.error ===
                                                                    undefined
                                                                      ? false
                                                                      : dataInput.error
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "searchselect" ? (
                                                                <SearchDropdownDisable
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  data={
                                                                    dataInput.pickliststep
                                                                      ? dataInput.pickliststep
                                                                      : []
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error ===
                                                                    undefined
                                                                      ? false
                                                                      : dataInput.error
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  error_messg={
                                                                    "Select the value"
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "checkbox" ? (
                                                                <CheckBox
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  name={
                                                                    dataInput.field_lable
                                                                  }
                                                                  id={
                                                                    dataInput._id
                                                                  }
                                                                  value={Boolean(
                                                                    dataInput.value
                                                                  )}
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  default_check={
                                                                    true
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "number" ? (
                                                                <RKTextFieldDisable
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  validation_type="numeric"
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  error_messg={
                                                                    dataInput
                                                                      .value
                                                                      .length ===
                                                                    0
                                                                      ? `Enter ${dataInput.field_lable}`
                                                                      : dataInput.characterLimit &&
                                                                        dataInput
                                                                          .value
                                                                          .length >
                                                                          dataInput.characterLimit
                                                                      ? `Maximum ${dataInput.characterLimit} limit`
                                                                      : ""
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  charcterlimit={
                                                                    !dataInput.maxDigits
                                                                      ? 12
                                                                      : dataInput.maxDigits
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ==
                                                                "currency" ? (
                                                                <CurrencyTextField
                                                                  validation_type="numeric"
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  error_messg={
                                                                    dataInput
                                                                      .value
                                                                      .length ===
                                                                    0
                                                                      ? `Enter ${dataInput.field_lable}`
                                                                      : dataInput.characterLimit &&
                                                                        dataInput
                                                                          .value
                                                                          .length >
                                                                          dataInput.characterLimit
                                                                      ? `Maximum ${dataInput.characterLimit} Limit`
                                                                      : ""
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  charcterlimit={
                                                                    !dataInput.maxDigits
                                                                      ? 12
                                                                      : dataInput.maxDigits
                                                                  }
                                                                  disable={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "multiselect" ? (
                                                                <MultipleSearchDropdown
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  multiple_data={
                                                                    dataInput.pickliststep
                                                                      ? dataInput.pickliststep
                                                                      : []
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error ===
                                                                    undefined
                                                                      ? false
                                                                      : dataInput.error
                                                                  }
                                                                  error_messg={
                                                                    "Select the value"
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "multiline" ? (
                                                                <TextAreaField
                                                                  specialcharacter={
                                                                    !dataInput.speacialChar
                                                                      ? false
                                                                      : dataInput.speacialChar
                                                                  }
                                                                  alphanumeric={
                                                                    !dataInput.alphaNumeric
                                                                      ? false
                                                                      : dataInput.alphaNumeric
                                                                  }
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error ===
                                                                    undefined
                                                                      ? false
                                                                      : dataInput.error
                                                                  }
                                                                  error_messg={
                                                                    dataInput
                                                                      .value
                                                                      .length ===
                                                                    0
                                                                      ? `Enter ${dataInput.field_lable}`
                                                                      : dataInput.characterLimit &&
                                                                        dataInput
                                                                          .value
                                                                          .length >
                                                                          dataInput.characterLimit
                                                                      ? `Maximum ${dataInput.characterLimit} charcter`
                                                                      : ""
                                                                  }
                                                                  charcterlimit={
                                                                    !dataInput.characterLimit
                                                                      ? 30
                                                                      : dataInput.characterLimit
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  disable={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "url" ? (
                                                                <RKTextFieldDisable
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  validation_type="url"
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  error_messg={
                                                                    dataInput
                                                                      .value
                                                                      .length ===
                                                                    0
                                                                      ? `Enter ${dataInput.field_lable}`
                                                                      : validateUrl(
                                                                          dataInput.value
                                                                        ) ===
                                                                        false
                                                                      ? `Enter a valid Url`
                                                                      : ""
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  charcterlimit={
                                                                    !dataInput.characterLimit
                                                                      ? 50
                                                                      : dataInput.characterLimit
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "country" ? (
                                                                <SearchDropdownDisable
                                                                  disable_status={
                                                                    disableAddress
                                                                  }
                                                                  set_disable_status={
                                                                    setDisableAddress
                                                                  }
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  class_name="inputField"
                                                                  title="Country"
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  data={
                                                                    countryData
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  error_messg="Please select country"
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "state" ? (
                                                                <SearchDropdownDisable
                                                                  disable_status={
                                                                    disableAddress
                                                                  }
                                                                  set_disable_status={
                                                                    setDisableAddress
                                                                  }
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  class_name="inputField"
                                                                  title="State"
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  data={
                                                                    stateData
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  error_messg="Please select state"
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "city" ? (
                                                                <SearchDropdownDisable
                                                                  disable_status={
                                                                    disableAddress
                                                                  }
                                                                  set_disable_status={
                                                                    setDisableAddress
                                                                  }
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  class_name="inputField"
                                                                  title="City"
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  data={
                                                                    cityData
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  error_messg="Please select city"
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ===
                                                                "spoc" ? (
                                                                <SearchDropdownDisable
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  class_name="inputField"
                                                                  title="Spoc"
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  data={
                                                                    spocdata
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  error_messg="Please select spoc"
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              ) : dataInput.field_type ==
                                                                "industry" ? (
                                                                <>
                                                                  <Box>
                                                                    <Grid
                                                                      container
                                                                      columnSpacing={
                                                                        3
                                                                      }
                                                                      padding={
                                                                        0
                                                                      }
                                                                    >
                                                                      <Grid
                                                                        xs={
                                                                          dataInput.value ===
                                                                          "Other"
                                                                            ? 6
                                                                            : 12
                                                                        }
                                                                      >
                                                                        <SearchDropdownDisable
                                                                          params={
                                                                            individual_edit
                                                                          }
                                                                          set_disable_status={
                                                                            setDisableIndustry
                                                                          }
                                                                          class_name="inputField"
                                                                          title="Industry"
                                                                          value={
                                                                            dataInput.value
                                                                          }
                                                                          attrName={[
                                                                            indexMainSection,
                                                                            indexSubsection,
                                                                            indexRow,
                                                                            indexColumn,
                                                                            indexInput,
                                                                            "value",
                                                                          ]}
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          data={
                                                                            IndustryData
                                                                          }
                                                                          error_messg="Please select Industry"
                                                                          warn_status={
                                                                            false
                                                                          }
                                                                          disabled={
                                                                            clientEiditPermission ===
                                                                              false ||
                                                                            dataInput.value ===
                                                                              "Other"
                                                                          }
                                                                        />
                                                                      </Grid>

                                                                      {dataInput.value ===
                                                                        "Other" && (
                                                                        <Grid
                                                                          xs={6}
                                                                        >
                                                                          <RKTextFieldDisable
                                                                            class_name="inputField"
                                                                            title="Industry"
                                                                            params={
                                                                              individual_edit
                                                                            }
                                                                            value={
                                                                              otherIndustrySubindustry.industry
                                                                            }
                                                                            attrName={[
                                                                              indexMainSection,
                                                                              indexSubsection,
                                                                              indexRow,
                                                                              indexColumn,
                                                                              indexInput,
                                                                              "value",
                                                                              "otherIndustrySubindustry",
                                                                              "Industry",
                                                                            ]}
                                                                            value_update={(
                                                                              attrName: any,
                                                                              value: any
                                                                            ) => {
                                                                              setotherIndustrySubindustry(
                                                                                (
                                                                                  prev
                                                                                ) => ({
                                                                                  ...prev,
                                                                                  industry:
                                                                                    value,
                                                                                })
                                                                              );
                                                                            }}
                                                                            warn_status={
                                                                              otherIndustrySubindustry.industryWarn
                                                                            }
                                                                            required={
                                                                              true
                                                                            }
                                                                            charcterlimit={
                                                                              !dataInput.characterLimit
                                                                                ? 50
                                                                                : dataInput.characterLimit
                                                                            }
                                                                          />
                                                                        </Grid>
                                                                      )}
                                                                    </Grid>
                                                                  </Box>
                                                                </>
                                                              ) : dataInput.field_type ==
                                                                "subIndustry" ? (
                                                                <>
                                                                  <Box>
                                                                    <Grid
                                                                      container
                                                                      columnSpacing={
                                                                        3
                                                                      }
                                                                      padding={
                                                                        0
                                                                      }
                                                                    >
                                                                      <Grid
                                                                        xs={
                                                                          dataInput.value ===
                                                                          "Other"
                                                                            ? 6
                                                                            : 12
                                                                        }
                                                                      >
                                                                        <SearchDropdownDisable
                                                                          params={
                                                                            individual_edit
                                                                          }
                                                                          set_disable_status={
                                                                            setDisableIndustry
                                                                          }
                                                                          class_name="inputField"
                                                                          title="Sub Industry"
                                                                          value={
                                                                            dataInput.value
                                                                          }
                                                                          attrName={[
                                                                            indexMainSection,
                                                                            indexSubsection,
                                                                            indexRow,
                                                                            indexColumn,
                                                                            indexInput,
                                                                            "value",
                                                                          ]}
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          data={
                                                                            subIndustryData
                                                                          }
                                                                          error_messg="Please select Industry"
                                                                          warn_status={
                                                                            false
                                                                          }
                                                                          disabled={
                                                                            clientEiditPermission ===
                                                                              false ||
                                                                            dataInput.value ===
                                                                              "Other"
                                                                          }
                                                                        />
                                                                      </Grid>

                                                                      {dataInput.value ===
                                                                        "Other" && (
                                                                        <Grid
                                                                          xs={6}
                                                                        >
                                                                          <RKTextFieldDisable
                                                                            class_name="inputField"
                                                                            title="Sub Industry"
                                                                            params={
                                                                              individual_edit
                                                                            }
                                                                            value={
                                                                              otherIndustrySubindustry.subIndustry
                                                                            }
                                                                            attrName={[
                                                                              indexMainSection,
                                                                              indexSubsection,
                                                                              indexRow,
                                                                              indexColumn,
                                                                              indexInput,
                                                                              "value",
                                                                              "otherIndustrySubindustry",
                                                                              "SubIndustry",
                                                                            ]}
                                                                            value_update={(
                                                                              attrName: any,
                                                                              value: any
                                                                            ) => {
                                                                              setotherIndustrySubindustry(
                                                                                (
                                                                                  prev
                                                                                ) => ({
                                                                                  ...prev,
                                                                                  subIndustry:
                                                                                    value,
                                                                                })
                                                                              );
                                                                            }}
                                                                            warn_status={
                                                                              false
                                                                            }
                                                                            charcterlimit={
                                                                              !dataInput.characterLimit
                                                                                ? 50
                                                                                : dataInput.characterLimit
                                                                            }
                                                                          />
                                                                        </Grid>
                                                                      )}
                                                                    </Grid>
                                                                  </Box>
                                                                </>
                                                              ) : dataInput.field_type ===
                                                                "hide" ? null : (
                                                                <RKTextFieldDisable
                                                                  params={
                                                                    individual_edit
                                                                  }
                                                                  specialcharacter={
                                                                    !dataInput.speacialChar
                                                                      ? false
                                                                      : dataInput.speacialChar
                                                                  }
                                                                  alphanumeric={
                                                                    dataInput.field_lable ===
                                                                      "Chief Contact Name" ||
                                                                    dataInput.field_lable ===
                                                                      "Chief Contact Designation"
                                                                      ? true
                                                                      : !dataInput.alphaNumeric
                                                                      ? false
                                                                      : dataInput.alphaNumeric
                                                                  }
                                                                  validation_type="name"
                                                                  class_name="inputField"
                                                                  title={
                                                                    dataInput.field_lable
                                                                  }
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  info_status={
                                                                    dataInput.toolTip
                                                                  }
                                                                  info_message={
                                                                    dataInput.tipText
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                  error_messg={
                                                                    dataInput
                                                                      .value
                                                                      .length ===
                                                                    0
                                                                      ? `Enter ${dataInput.field_lable}`
                                                                      : dataInput.characterLimit &&
                                                                        dataInput
                                                                          .value
                                                                          .length >
                                                                          dataInput.characterLimit
                                                                      ? `Maximum ${dataInput.characterLimit} charcter`
                                                                      : ""
                                                                  }
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  charcterlimit={
                                                                    dataInput.field_lable === "Company Id" ? undefined :
                                                                    !dataInput.characterLimit
                                                                      ? 30
                                                                      : dataInput.characterLimit
                                                                  }
                                                                  disabled={
                                                                    clientEiditPermission ===
                                                                    false
                                                                  }
                                                                />
                                                              )}
                                                            </Grid>
                                                          );
                                                        }
                                                      )}

                                                      {dataColumn.children.map(
                                                        (
                                                          dataInput,
                                                          indexInput
                                                        ) => {
                                                          if (
                                                            dataInput.field_lable ===
                                                            "Salary Brackets"
                                                          ) {
                                                            return (
                                                              <Grid
                                                                xs={12}
                                                                className="subColumn"
                                                              >
                                                                <form>
                                                                  <SalaryBracketInputs
                                                                    dbValue={
                                                                      dataInput.value
                                                                    }
                                                                    getSalaryBrackets={
                                                                      setSalaryBracketsInfo
                                                                    }
                                                                    editMode
                                                                    updateApi={() =>
                                                                      updateSalaryBrackets(
                                                                        [
                                                                          indexMainSection,
                                                                          indexSubsection,
                                                                          indexRow,
                                                                          indexColumn,
                                                                          indexInput,
                                                                        ]
                                                                      )
                                                                    }
                                                                    stopEditOldValues={
                                                                      isClientAssigned
                                                                    }
                                                                    disabled={
                                                                      clientEiditPermission ===
                                                                      false
                                                                    }
                                                                  />
                                                                </form>
                                                              </Grid>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </>
                                                  )
                                                )}
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </div>
                          </Box>
                        )
                      )}

                      {/* // add dynamic location */}
                      <div
                        onMouseEnter={() => {
                          SetSectionStatus("Locations");
                        }}
                        id="Locations"
                        ref={officeLocation}
                      >
                        <LocationTableView
                          id={id}
                          parentType={CLIENT_SERVICES.client_indivisualEdit}
                          length={clientLocation.length ?? 0}
                          data={clientLocation}
                          deleteurl={
                            CLIENT_SERVICES.delete_location_client_details
                          }
                          refetchData={get_client_one_data}
                          disabled={clientEiditPermission === false}
                        />
                      </div>

                      <Box
                        id="Attachments"
                        className="detailSection"
                        ref={attachmentTabs}
                        onMouseEnter={() => {
                          SetSectionStatus("Attachments");
                        }}
                      >
                        <UploadAttachmentClientDetails
                          open_status={uploadFile}
                          attrName={setUploadFile}
                          value_update={updateMasterState}
                          length={attachmentData.length}
                          id={id}
                        />

                        {/* Attachment Section Start*/}
                        <div className="sectionTitle">
                          <h4>Attachments </h4>
                          <div className="actionBtns">
                            <Link
                              className="blueBtn addfile"
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (clientEiditPermission === false) {
                                  return toast.error(
                                    "Edit Permissions Not Allowed"
                                  );
                                }
                                setUploadFile(true);
                              }}
                            >
                              Add Document
                            </Link>
                          </div>
                        </div>
                        <Attachment
                          open_status={attachment}
                          attrName={setAttachment}
                          value_update={updateMasterState}
                          dataArray={attachmentData}
                          deleteurl={CLIENT_SERVICES.delete_attachment_client}
                          disabled={clientEiditPermission === false}
                        />
                        {/* Attachment Section End*/}
                      </Box>
                    </Grid>
                  </>
                ) : null}

                {productTabsStatus === "policies" ? (
                  <>
                    <Grid xs={12} className="detailBox mb-5">
                      <Grid container padding="0">
                        <Grid xs={12}>
                          <div style={{ height: "100%", width: "100%" }}>
                            <UGTable
                              header_data={columns}
                              data={policyData}
                              value_update={updateMasterState}
                              attrName={"UGTable"}
                              BE_filter_code={E_BE_FILTER_CODE.POLICIES}
                              totalRecords={totalRecords}
                              refetch_data={get_policy_data}
                              setTableLimit={setTableLimit}
                              setTableSkip={setTableSkip}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                {productTabsStatus === "subsidiaries" ? (
                  <Subsidiary
                    disabled={clientEiditPermission === false}
                    clientName={`${ClientPrefix}${ClientIds}-${firstNameChar}`}
                  />
                ) : null}

                {productTabsStatus === "contacts" ? <Contacts /> : null}

                {productTabsStatus === "logs" ? (
                  <>
                    <Box>
                      <Grid xs={12} className="px-0">
                        <div className="tableview">
                          <div className="tableinner">
                            <ul className="tableHeader">
                              <li>
                                Creation Time
                                <button className="sorting-btn"></button>
                              </li>
                              <li>
                                Creation Date
                                <button className="sorting-btn"></button>
                              </li>
                              <li>
                                Activity
                                <button className="sorting-btn"></button>
                              </li>
                              <li>
                                Field
                                <button className="sorting-btn mr-0"></button>
                              </li>
                              <li>
                                Description
                                <button className="sorting-btn mr-0"></button>
                              </li>
                              <li>
                                Type
                                <button className="sorting-btn mr-0"></button>
                              </li>
                            </ul>
                            {logsData.map((data: any, index) => (
                              <ul className="tableData">
                                <li>{data.log_creation_time}</li>
                                <li>{data.log_creation_date}</li>
                                <li>{data.log_activty}</li>
                                <li>{data.field}</li>
                                <li>{data.log_desc}</li>
                                <li>{data.type}</li>
                              </ul>
                            ))}
                          </div>
                        </div>
                      </Grid>
                    </Box>
                  </>
                ) : null}

                {productTabsStatus === "settings" ? (
                  <Box padding={0} margin={0}>
                    <Grid container padding={0} margin={0}>
                      <Grid
                        xs={12}
                        className="detailSection"
                        padding={"12px"}
                        marginTop={3}
                      >
                        <Grid
                          container
                          className="emp_switchFields"
                          padding={3}
                          margin={0}
                          gap={"12px"}
                        >
                          <Grid xs={4} display={"flex"} alignItems="center">
                            <span> Enable Employer Portal</span>
                            <input
                              type="checkbox"
                              className="switch"
                              name="enableEmployerPortal"
                              onClick={(e: any) => {
                                if (!e.target.checked) {
                                  toast.info(
                                    "Disabling a toogle will pause the access to the Employee platform, enabling the toggle will resume the access again.",
                                    {
                                      autoClose: false,
                                    }
                                  );
                                }
                                handleClick(e);
                              }}
                              checked={featureEnable.enableEmployerPortal}
                              disabled={clientEiditPermission === false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ClientDetails;
