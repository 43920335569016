//used
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import SalaryBracketInputs from "../../../../../Components/Broker/SalaryBracketInput/SalaryBracketInput";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { CLIENT_SERVICES } from "../../../../../Services/Clients/ClientService";
import { clientsFormDTO } from "../../../../../Services/DTO/ClientDTO";
import { contactsFormDTO } from "../../../../../Services/DTO/ContactsDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { Post } from "../../../../../Supporting files/CallAPI";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextField from "../../../../../Supporting files/Currency/CurrencyTextField";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  get_city_list_via_name,
  get_country_list,
  get_industry_list,
  get_state_list,
  get_subindustry_list,
  uploadImage,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import ARMultiInputField from "../../../../../Supporting files/UGEmailInput/ARMultiInputField";
import UGEmailInput from "../../../../../Supporting files/UGEmailInput/UGEmailInput";
import "../AddNewClient/AddNewClient.scss";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";

function AddNewClient() {
  const navigate = useNavigate();
  const [showloader, setshowloader] = useState<boolean>(true);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const userState: TUserData = useAppSelector(    (state: RootState) => state.userDetailsSlice  );
  const [sectionStatus, SetSectionStatus] = useState<any>(    "Organisation Details"  );
  const [subsectionStatus, SetsubSectionStatus] = useState<any>("Basic Info");
  const [uploadFile, setUploadFile] = useState(false);
  const [clientaddnewlayout, setclientaddnewlayout] = useState<    clientsFormDTO[]  >([]);
  const [displayLogo, setDisplayLogo] = useState<{
    logo: string;
    warning: boolean;
  }>({
    logo: "clientstaticlogo.jpg",
    warning: false,
  });
  const [addClass, setAddClass] = useState<boolean>(false);
  const [countryData, setCountryData] = useState<    Array<{ label: string; id: string }>  >([]);
  const [spocdata, setspocdata] = useState<    Array<{ label: string; id: string }>  >([]);
  const [countryId, setcountryId] = useState("");
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>  >([]);
  const [cityData, setCityData] = useState<    Array<{ label: string; id: string }>  >([]);
  const [registerSpocId, setregisterSpocId] = useState("");
  const [IndustryData, setIndustryData] = useState<    Array<{ label: string; id: string }>  >([]);
  const [subIndustryData, setSubIndustryData] = useState<    Array<{ label: string; id: string }>  >([]);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [featureEnable, setfeatureEnable] = useState<{
    enableEmployeePortal: any;
    enableEmployerPortal: any;
  }>({ enableEmployeePortal: false, enableEmployerPortal: false });
  const [otherIndustrySubindustry, setotherIndustrySubindustry] = useState<{
    industry: string;
    subIndustry: string;
    industryWarn: boolean;
  }>({ industry: "", subIndustry: "", industryWarn: false });

  const [sidepanelLayout, setsidepanelLayout] = useState<any[]>([]);
  const [addClientLogo, setAddClientLogo] = useState<string>(
    "clientstaticlogo.jpg"
  );
  const [addressData, setAddressData] = useState<
    Array<{
      title: string;
      name: string;
      type: string;
      street: string;
      area: string;
      country: string;
      zip: string;
      state: string;
      city: string;
      spoc_tittle: string;
      spocId: string;
      delete?: boolean;
      warnName?: boolean;
      warnType?: boolean;
      warnStreet?: boolean;
      warnArea?: boolean;
      warnCountry?: boolean;
      warnZip?: boolean;
      warnState?: boolean;
      warnCity?: boolean;
      warnSpocId?: boolean;
    }>
  >([
    {
      title: "Registered Address Details",
      name: "Registered Address",
      type: "Registered Address",
      street: "",
      area: "",
      country: "",
      zip: "",
      state: "",
      city: "",
      spoc_tittle: "",
      spocId: "",
      delete: false,
      warnName: false,
      warnType: false,
      warnStreet: false,
      warnArea: false,
      warnCountry: false,
      warnZip: false,
      warnState: false,
      warnCity: false,
      warnSpocId: false,
    },
  ]);
  const [officialMailId, setOfficialMailId] = useState<any>("");
  const [salaryBracketsInfo, setSalaryBracketsInfo] = useState<{
    invalidSalaryBrackets: boolean;
    salaryBracketsString: string;
  }>({ invalidSalaryBrackets: true, salaryBracketsString: "" });
  const [upperWarnStatus, setUpperWarnStatus] = useState<boolean>(false);
  const handleClicks = (id: any) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const updateMasterState = (attrName: any, value: any) => {
    let data: clientsFormDTO[] = [...clientaddnewlayout];
    let dataLocation: any = [...addressData];
    let key = attrName[5];
    const array_data: any = [...addressData];
    if (attrName[0] === "otherIndustrySubindustry") {
      let otherIndustry = { ...otherIndustrySubindustry };
      if (attrName[1] === "Industry") {
        otherIndustry.industry = value;
      } else if (attrName[1] === "SubIndustry") {
        otherIndustry.subIndustry = value;
      }
      setotherIndustrySubindustry(otherIndustry);
    }
    if (attrName[1] === "name") {
      array_data[attrName[0]].name = value;
    } else if (attrName[1] === "type") {
      array_data[attrName[0]].type = value;
    }
    setAddressData(array_data);

    if (attrName === "notes_new_data") {
    } else if (attrName === setPostQueryForm) {
      attrName(value);
    } else if (attrName === setUploadFile) {
      attrName(value);
    } else if (key === "value") {
      console.log(
        "data",
        data[1].children[0].children[0].children[0].children[0].value
      );
      //For required fields
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].required === true &&
        (value === undefined || value === null
          ? 0
          : value.toString().length) === 0
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = true;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = false;
      }
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_lable === "Organization Email"
      ) {
        setOfficialMailId(value);
      }
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "industry"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
        get_subindustry_list(value, (cb: any) => {
          setSubIndustryData(cb);
        });
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 1
        ].children[attrName[4]].value = "";
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "subIndustry"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "country"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
        get_state_list(value, (cb: any) => {
          setStateData(cb);
        });
        setcountryId(value);
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 1
        ].children[attrName[4]].value = "";
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 2
        ].children[attrName[4]].value = "";
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "state"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
        get_city_list_via_name(countryId, value, (cb: any) => {
          setCityData(cb);
        });
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value === "N.A."
          ? (data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3] + 1].children[attrName[4]].value = "N.A.")
          : (data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3] + 1].children[attrName[4]].value = "");
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "city"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "spoc"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
        setregisterSpocId(value.id);
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "domainSelect" &&
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_lable === "Domain"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.join(",");
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "domainSelect"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      }

      //update value
      // if (value || value.hasOwnProperty('id')) {
      //   get_subindustry_list(value.id, (cb: any) => {
      //     setSubIndustryData(cb);
      //   });
      // }

      setclientaddnewlayout([...data]);
    }
  };

  //get add new client layout
  const get_add_new_client_layout = () => {
    const onSuccess = (res: any) => {
      setclientaddnewlayout(res.data.data[0].layout);
      let arr: any = [
        ...res.data.data[0].layout,
        { type: "section", id: "Settings", name: "Settings", children: [] },
      ];
      setsidepanelLayout(arr);
      setshowloader(false)
    };
    const onError = (err: any) => {
      console.log("err", err);
      setshowloader(false)
    };
    setshowloader(true)
    CLIENT_SERVICES.get_add_new_client_layouts(onSuccess, onError);
  };

  // form submission of contact
  const detail_form_submit = () => {
    if (disableSave) return;
    let hasError = false;
    let newData = clientaddnewlayout.map((dataMainsection: clientsFormDTO) => {
      let newMainSection = { ...dataMainsection };
      newMainSection.children = dataMainsection.children.map(
        (dataSubsection) => {
          let newSubsection = { ...dataSubsection };
          newSubsection.children = dataSubsection.children.map((dataRow) => {
            let newRow = { ...dataRow };
            newRow.children = dataRow.children.map((dataColumn) => {
              let newColumn = { ...dataColumn };
              newColumn.children = dataColumn.children.map((dataInput) => {
                if (dataInput.field_lable === "Salary Brackets") {
                  const result = salaryBracketsInfo.salaryBracketsString
                    .split(",")
                    .map((item: any) => {
                      const [lowerRange, upperRange] = item.split("-");
                      return { lowerRange: parseInt(lowerRange), upperRange };
                    });
                  let lastLower = Number(
                    `${result[result.length - 1].lowerRange}`
                  );
                  let lastUpper = Number(
                    `${result[result.length - 1].upperRange}`
                  );
                  if (result.length > 1 && lastUpper === 0) {
                    if (!hasError) {
                      setUpperWarnStatus(true);
                      hasError = true;
                    } else {
                      setUpperWarnStatus(true);
                    }
                  } else if (result.length > 1 && lastLower >= lastUpper) {
                    if (!hasError) {
                      setUpperWarnStatus(true);

                      hasError = true;
                    } else {
                      setUpperWarnStatus(true);
                    }
                  }
                  return {
                    ...dataInput,
                    error: false,
                    value: salaryBracketsInfo.salaryBracketsString,
                  };
                } else if (dataInput.field_type === "industry") {
                  if (dataInput.value === "Other") {
                    if (!otherIndustrySubindustry.industry) {
                      hasError = true;
                      setotherIndustrySubindustry((prev) => ({
                        ...prev,
                        industryWarn: true,
                      }));
                      return { ...dataInput, value: "Other" };
                    }
                    return {
                      ...dataInput,
                      value: otherIndustrySubindustry.industry,
                    };
                  }
                } else if (dataInput.field_type === "subIndustry") {
                  if (dataInput.value === "Other") {
                    return {
                      ...dataInput,
                      value: otherIndustrySubindustry.subIndustry,
                    };
                  }
                } else if (dataInput.field_type === "domainSelect") {
                  let final =
                    Array.isArray(dataInput?.value) &&
                    dataInput?.value?.map((item: any) => {
                      return { ...item, label: item.label.trim() };
                    });
                  return {
                    ...dataInput,
                    value: final === false ? "" : final,
                  };
                }

                if (
                  dataInput.field_lable === "Location Name" ||
                  dataInput.field_lable === "Location Type"
                ) {
                  return {
                    ...dataInput,
                    error: false,
                    value: "Registered Address",
                  };
                }

                if (
                  dataInput.required === true &&
                  dataInput.value.length === 0
                ) {
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (
                  dataInput.characterLimit &&
                  dataInput.value.length > dataInput.characterLimit
                ) {
                  // toast.warn("Value has character limit and limit has exceeded");
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "email"
                ) {
                  if (validateEmail(dataInput.value.toString()) === true) {
                    return { ...dataInput, error: false };
                  } else {
                    // toast.warn("Email not valid");
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                } else if (dataInput.field_type === "email") {
                  if (
                    validateEmail(dataInput.value.toString()) === false &&
                    dataInput.value.length >= 1
                  ) {
                    // toast.warn("Email 2 error");
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "url"
                ) {
                  if (validateUrl(dataInput.value.toString()) === true) {
                    return { ...dataInput, error: false };
                  } else {
                    // toast.warn("URL invalid");
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                } else if (dataInput.field_type === "url") {
                  if (
                    validateUrl(dataInput.value.toString()) === false &&
                    dataInput.value.length >= 1
                  ) {
                    // toast.warn("No value in URL");
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "address"
                ) {
                  // toast.warn("Error in address");
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (dataInput.field_type === "phone") {
                  if (
                    dataInput.required === true &&
                    dataInput.value.length === 0
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else if (
                    validateMobileNumber(dataInput.value) === false &&
                    dataInput.value.length >= 1
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "address"
                ) {
                  // toast.warn("Address is required");
                  hasError = true;
                  return { ...dataInput, error: true };
                } else {
                  return { ...dataInput, error: false };
                }
              });
              return newColumn;
            });
            return newRow;
          });
          return newSubsection;
        }
      );
      return newMainSection;
    });
    setclientaddnewlayout(newData);

    let addressDataTemp: any = [...addressData];
    addressDataTemp.map((data: any) => {
      if (data.name.length === 0) {
        data.warnName = true;
      } else if (data.type.length === 0) {
        data.warnType = true;
      } else if (data.street.length === 0) {
        data.warnStreet = true;
      } else if (data.country.length === 0) {
        data.warnCountry = true;
      } else if (data.state.length === 0) {
        data.warnState = true;
      } else if (data.city.length === 0) {
        data.warnCity = true;
      } else if (data.zip.length === 0) {
        data.warnZip = true;
      } else if (data.spocId.length === 0) {
        data.warnSpocId = true;
      }
    });
    setAddressData(addressDataTemp);
    if (displayLogo.logo === "") {
      setDisplayLogo((prev) => ({
        ...prev,
        warning: true,
      }));
    }
    // api call code
    if (hasError === false && displayLogo.warning === false) {
      let params = {
        logo: addClientLogo ?? "",
        layout: newData,
        location: [],
        attachments: [],
        settings: featureEnable,
        registerSpocId: registerSpocId,
      };
      setDisableSave(true);
      Post(URL_CONSTANTS.add_new_client, params)
        .then((data: any) => {
          let status = data[0];
          let response = data[1];

          if (status === "success") {
            toast.success("Data added successfully");
            navigate(ALL_ROUTES.CLIENTS);
          }
          if (status?.status === "failed") {
            setDisableSave(false);
            let param = status.errors[0].param;
            let error = status.errors[0].message;
            toast.error(param + " " + " " + error);
          }
        })
        .catch(() => setDisableSave(false));
    }
    // else {
    //   toast.warn("Value Required But no value entered");
    // }
  };

  //get SPOC
  const getSpoc = () => {
    const onSuccess = (res: any) => {
      setspocdata(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.get_Spoc(onSuccess, onError);
  };
  let timeoutIdOne: any;
  useEffect(() => {
    if (timeoutIdOne) {
      clearInterval(timeoutIdOne)
    }
    const delayedApiCall = () => {

      get_add_new_client_layout();

      get_country_list((cb: any) => {
        setCountryData(cb);
      });
      get_industry_list((cb: any) => {
        setIndustryData(cb);
      });

      getSpoc();
    }
    timeoutIdOne = setTimeout(delayedApiCall, 150);

    return () => {
      if (timeoutIdOne) {
        clearTimeout(timeoutIdOne);
      }
    };

  }, []);

  const handleClick = (e: any) => {
    const { name, checked } = e.target;
    setfeatureEnable((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      };
    });
  };

  const checkMailIsExist = (currentValue: string) => {
    const onSuccess = (res: any) => {
      if (res.data.message === "Email not used previously") return;
      else {
        toast.info(res.data.message, {
          autoClose: false,
        });
      }
    };
    const onError = (err: any) => {
      toast.error(err?.response?.data?.errors[0]?.message);
    };

    EMPLOYEE_SERVICES.checkIsMailUsed(
      onSuccess,
      onError,
      currentValue,
      userState.userType.toLowerCase(),
      "employer"
    );
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      if (officialMailId) {
        checkMailIsExist(officialMailId);
      }
    };

    timeoutId = setTimeout(delayedApiCall, 0);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [officialMailId]);

  return (
    <>
      {showloader && <NewLoader />}
      <Box className="layoutWrapper">
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />

        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3> Add New Client </h3>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="mt-0">
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection h-100">
                <>
                  {sidepanelLayout.map(
                    (dataMainSection: contactsFormDTO, index: number) => (
                      <>
                        <Link
                          className={
                            sectionStatus === dataMainSection.name
                              ? `active sectionLink`
                              : "sectionLink"
                          }
                          onClick={() => {
                            handleClicks(dataMainSection.name);
                            setAddClass(true);
                            SetSectionStatus(
                              dataMainSection.name === undefined
                                ? ""
                                : dataMainSection.name
                            );
                          }}
                        >
                          <span>{dataMainSection.name}</span>
                        </Link>
                        {dataMainSection.children.map((dataSubsection) => (
                          <ul className="accordion_list">
                            <li>
                              <Link
                                className={
                                  subsectionStatus === dataSubsection.name
                                    ? `inner_link active`
                                    : `inner_link`
                                }
                                onClick={() => {
                                  handleClicks(dataSubsection.name);
                                  SetsubSectionStatus(dataSubsection.name);
                                  SetSectionStatus(
                                    dataMainSection.name === undefined
                                      ? ""
                                      : dataMainSection.name
                                  );
                                }}
                              >
                                {dataSubsection.name}
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </>
                    )
                  )}
                </>
              </Grid>
              <Grid xs className="rightSection scroll">
                <Grid container spacing={3}>
                  <Grid xs={12} sx={{ paddingBottom: "16px" }}>
                    <Box className="upload-logo">
                      <Grid container sx={{ paddingLeft: 0 }} className="mb-3">
                        <Grid xs={12}>
                          <img
                            src={displayLogo.logo ? displayLogo.logo : ""}
                            alt=""
                            className="upload_company_logo add_logo"
                          />
                          <Link className="upload_img" component="label">
                            <input
                              hidden
                              accept="image/*"
                              multiple
                              type="file"
                              onChange={(e: any) => {
                                const file = e?.target?.files[0];
                                const fileSize = file?.size;
                                const maxSizeBytes = 2 * 1024 * 1024;
                                if (fileSize && fileSize > maxSizeBytes) {
                                  toast.error("File size exceeds 2 MB limit");
                                  return (e.target.value = null);
                                }
                                // let displaydata: any = displayLogo;
                                // displaydata.warning = displayLogo.logo !== "" ? true : false;
                                // setDisplayLogo({ ...displaydata });
                                const allowedFormats = [
                                  "image/png",
                                  "image/jpeg",
                                  "image/jpg",
                                ];
                                if (file) {
                                  if (allowedFormats?.includes(file?.type)) {
                                    uploadImage(
                                      file,
                                      (base64Image: any, base64String: any) => {
                                        setAddClientLogo(base64Image ?? "");
                                        setDisplayLogo({
                                          logo: base64String,
                                          warning: false,
                                        });
                                      }
                                    );
                                  } else {
                                    toast.error(
                                      "Uploaded format not allowed. Only .png, .jpeg, .jpg allowed"
                                    );
                                  }
                                }
                              }}
                            />
                            {displayLogo.warning === true ? (
                              <span className="error">Upload logo</span>
                            ) : null}
                            <img src="images/upload_logo_icon.svg" alt="" />
                            <span>Replace sample logo</span>
                            <div className="mt-1">
                              <span>Maximum size of logo is 2 MB*</span>
                            </div>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                {clientaddnewlayout?.map(
                  (dataMainSection: clientsFormDTO, indexMainSection: number) => (
                    // Main section
                    <Box
                      onMouseEnter={() => {
                        SetSectionStatus(dataMainSection.name);
                      }}
                      className="detailSection"
                    >
                      <div id={dataMainSection.name}>
                        <div className="sectionTitle">
                          <h4>{dataMainSection.name}</h4>
                        </div>
                        {dataMainSection?.children?.map(
                          (dataSubsection, indexSubsection) => (
                            // Sub Section
                            <Grid
                              onMouseEnter={() => {
                                SetsubSectionStatus(dataSubsection.name);
                              }}
                              container
                            >
                              <Grid xs={12}>
                                <h5
                                  id={dataSubsection.name}
                                  key={dataSubsection.type}
                                  className="subHeading"
                                >
                                  {dataSubsection.name}
                                </h5>
                                {dataSubsection.children.map(
                                  (dataRow, indexRow) => (
                                    // Row
                                    <>
                                      <Grid
                                        container
                                        className="subRow"
                                        spacing={3}
                                      >
                                        {dataRow.children?.map(
                                          (dataColumn, indexColumn) => (
                                            <>
                                              {/* // Columns created */}

                                              {dataColumn.children.map(
                                                (dataInput, indexInput) => {
                                                  if (
                                                    dataInput.field_lable ===
                                                    "Salary Brackets"
                                                  ) {
                                                    return null;
                                                  }
                                                  // input Component will be here
                                                  return (
                                                    <Grid
                                                      xs={
                                                        dataInput.field_lable ===
                                                          "Departments" ||
                                                          dataInput.field_lable ===
                                                          "Designations"
                                                          ? 6
                                                          : true
                                                      }
                                                      className="subColumn"
                                                    >
                                                      {dataInput.field_type ===
                                                        "singleselect" ? (
                                                        <SelectDropdown
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          dropdown_data={
                                                            dataInput.pickliststep ??
                                                            []
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg={`Enter ${dataInput.field_lable}`}
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "clientId" ? null : dataInput.field_type ===
                                                          "phone" ? (
                                                        <RKTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value === "" ? `Enter ${dataInput.field_lable}` :
                                                              !validateMobileNumber(dataInput.value) ?
                                                                `Enter valid ${dataInput.field_lable}`
                                                                : dataInput.characterLimit && dataInput.value.length > dataInput.characterLimit
                                                                  ? `Maximum ${dataInput.characterLimit} limit` : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            dataInput.field_lable ===
                                                              "Organization Phone Number" ? 15 :
                                                              !dataInput.characterLimit
                                                                ? 11
                                                                : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "email" ? (
                                                        <RKTextField
                                                          validation_type="email"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : validateEmail(
                                                                dataInput.value
                                                              ) === false
                                                                ? `Enter a valid email Id`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 50
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "datepicker" ? (
                                                        <DatePicker
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value ||
                                                            null
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          error_message="Enter a Valid Date"
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "searchselect" ? (
                                                        <SearchDropdown
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          data={
                                                            dataInput.pickliststep
                                                              ? dataInput.pickliststep
                                                              : []
                                                          }
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_message={
                                                            "Select the value"
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "checkbox" ? (
                                                        <CheckBox
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          name={
                                                            dataInput.field_lable
                                                          }
                                                          id={dataInput._id}
                                                          value={Boolean(
                                                            dataInput.value
                                                          )}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          default_check={true}
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "number" ? (
                                                        <RKTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.maxDigits
                                                              ? 12
                                                              : dataInput.maxDigits
                                                          }
                                                        />
                                                      ) : dataInput.field_type ==
                                                        "currency" ? (
                                                        <CurrencyTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} Limit`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.maxDigits
                                                              ? 12
                                                              : dataInput.maxDigits
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "multiselect" ? (
                                                        <MultipleSearchDropdown
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          multiple_data={
                                                            dataInput.pickliststep
                                                              ? dataInput.pickliststep
                                                              : []
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          error_messg={
                                                            "Select the value"
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "multiline" ? (
                                                        <TextAreaField
                                                          specialcharacter={
                                                            !dataInput.speacialChar
                                                              ? false
                                                              : dataInput.speacialChar
                                                          }
                                                          alphanumeric={
                                                            !dataInput.alphaNumeric
                                                              ? false
                                                              : dataInput.alphaNumeric
                                                          }
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 30
                                                              : dataInput.characterLimit
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "url" ? (
                                                        <RKTextField
                                                          validation_type="url"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : validateUrl(
                                                                dataInput.value
                                                              ) === false
                                                                ? `Enter a valid Url`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 50
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "country" ? (
                                                        <SearchDropdown
                                                          class_name="inputField"
                                                          title="Country"
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={countryData}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_message="Select country"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "state" ? (
                                                        <SearchDropdown
                                                          class_name="inputField"
                                                          title="State"
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={stateData}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_message="Select state"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "city" ? (
                                                        <SearchDropdown
                                                          class_name="inputField"
                                                          title="City"
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={cityData}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_message="Select city"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "spoc" ? (
                                                        <SearchDropdown
                                                          class_name="inputField"
                                                          title="Spoc"
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          data={spocdata}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_message="Select spoc"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "domainSelect" &&
                                                        dataInput.field_lable ===
                                                        "Domain" ? (
                                                        <UGEmailInput
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          calledFrom={null}
                                                          warn_status={
                                                            dataInput?.error
                                                          }
                                                          required={
                                                            dataInput?.required
                                                          }
                                                          error_messg={`Enter ${dataInput.field_lable}`}
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "domainSelect" ? (
                                                        <ARMultiInputField
                                                          class_name={
                                                            "inputField"
                                                          }
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput?.error
                                                          }
                                                          required={
                                                            dataInput?.required
                                                          }
                                                          error_messg={`Enter ${dataInput.field_lable}`}
                                                        />
                                                      ) : dataInput.field_type ==
                                                        "industry" ? (
                                                        <>
                                                          <Box>
                                                            <Grid
                                                              container
                                                              columnSpacing={3}
                                                              padding={0}
                                                            >
                                                              <Grid
                                                                xs={
                                                                  dataInput.value ===
                                                                    "Other"
                                                                    ? 6
                                                                    : 12
                                                                }
                                                              >
                                                                <SearchDropdown
                                                                  class_name="inputField"
                                                                  title="Industry"
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  data={
                                                                    IndustryData
                                                                  }
                                                                  error_message="Select Industry"
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                />
                                                              </Grid>

                                                              {dataInput.value ===
                                                                "Other" && (
                                                                  <Grid xs={6}>
                                                                    <RKTextField
                                                                      class_name="inputField"
                                                                      title="Industry"
                                                                      value={
                                                                        otherIndustrySubindustry.industry
                                                                      }
                                                                      attrName={[
                                                                        "otherIndustrySubindustry",
                                                                        "Industry",
                                                                      ]}
                                                                      value_update={
                                                                        updateMasterState
                                                                      }
                                                                      warn_status={
                                                                        otherIndustrySubindustry.industryWarn
                                                                      }
                                                                      required={
                                                                        true
                                                                      }
                                                                      charcterlimit={
                                                                        !dataInput.characterLimit
                                                                          ? 50
                                                                          : dataInput.characterLimit
                                                                      }
                                                                    />
                                                                  </Grid>
                                                                )}
                                                            </Grid>
                                                          </Box>
                                                        </>
                                                      ) : dataInput.field_type ==
                                                        "subIndustry" ? (
                                                        <>
                                                          <Box>
                                                            <Grid
                                                              container
                                                              columnSpacing={3}
                                                              padding={0}
                                                            >
                                                              <Grid
                                                                xs={
                                                                  dataInput.value ===
                                                                    "Other"
                                                                    ? 6
                                                                    : 12
                                                                }
                                                              >
                                                                <SearchDropdown
                                                                  class_name="inputField"
                                                                  title="Sub Industry"
                                                                  value={
                                                                    dataInput.value
                                                                  }
                                                                  attrName={[
                                                                    indexMainSection,
                                                                    indexSubsection,
                                                                    indexRow,
                                                                    indexColumn,
                                                                    indexInput,
                                                                    "value",
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  data={
                                                                    subIndustryData
                                                                  }
                                                                  error_message="Select Sub Industry"
                                                                  required={
                                                                    dataInput.required
                                                                  }
                                                                  warn_status={
                                                                    dataInput.error
                                                                  }
                                                                />
                                                              </Grid>

                                                              {dataInput.value ===
                                                                "Other" && (
                                                                  <Grid xs={6}>
                                                                    <RKTextField
                                                                      class_name="inputField"
                                                                      title="Sub Industry"
                                                                      value={
                                                                        otherIndustrySubindustry.subIndustry
                                                                      }
                                                                      attrName={[
                                                                        "otherIndustrySubindustry",
                                                                        "SubIndustry",
                                                                      ]}
                                                                      value_update={
                                                                        updateMasterState
                                                                      }
                                                                      warn_status={
                                                                        false
                                                                      }
                                                                      charcterlimit={
                                                                        !dataInput.characterLimit
                                                                          ? 50
                                                                          : dataInput.characterLimit
                                                                      }
                                                                    />
                                                                  </Grid>
                                                                )}
                                                            </Grid>
                                                          </Box>
                                                        </>
                                                      ) : dataInput.field_type ==
                                                        "singleline" &&
                                                        dataInput.field_lable ==
                                                        "Organisation" ? (
                                                        <RKTextField
                                                          specialcharacter={
                                                            !dataInput.speacialChar
                                                              ? false
                                                              : dataInput.speacialChar
                                                          }
                                                          alphanumeric={
                                                            !dataInput.alphaNumeric
                                                              ? false
                                                              : dataInput.alphaNumeric
                                                          }
                                                          validation_type="name"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 30
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : (
                                                        <RKTextField
                                                          specialcharacter={
                                                            !dataInput.speacialChar
                                                              ? false
                                                              : dataInput.speacialChar
                                                          }
                                                          alphanumeric={
                                                            !dataInput.alphaNumeric
                                                              ? false
                                                              : dataInput.alphaNumeric
                                                          }
                                                          validation_type="name"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.field_lable ===
                                                              "Location Name" ||
                                                              dataInput.field_lable ===
                                                              "Location Type"
                                                              ? "Registered Address"
                                                              : dataInput.value
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          disabled={
                                                            dataInput.field_lable ===
                                                            "Location Name" ||
                                                            dataInput.field_lable ===
                                                            "Location Type"
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            dataInput.field_lable ===
                                                              "Company Id"
                                                              ? undefined
                                                              : !dataInput.characterLimit
                                                                ? 30
                                                                : dataInput.characterLimit
                                                          }
                                                        />
                                                      )}
                                                    </Grid>
                                                  );
                                                }
                                              )}

                                              {dataColumn.children.map(
                                                (dataInput, indexInput) => {
                                                  if (
                                                    dataInput.field_lable ===
                                                    "Salary Brackets"
                                                  ) {
                                                    return (
                                                      <Grid
                                                        xs={12}
                                                        className="subColumn"
                                                      >
                                                        <form>
                                                          <SalaryBracketInputs
                                                            dbValue={
                                                              dataInput.value
                                                            }
                                                            getSalaryBrackets={
                                                              setSalaryBracketsInfo
                                                            }
                                                            submit={
                                                              upperWarnStatus
                                                            }
                                                            submit2={
                                                              setUpperWarnStatus
                                                            }
                                                          />
                                                        </form>
                                                      </Grid>
                                                    );
                                                  }
                                                }
                                              )}
                                            </>
                                          )
                                        )}
                                      </Grid>
                                    </>
                                  )
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </div>
                    </Box>
                  )
                )}

                <Box
                  onMouseEnter={() => {
                    SetSectionStatus("Settings");
                  }}
                  className="detailSection"
                  id="Settings"
                >
                  <div className="sectionTitle">
                    <h4>Settings</h4>
                  </div>

                  <Grid container spacing={3} className="emp_switchFields">
                    {/* <Grid xs={4} display={"flex"} alignItems="center">
                    <span>Enable Employee Portal</span>
                    <input
                      type="checkbox"
                      className="switch"
                      name="enableEmployeePortal"
                      onClick={(e: any) => {
                        if (!e.target.checked) {
                          toast.info(
                            "Disabling a toogle will pause the access to the Employee platform, enabling the toggle will resume the access again.", {
                              autoClose: false,
                            }
                          );
                        }
                        handleClick(e);
                      }}
                    />
                  </Grid> */}
                    <Grid xs={4} display={"flex"} alignItems="center">
                      <span> Enable Employer Portal</span>
                      <input
                        type="checkbox"
                        className="switch"
                        name="enableEmployerPortal"
                        onClick={(e: any) => {
                          if (!e.target.checked) {
                            toast.info(
                              "Disabling a toogle will pause the access to the Employee platform, enabling the toggle will resume the access again.",
                              {
                                autoClose: false,
                              }
                            );
                          }
                          handleClick(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Footer Section */}
        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              onClick={() => {
                navigate(ALL_ROUTES.CLIENTS);
              }}
              variant="outlined"
              className="borderBtn"
            >
              Cancel
            </Button>
            <Button
              onClick={detail_form_submit}
              variant="contained"
              className="submitBtn"
              disabled={disableSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <PostQueryForm
          attrName={"postQueryForm"}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />
      </Box>
    </>
  );
}

export default AddNewClient;
